import React, { Component } from 'react'
import { Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import ProductSubchoiceSelectTable from '~/src/components/productSubchoiceSelectTable'

class ProductSubchoiceSelectTableModal extends Component {

    render() {
        const { intl, visible, isSubmitting, subchoices, choiceId } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={true}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.subchoice })}
                footer={null}
                onCancel={() => {
                    this.props.onClose()
                }}>
                {
                    visible
                        ? (

                            <ProductSubchoiceSelectTable
                                value={subchoices}
                                choiceId={choiceId}
                                onChange={(value) => this.props.onChange(value)}
                            />
                        )
                        : null
                }
            </Modal>
        )
    }
}

export default injectIntl(ProductSubchoiceSelectTableModal)