import React, { Component } from 'react'
import { Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import CreateCouponForm from '~/src/components/forms/createCouponForm'

class EditCouponModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { intl, visible, isSubmitting, coupon, isOffline } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width={960}
                title={intl.formatMessage({ ...messages.editCoupon })}
                okText={intl.formatMessage({ ...messages.confirm })}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => {
                    this.props.onClose()
                    this.formRef.resetForm()
                }}>
                {
                    visible
                        ? (
                            <CreateCouponForm
                                initial={coupon}
                                isSubmitting={isSubmitting}
                                isOffline={isOffline}
                                onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                                wrappedComponentRef={(inst) => this.formRef = inst} />
                        )
                        : null
                }
            </Modal>
        )
    }
}

export default injectIntl(EditCouponModal)