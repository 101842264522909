import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { DATE_FORMAT } from '~/src/constants/format'
import styled from 'styled-components'
import messages from '~/src/messages'
import moment from 'moment'
import { LIMIT } from '~/src/constants/common'
import { Table, Button, Tag, Modal, Input, Icon, Checkbox, Row, DatePicker } from 'antd'
import { statusOptions, pageSizeOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import { toJS } from 'mobx'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

@inject('commonStore', 'userStore') @observer
class MemberListModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            pageSize: LIMIT,
            filterValues: {
                username: undefined,
                email: undefined,
                status: [],
                validationDateMin: undefined,
                validationDateMax: undefined
            },
            sortedInfo: undefined,
            selectedKey: undefined,
            selectedMember: undefined
        }
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    resetModal() {
        this.setState({
            currentPage: 1,
            pageSize: LIMIT,
            filterValues: {
                username: undefined,
                email: undefined,
                status: [],
                validationDateMin: undefined,
                validationDateMax: undefined
            },
            sortedInfo: undefined,
            selectedKey: undefined,
            selectedMember: undefined
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order==='ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { userStore, commonStore } = this.props
        const { token, shop } = commonStore
        const { pageSize } = pagination
        const {
            username,
            email,
            status,
            validationDateMin,
            validationDateMax
        } = this.state.filterValues
        userStore.listMember(token, shop, pageSize, pageSize * (page - 1),
            sortField,
            username,
            email,
            status,
            validationDateMin ? validationDateMin.format() : undefined,
            validationDateMax ? validationDateMax.format() : undefined
        )
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    handleOnSelectedRowsChange(selectedRowKeys, selectedRows) {
        if (selectedRowKeys.length === 1) {
            this.setState({ selectedKey: selectedRowKeys[0], selectedMember: selectedRows[0] })
        } else if (selectedRowKeys.length > 1) {
            this.setState({ selectedKey: selectedRowKeys[1], selectedMember: selectedRows[0] })
        } else {
            this.setState({ selectedKey: undefined, selectedMember: undefined })
        }
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'username':
            case 'email':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'joinedAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'validationDateMin': { $set: undefined }, 'validationDateMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                username: undefined,
                email: undefined,
                status: [],
                validationDateMin: undefined,
                validationDateMax: undefined
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'username':
            case 'email':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'joinedAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.validationDateMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'validationDateMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.validationDateMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'validationDateMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex==='username' || dataIndex==='email')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { commonStore } = this.props
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.username }),
                dataIndex: 'username',
                key: 'username',
                fixed: 'left',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'username' && sortedInfo.order,
                ...this.getColumnSearchProps('username')
            },
            {
                title: intl.formatMessage({ ...messages.email }),
                dataIndex: 'email',
                key: 'email',
                fixed: 'left',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'email' && sortedInfo.order,
                ...this.getColumnSearchProps('email')
            },
            {
                title: intl.formatMessage({ ...messages.credit }),
                dataIndex: 'credit',
                key: 'credit',
                width: 100,
            },
            // {
            //     title: intl.formatMessage({ ...messages.status }),
            //     dataIndex: 'membership.status',
            //     key: 'status',
            //     width: 100,
            //     render: (text) => {
            //         const index = statusOptions.findIndex(c => c.value===text)
            //         return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
            //     },
            //     ...this.getColumnSearchProps('status')
            // },
            // {
            //     title: intl.formatMessage({ ...messages.joinedAt }),
            //     dataIndex: 'membership.validationDate',
            //     key: 'joinedAt',
            //     sorter: true,
            //     sortOrder: sortedInfo && sortedInfo.columnKey === 'joinedAt' && sortedInfo.order,
            //     render: (joinedAt) => (
            //         <span>
            //             {moment(joinedAt).format(DATE_FORMAT)}
            //         </span>
            //     ),
            //     ...this.getColumnSearchProps('joinedAt')
            // },
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'username':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.username })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'email':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.email })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'validationDateMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.joinedAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'validationDateMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.joinedAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { userStore, intl, visible } = this.props
        const { currentPage, pageSize } = this.state
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.linkShopperMember })}
                okText={intl.formatMessage({...messages.link})}
                cancelText={intl.formatMessage({...messages.cancel})}
                onOk={() => {
                    if (this.state.selectedKey) {
                        this.props.onLink(this.state.selectedKey, this.state.selectedMember)
                        this.setState({ selectedKey: undefined, selectedMember: undefined  })
                    }
                }}
                okButtonProps={{
                    disabled: !this.state.selectedKey
                }}
                onCancel={() => this.props.onClose()}>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                            {intl.formatMessage({ ...messages.resetAllSearch })}
                        </Button>
                    </ActionLeftWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <Table
                    columns={this.renderTableColumn()}
                    dataSource={toJS(userStore.users)}
                    pagination={
                        {
                            showSizeChanger: true,
                            defaultPageSize: LIMIT,
                            pageSizeOptions: pageSizeOptions,
                            showQuickJumper: true,
                            current: +currentPage,
                            pageSize: pageSize,
                            total: userStore.count,
                            showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total })}
                        }
                    }
                    scroll={{ x: 1300 }}
                    onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                    loading={userStore.isSubmitting}
                    rowKey={record => record._id}
                    rowSelection={{
                        fixed: true,
                        columnTitle: ' ',
                        hideDefaultSelections: true,
                        selectedRowKeys: this.state.selectedKey ? [this.state.selectedKey] : [],
                        onChange: (selectedRowKeys, selectedRows) => this.handleOnSelectedRowsChange(selectedRowKeys, selectedRows)
                    }}
                />
            </Modal>
        )
    }
}

export default injectIntl(MemberListModal, { withRef: true })
