import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'

class MediaStore {
    @observable isSubmitting = false
    @observable error = null
    
    uploadPhoto = flow(function* (token, file) {
        this.isSubmitting = true
        const formData = new FormData()
        const type = file.type.split('/')[0].toUpperCase()
        if (type === 'VIDEO') {
            formData.append('photo', file)
        }
        if (type === 'IMAGE') {
            const thumbnail = yield makeThumbnail(file)
            formData.append('photo', thumbnail)
        }
        try {
            const response = yield api.uploadPhoto(token, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve(response.data)
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

}

export default new MediaStore()
