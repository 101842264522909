import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { Table, Modal, Divider, Button } from 'antd'
import EmailModal from '~/src/components/emailModal'
import styled from 'styled-components'
import pdfMake from "pdfmake/build/pdfmake"
import fontLoader from '~/src/lib/fontLoader'

const PrintArea = styled.div`
    font-variant: normal;
`

const TableWrapper = styled.div`
    margin: 16px;
`

const Footer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
`

class PospalTicketPrintModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            emailVisible: false,
            isMakingPDF: false
        }
    }

    getPdf(callback) {
        const { ticket, shop, intl } = this.props
        var img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = function () {
            console.log('on load')
            var canvas = document.createElement('CANVAS')
            const ctx = canvas.getContext('2d')
            canvas.height = img.height
            canvas.width = img.width
            ctx.drawImage(img, 0, 0)
            const dataURL = canvas.toDataURL('image/png')

            const tableData = ticket ? ticket.items.map(({ name, quantity, totalAmount }, index) => {
                return [
                    { text: name },
                    quantity,
                    { text: `$${totalAmount}`, alignment: 'right' }
                ]
            }) : []
            const tableHeader = [
                intl.formatMessage({ ...messages.name }),
                intl.formatMessage({ ...messages.quantity }),
                intl.formatMessage({ ...messages.price })
            ]

            const definition = {
                content: [
                    {
                        columnGap: 20,
                        columns: [
                            { image: dataURL, width: 50, height: 50 },
                            { text: `${intl.formatMessage({ ...messages.invoice })} - ${shop.name}` }
                        ]
                    },
                    { text: `${intl.formatMessage({ ...messages.address })}: ${shop.address}` },
                    { text: `${intl.formatMessage({ ...messages.phoneNumber })}: ${shop.phoneNumber}` },
                    { text: `${intl.formatMessage({ ...messages.invoiceId })}: ${ticket.sn}` },
                    { text: `${intl.formatMessage({ ...messages.orderDate })}: ${ticket.datetime}` },
                    {
                        layout: 'lightHorizontalLines',
                        table: {
                            headerRows: 1,
                            widths: ['*', 'auto', 100],

                            body: [
                                tableHeader,
                            ].concat(tableData)
                        }
                    },
                    { text: `${intl.formatMessage({ ...messages.totalAmount })}: ${ticket.totalAmount}`, alignment: 'right' },
                ],
                defaultStyle: {
                    font: 'msyh'
                }
            }

            fontLoader.load()
                .then(() => {
                    const doc = pdfMake.createPdf(definition)
                    callback(doc)

                    canvas = null
                })
        }
        img.src = shop.logo
    }

    handleOnExportPdfClick() {
        this.setState({ isMakingPDF: true })
        const { ticket } = this.props
        this.getPdf((doc) => {
            doc.download(`invoice_${ticket.sn}.pdf`)
            this.setState({ isMakingPDF: false })
        })
    }

    handleOnPrintPdfClick() {
        this.setState({ isMakingPDF: true })
        this.getPdf((doc) => {
            doc.print()
            this.setState({ isMakingPDF: false })
        })
    }

    handleOnEmailPdfClick() {
        this.setState({ emailVisible: true })
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: intl.formatMessage({ ...messages.quantity }),
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: intl.formatMessage({ ...messages.price }),
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                width: 100
            },
        ]
    }

    renderExpandedRow(record, index) {
        const resultArray = record.ticketitemattributes
            ? record.ticketitemattributes.map(a => {
                return a.attributeName
            })
            : []
        return resultArray.join(' ')
    }

    render() {
        const { intl, visible, isSubmitting, ticket, shop } = this.props
        const { isMakingPDF } = this.state
        if (!ticket || !shop) {
            return null
        }
        const data = ticket ? ticket.items.map((item, index) => { return { key: index, ...item } }) : []
        const expandedRowKeys = data.map((d, i) => i)
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                closable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.invoicePreview })}
                footer={[
                    <Button key="cancel" loading={isSubmitting || isMakingPDF} onClick={() => this.props.onClose()}>
                        {intl.formatMessage({ ...messages.cancel })}
                    </Button>,
                    <Button key="email" type="primary" loading={isSubmitting || isMakingPDF} onClick={() => this.handleOnEmailPdfClick()}>
                        {intl.formatMessage({ ...messages.email })}
                    </Button>,
                    <Button key="print" type="primary" loading={isSubmitting || isMakingPDF} onClick={() => this.handleOnPrintPdfClick()}>
                        {intl.formatMessage({ ...messages.print })}
                    </Button>,
                    <Button key="export" type="primary" loading={isSubmitting || isMakingPDF} onClick={() => this.handleOnExportPdfClick()}>
                        {intl.formatMessage({ ...messages.export })}
                    </Button>,
                ]}
                okText={intl.formatMessage({ ...messages.download })}
                onOk={() => this.handleOnExportPdfClick()}
                onCancel={() => this.props.onClose()}>
                <PrintArea id='pdf'>
                    <TableWrapper>
                        <h2>{`${intl.formatMessage({ ...messages.invoice })} - ${shop.name}`}</h2>
                        <h4>{`${intl.formatMessage({ ...messages.address })}: ${shop.address}`}</h4>
                        <h4>{`${intl.formatMessage({ ...messages.phoneNumber })}: ${shop.phoneNumber}`}</h4>
                        <Divider />
                        <h4>{`${intl.formatMessage({ ...messages.invoiceId })}: ${ticket.sn}`}</h4>
                        <h4>{`${intl.formatMessage({ ...messages.orderDate })}: ${ticket.datetime}`}</h4>
                        <Table
                            columns={this.renderTableColumn()}
                            dataSource={data}
                            pagination={false}
                            loading={isSubmitting}
                            defaultExpandAllRows={true}
                            expandedRowKeys={expandedRowKeys}
                            footer={() =>
                                ticket
                                    ? <Footer><span>{`${intl.formatMessage({ ...messages.totalAmount })}: $${ticket.totalAmount}`}</span></Footer>
                                    : null
                            }
                            expandedRowRender={(record, index) => this.renderExpandedRow(record, index)}
                            rowKey={record => { return record.key }}
                        />
                    </TableWrapper>
                </PrintArea>
                <EmailModal
                    visible={this.state.emailVisible}
                    isSubmitting={isSubmitting}
                    onSubmit={(values, reset) => this.props.onEmail(values, () => {
                        reset()
                        this.setState({ emailVisible: false })
                    })}
                    onClose={() => this.setState({ emailVisible: false })} />
            </Modal>
        )
    }
}

export default injectIntl(PospalTicketPrintModal)