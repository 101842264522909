import React from 'react'
import { Form, Input, InputNumber, Checkbox, Descriptions, Divider } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { hasError, getErrorMessage } from '~/src/lib/errorMessages'

class UpdateMemberCreditForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => this.resetForm() )
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { isSubmitting, intl } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form>
                <Form.Item
                    label={intl.formatMessage({ ...messages.credit })}>
                    {
                        getFieldDecorator('credit', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: 0
                        })(
                            <InputNumber
                                placeholder={intl.formatMessage({ ...messages.credit })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(UpdateMemberCreditForm))