import React from 'react'
import { Form, Select } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { orderStatusOptions } from '~/src/constants/options'

class UpdateManyOrderForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { isSubmitting, intl } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        return (
            <Form {...formItemLayout}>
            <Form.Item
                label={intl.formatMessage({ ...messages.status })}>
                {
                    getFieldDecorator('status', {
                        rules: [
                            { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                        ],
                    })(
                        <Select
                            placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                            disabled={isSubmitting}>
                            {
                                orderStatusOptions.map((item, index) => {
                                    return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                })
                            }
                        </Select>
                    )
                }
            </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(UpdateManyOrderForm))