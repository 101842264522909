import { observable, flow, computed } from 'mobx'
import api from '../api'

class AnalysisStore {
    @observable isSubmitting = false
    @observable error = null
    @observable memberCount = undefined
    @observable memberCountByPeriod = []
    @observable memberCumulativeCountByPeriod = []
    @observable orderTotalCount = undefined
    @observable orderTotalAmount = undefined
    @observable foodOrderTotalCount = undefined
    @observable foodOrderTotalAmount = undefined
    @observable orderSummaryByPeriod = []
    @observable foodOrderSummaryByPeriod = []
    @observable mostBookmarkProducts = []
    @observable mostOrderMembers = []
    @observable mostFoodOrderMembers = []
    @observable mostAmountMembers = []
    @observable mostFoodAmountMembers = []
    @observable retailReport = []
    @observable storeReport = []
    @observable productSummaryByPeriod = []
    @observable memberSummaryByPeriod = []

    getMemberCount = flow(function* (token, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.getMemberCount(token, shop)
            this.memberCount = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getMemberCountByPeriod = flow(function* (token, shop, mode, startDate, endDate) {
        this.isSubmitting = true
        try {
            const response = yield api.getMemberCountByPeriod(token, shop, mode, startDate, endDate)
            this.memberCountByPeriod = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getMemberCumulativeCountByPeriod = flow(function* (token, shop, mode, startDate, endDate) {
        this.isSubmitting = true
        try {
            const response = yield api.getMemberCumulativeCountByPeriod(token, shop, mode, startDate, endDate)
            this.memberCumulativeCountByPeriod = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getOrderSummary = flow(function* (token, shop, startDate, endDate) {
        this.isSubmitting = true
        try {
            const response = yield api.getOrderSummary(token, shop, startDate, endDate)
            const { totalAmount, totalCount } = response.data
            this.orderTotalAmount = totalAmount
            this.orderTotalCount = totalCount
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getFoodOrderSummary = flow(function* (token, shop, startDate, endDate, deliveryMethod) {
        this.isSubmitting = true
        try {
            const response = yield api.getFoodOrderSummary(token, shop, startDate, endDate, deliveryMethod)
            const { totalAmount, totalCount } = response.data
            this.foodOrderTotalAmount = totalAmount
            this.foodOrderTotalCount = totalCount
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getOrderSummaryByPeriod = flow(function* (token, shop, mode, startDate, endDate) {
        this.isSubmitting = true
        try {
            const response = yield api.getOrderSummaryByPeriod(token, shop, mode, startDate, endDate)
            this.orderSummaryByPeriod = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getFoodOrderSummaryByPeriod = flow(function* (token, shop, mode, startDate, endDate, deliveryMethod) {
        this.isSubmitting = true
        try {
            const response = yield api.getFoodOrderSummaryByPeriod(token, shop, mode, startDate, endDate, deliveryMethod)
            this.foodOrderSummaryByPeriod = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listMostBookmarkedProducts = flow(function* (token, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.listMostBookmarkedProducts(token, shop)
            this.mostBookmarkProducts = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listMostOrderMembers = flow(function* (token, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.listMostOrderMembers(token, shop)
            this.mostOrderMembers = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listMostFoodOrderMembers = flow(function* (token, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.listMostFoodOrderMembers(token, shop)
            this.mostFoodOrderMembers = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listMostAmountMembers = flow(function* (token, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.listMostAmountMembers(token, shop)
            this.mostAmountMembers = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listMostFoodAmountMembers = flow(function* (token, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.listMostFoodAmountMembers(token, shop)
            this.mostFoodAmountMembers = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getRetailReport = flow(function* (token, shop, startDate, endDate) {
        this.isSubmitting = true
        try {
            const response = yield api.getRetailReport(token, shop, startDate, endDate)
            this.retailReport = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getStoreReport = flow(function* (token, shop, storeId, startDate, endDate) {
        this.isSubmitting = true
        try {
            const response = yield api.getStoreReport(token, shop, storeId, startDate, endDate)
            this.storeReport = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getProductSummaryByPeriod = flow(function* (token, shop, product, mode, startDate, endDate) {
        this.isSubmitting = true
        try {
            const response = yield api.getProductSummaryByPeriod(token, shop, product, mode, startDate, endDate)
            this.productSummaryByPeriod = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getMemberSummaryByPeriod = flow(function* (token, shop, user, mode, startDate, endDate) {
        this.isSubmitting = true
        try {
            const response = yield api.getMemberSummaryByPeriod(token, shop, user, mode, startDate, endDate)
            this.memberSummaryByPeriod = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })
}

export default new AnalysisStore()
