import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'
import commonStore from './commonStore'

class ShopStore {
    @observable isSubmitting = false
    @observable error = null
    @observable shops = []
    @observable count = 0
    @observable selfShop = undefined
    @observable memberAnalysis = undefined


    getShop = flow(function* (shopId) {
        this.isSubmitting = true
        try {
            const response = yield api.getShop(shopId)
            this.selfShop = response.data
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getShopByInitial = flow(function* (shopInitial) {
        this.isSubmitting = true
        try {
            const response = yield api.getShopByInitial(shopInitial)
            console.log(response.data)
            this.selfShop = response.data
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getShopByDomainName = flow(function* (domainName) {
        this.isSubmitting = true
        try {
            const response = yield api.getShopByDomainName(domainName)
            this.selfShop = response.data
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getSelfShop = flow(function* (token) {
        this.isSubmitting = true
        try {
            const response = yield api.getSelfShop(token)
            this.selfShop = response.data
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateSelfShop = flow(function* (token, values) {
        const formData = new FormData()
        formData.append('phoneNumber', values.phoneNumber)
        formData.append('website', values.website)
        formData.append('description', values.description.toHTML())
        formData.append('terms', values.terms.toHTML())
        values.stores.forEach(s => formData.append('stores[]', s.address))
        values.deliveryMethods.forEach(s => formData.append('deliveryMethods[]', s))
        if (values.freeCustomShippingPrice) {
            formData.append('freeCustomShippingPrice', values.freeCustomShippingPrice)
        }
        if (values.nonfreeCustomShippingFee) {
            formData.append('nonfreeCustomShippingFee', values.nonfreeCustomShippingFee)
        }
        for (const f of values.logo) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeThumbnail(originFileObj)
                formData.append('logo', thumbnail)
            }
        }
        for (const f of values.feedCover) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('feedCover', thumbnail)
            }
        }
        for (const f of values.searchCover) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('searchCover', thumbnail)
            }
        }
        for (const f of values.couponCover) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('couponCover', thumbnail)
            }
        }
        this.isSubmitting = true
        try {
            const response = yield api.updateSelfShop(token, formData)
            this.selfShop = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    getMemberAnalysis = flow(function* (token, shop, startDate, endDate) {
        this.isSubmitting = true
        try {
            const response = yield api.getMemberAnalysis(token, shop, startDate, endDate)
            this.memberAnalysis = response.data
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

}

export default new ShopStore()
