import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { toJS } from 'mobx'
import { Table, Descriptions, Select, Spin, Divider, Card, Row, Col } from 'antd'
import messages from '~/src/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { DATE_FORMAT, DATE_ONLY_FORMAT } from '~/src/constants/format'
import _ from 'lodash'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 16px;
    background-color: white;
    
    .ant-card:not(:last-child) {
        margin-bottom: 16px;
    }
`

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;

`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

@inject('commonStore', 'productStore', 'shopStore') @observer
class ProductStockPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedProduct: undefined
        }
    }

    debounceProductSearch = _.debounce((search) => {
        const { commonStore, productStore } = this.props
        const { token, shop } = commonStore
        if (search && search.length >= 3) {
            productStore.searchProduct(token, shop, search)
        }
    }, 250)

    handleOnProductSearch(value) {
        this.debounceProductSearch(value)
    }

    handleOnChange(value) {
        const { productStore, commonStore } = this.props
        const { token, shop } = commonStore
        const index = productStore.searchedProducts.findIndex(s => s._id === value)
        if (index > -1) {
            console.log(productStore.searchedProducts[index])
            this.setState({ selectedProduct: toJS(productStore.searchedProducts[index]) }, async () => {
                await productStore.getProductStockHistory(token, shop, value)
            })
        }
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.stockChange }),
                dataIndex: 'quantity',
                key: 'quantity',
                render: (text, record) => {
                    return `-${record.quantity}`
                }
            },
            {
                title: intl.formatMessage({ ...messages.date }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                )
            }
        ]
    }

    render() {
        const { intl, productStore } = this.props
        const { selectedProduct } = this.state
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.productStock })}</title>
                </Helmet>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    notFoundContent={productStore.isSubmitting ? <Spin size="small" /> : null}
                    placeholder={intl.formatMessage({ ...messages.productIdOrName })}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    value={selectedProduct ? selectedProduct._id : undefined}
                    onChange={value => this.handleOnChange(value)}
                    onSearch={value => this.handleOnProductSearch(value)}>
                    {
                        productStore.searchedProducts.map((s) => {
                            return (
                                <Select.Option key={s._id}> {`${s.productId} - ${s.name}`}</Select.Option>
                            )
                        })
                    }
                </Select>
                <Divider />
                {
                    selectedProduct
                        ? (
                            <Descriptions title={intl.formatMessage({ ...messages.productInformation })}>
                                <Descriptions.Item label={intl.formatMessage({ ...messages.name })} span={3}>{selectedProduct.name}</Descriptions.Item>
                                <Descriptions.Item label={intl.formatMessage({ ...messages.productId })} span={3}>{selectedProduct.productId}</Descriptions.Item>
                                <Descriptions.Item label={intl.formatMessage({ ...messages.price })}>{selectedProduct.price}</Descriptions.Item>
                                <Descriptions.Item label={intl.formatMessage({ ...messages.stock })}>{selectedProduct.stock}</Descriptions.Item>
                            </Descriptions>
                        )
                        : null
                }
                {
                    selectedProduct
                        ? (
                            <Table
                                columns={this.renderTableColumn()}
                                dataSource={toJS(productStore.productStockHistory)}
                                rowKey={record => record._id}
                            />
                        )
                        : null
                }
            </Container>
        )
    }
}

export default withRouter(injectIntl(ProductStockPage))