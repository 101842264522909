import React, { Component } from 'react'
import styled from 'styled-components'
// import { ReactComponent as SVGIcon } from '~/src/svgs/hkhouseIcon.svg'

class AppIcon extends Component {

    render() {
        return (
            <div />
        )
    }
}

export default AppIcon