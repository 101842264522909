import React from 'react'
import { Form, Icon, Input, Button } from 'antd'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import validator from '~/src/lib/validator'

const FormItem = Form.Item

const SubmitButton = styled(Button)`
    margin-right: 16px;
`

class ForgotPasswordForm extends React.Component {

    handleSubmit(e) {
        e.preventDefault()
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values)
                }
            })
        }
    }

    render() {
        const { isSubmitting, intl } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <FormItem>
                    {
                        getFieldDecorator('email', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInputEmail }) },
                                { validator: (rule, value, callback) => validator.validateEmail(rule, value, callback, intl) }
                            ],
                        })(
                            <Input
                                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder={intl.formatMessage({ ...messages.email })}
                                disabled={isSubmitting} />
                        )
                    }
                </FormItem>
                <FormItem>
                    <SubmitButton type="primary" htmlType="submit" loading={isSubmitting}>
                        {intl.formatMessage({ ...messages.submit })}
                    </SubmitButton>
                </FormItem>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(ForgotPasswordForm))