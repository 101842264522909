import React from 'react'
import { inject, observer } from 'mobx-react'
import { Input, Table, Modal, Icon, Button, Upload } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { getRandomId } from '~/src/lib/idGenerator'
import { OPTION_LIMIT } from '~/src/constants/common'
import ProductSubchoiceSelectTableModal from '~/src/components/productSubchoiceSelectTableModal'

@inject('commonStore', 'optionStore') @observer
class ChoiceTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectVisible: false,
            selectedChoiceIndex: undefined,
            previewVisible: false,
            previewImage: ''
        }
    }

    getEmptyChoice() {
        return {
            _id: getRandomId(),
            name: undefined,
            photos: [],
            subchoices: []
        }
    }

    handleOnAddNewClick(index) {
        var oldChoices = this.props.value
        oldChoices.splice(index + 1, 0, this.getEmptyChoice())
        this.props.onChange(oldChoices)
    }

    handleOnDeleteClick(index) {
        var oldChoices = this.props.value
        if (oldChoices.length > 2) {
            oldChoices.splice(index, 1)
            this.props.onChange(oldChoices)
        }
    }

    handleOnValueChange(index, newValue) {
        var oldChoices = this.props.value
        oldChoices[index] = { ...oldChoices[index], ...newValue }
        this.props.onChange(oldChoices)
    }

    handleOnSubchoiceUpdate(subchoices) {
        const { selectedChoiceIndex } = this.state
        this.handleOnValueChange(selectedChoiceIndex, { subchoices: subchoices })
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false })
    }

    handlePreview(file) {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        })
    }

    async handleOnSubchoiceClick(index) {
        const { optionStore, commonStore } = this.props
        const { token, shop } = commonStore
        await optionStore.listProductSubchoice(token, shop, OPTION_LIMIT, 0)
        this.setState({ selectVisible: true, selectedChoiceIndex: index })
    }

    renderImageUploaderArea(record, index) {
        const { intl, isSubmitting } = this.props
        const uploadButton = (
            <Button type='pirmary' icon='plus'>{intl.formatMessage({ ...messages.uploadPhoto })}</Button>
        )
        return (
            <div>
                <Upload
                    listType='picture'
                    fileList={record.photos}
                    onChange={(e) => this.handleOnValueChange(index, { photos: e.fileList })}
                    onPreview={(file) => this.handlePreview(file)}
                    disabled={isSubmitting}
                    beforeUpload={(file) => {
                        return false
                    }}>
                    {record.photos.length >= 1 ? null : uploadButton}
                </Upload>
            </div>
        )
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                render: (text, record, index) => {
                    return (
                        <Input
                            value={record.name}
                            onChange={(e) => this.handleOnValueChange(index, { name: e.target.value })}
                            placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                        />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.photo }),
                dataIndex: 'photos',
                key: 'photos',
                width: 400,
                render: (text, record, index) => {
                    return this.renderImageUploaderArea(record, index)
                },
            },
            {
                title: intl.formatMessage({ ...messages.subchoice }),
                dataIndex: 'subchoices',
                key: 'subchoices',
                width: 100,
                render: (text, record, index) => {
                    const displayText = record.subchoices.length > 0
                        ? `${record.subchoices.map(s => s.fieldName).join(', ')}`
                        : intl.formatMessage({ ...messages.addNew })
                    return <a onClick={() => this.handleOnSubchoiceClick(index)}>{displayText}</a>
                },
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                width: 70,
                render: (text, record, index) => {
                    return (
                        <span>
                            <Icon type="plus" onClick={() => this.handleOnAddNewClick(index)} style={{ marginRight: 8, cursor: 'pointer' }} />
                            <Icon type="delete" onClick={() => this.handleOnDeleteClick(index)} style={{ cursor: 'pointer' }} />
                        </span>
                    )
                }
            },
        ]
    }

    render() {
        const { value } = this.props
        return (
            <div>
                <Table
                    columns={this.renderTableColumn()}
                    dataSource={value}
                    rowKey={record => record._id}
                    pagination={false} />
                <ProductSubchoiceSelectTableModal
                    visible={this.state.selectVisible}
                    choiceId={this.state.selectedChoiceIndex !== undefined ? value[this.state.selectedChoiceIndex]._id : undefined}
                    subchoices={this.state.selectedChoiceIndex !== undefined ? value[this.state.selectedChoiceIndex].subchoices : []}
                    onChange={(subchoices) => this.handleOnSubchoiceUpdate(subchoices)}
                    onClose={() => this.setState({ selectVisible: false, selectedChoiceIndex: undefined })} />
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </div>
        )
    }
}

export default injectIntl(ChoiceTable)