import messages from '~/src/messages'

function getMessage(errorCode) {
    switch (errorCode) {
        case 1003:
            return messages.emailDuplicated
        case 1002:
            return messages.phoneNumberDuplicated
        case 1005:
            return messages.wrongPassword
        case 1007: 
            return messages.emailNotFound
        case 3003:
            return messages.categoryNameDuplicated
        case 3005:
            return messages.categoryIsStillInUseReminder
        case 4002:
            return messages.productIdDuplicated
        case 13008:
            return messages.shopperProductOptionHasBeenLinkedPleaseSelectAnother
        case 14003:
            return messages.shopperUserHasBeenLinked
        case 32004:
            return messages.nameDuplicated
        default:
            return undefined
    }
}

export function hasError(error, errorCode) {
    if (!error) {
        return false
    }
    return error.errorCodes.includes(errorCode)
}

export function getErrorMessage(intl, error, errorCode) {
    if (hasError(error, errorCode)) {
        const text = getMessage(errorCode)
        if (text) {
            return intl.formatMessage({...text})
        } else {
            return null
        }
    } else {
        return null
    }
}