import React from 'react'
import { Form, Checkbox } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import update from 'immutability-helper'

class deliveryMethodsCheckbox extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            deliveryMethods: [
                { value: 'SFEXPRESS', label: props.intl.formatMessage({ ...messages.localExpress }) },
                // { value: 'CONTACT_SELLER', label: props.intl.formatMessage({ ...messages.contactSeller }) },
                { value: 'SELF_PICK_UP', label: props.intl.formatMessage({ ...messages.selfPickUp }) }
            ]
        }
    }

    handleOnValueChange(value) {
        const { onChange } = this.props
        onChange(value)
    }

    render() {
        const { deliveryMethods } = this.state
        const { initialValue } = this.props
        return (
            <Checkbox.Group options={deliveryMethods} defaultValue={initialValue} onChange={(value) => { this.handleOnValueChange(value) }} />
        )
    }
}

export default injectIntl(Form.create()(deliveryMethodsCheckbox))