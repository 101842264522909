import React from 'react'
import styled from 'styled-components'
import { Form, Upload, Icon, Button } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import validator from '~/src/lib/validator'

const TextWithMargin = styled.div`
    margin-right: 16px;
`

class PospalProductImportForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            errors: []
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(
                        values,
                        () => { 
                            this.resetForm() 
                        },
                        (errors) => {
                            this.setState({ errors })
                        }
                    )
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
        this.setState({ errors: [] })
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    handleOnDownloadTemplate() {
        const { intl } = this.props
        const sheetHeader = [
            `${intl.formatMessage({ ...messages.name })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.barcode })}`,
            `${intl.formatMessage({ ...messages.category })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.stock })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.buyPrice })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.sellPrice })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.memberPrice })}`,
            `${intl.formatMessage({ ...messages.wholesellPrice })} (${intl.formatMessage({ ...messages.mustInput })})`
        ]
        const data = [
            [ 'test1', '1111111111111', '燒味外賣', '10', '15', '30', 'Y', '28', '20'],
            [ 'test2', '2222222222222', '燒味外賣', '10', '15', '30', 'Y', '28', '20']
        ]
        import('~/src/lib/xlsxHelper').then(xlsxHelper => {
            xlsxHelper.exportData(sheetHeader, data, 'pos_product_template.xlsx')
        })
    }

    render() {
        const { isSubmitting, intl } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        }
        const uploadButton = (
            <Button>
                <Icon type="upload" /> {intl.formatMessage({...messages.uploadDocument})}
            </Button>
        )
        return (
            <Form {...formItemLayout}>
                <Form.Item label='1'>
                    <TextWithMargin>{intl.formatMessage({ ...messages.downloadTemplateReminder })}</TextWithMargin>
                    <Button onClick={() => this.handleOnDownloadTemplate()}>{intl.formatMessage({ ...messages.downloadTemplate })}</Button>
                </Form.Item>
                <Form.Item
                    label='2'
                    help={intl.formatMessage({...messages.importFileReminder})}>
                    {getFieldDecorator(`file`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: [],
                        rules: [
                            { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) },
                            { validator: (rule, value, callback) => validator.validateExcel(rule, value, callback, intl)}
                        ],
                    })(
                        <Upload
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}>
                            {getFieldValue('file').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item
                    label='Error'>
                        {
                            this.state.errors.map(e => {
                                const { row, field, message } = e
                                return intl.formatMessage({ ...messages.rowErrorWithMessages }, {
                                    row, field, message
                                })
                            })
                        }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(PospalProductImportForm))