import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import ForgotPasswordForm from '~/src/components/forms/forgotPasswordForm'
import PageLayout from '~/src/components/layouts/public'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import messages from '~/src/messages'
import { Helmet } from 'react-helmet'

const ForgotPasswordWrapper = styled.div`
    height: 100vh;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
`

const NoticeWrapper = styled.h1`
    text-align: center;
`

@inject('authStore') @observer
class ForgotPasswordPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isEmailSent: false
        }
    }

    handleOnSubmit(fields) {
        const { email } = fields
        const { authStore } = this.props
        authStore.forgotPassword(email)
            .then(() => {
                this.setState({ isEmailSent: true })
            })
    }

    renderSubmitSuccessNotice() {
        const { intl } = this.props
        return (<NoticeWrapper>{intl.formatMessage({ ...messages.pleaseCheckYourEmail })}</NoticeWrapper>)
    }

    render() {
        const { isEmailSent } = this.state
        const { authStore, intl } = this.props
        const { isSubmitting } = authStore
        return (
            <PageLayout>
                <ForgotPasswordWrapper>
                    <Helmet>
                        <title>{intl.formatMessage({ ...messages.forgotPasswordPageTitle })}</title>
                    </Helmet>
                    {
                        isEmailSent
                            ? (
                                this.renderSubmitSuccessNotice()
                            )
                            : (
                                <ForgotPasswordForm
                                    isSubmitting={isSubmitting}
                                    onSubmit={(fields) => this.handleOnSubmit(fields)} />
                            )
                    }
                </ForgotPasswordWrapper>
            </PageLayout>
        )
    }
}

export default withRouter(injectIntl(ForgotPasswordPage))