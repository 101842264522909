import React from 'react'
import styled from 'styled-components'
import { Form, Input, Select, Button, Icon, Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`


class CreateNotificationForm extends React.Component {

    handleSubmit(e) {
        e.preventDefault()
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { isSubmitting, intl } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
        }
        return (
            <Form {...formItemLayout} style={{ backgroundColor: 'white', padding: 16 }} onSubmit={e => this.handleSubmit(e)}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.title })}>
                    {
                        getFieldDecorator('title', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.title })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.detail })}>
                    {
                        getFieldDecorator('body', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                        })(
                            <Input.TextArea 
                                row={2}
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item>
                    <ActionWrapper>
                        <Button onClick={() => this.resetForm()}>
                            {intl.formatMessage({ ...messages.reset })}
                        </Button>
                        <Button type="primary" htmlType='submit'>
                            {intl.formatMessage({ ...messages.pushNotification })}
                        </Button>
                    </ActionWrapper>
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreateNotificationForm))