import React, { Component } from 'react'
import { Table, Checkbox } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import update from 'immutability-helper'

class AvailabilityTable extends Component {

    handleOnValueChange(id, values) {
        const { value } = this.props
        const dataIndex = value.findIndex(a => a._id === id)
        if (dataIndex > -1) {
            const newAvailability = update(value, { [dataIndex]: { $set: { ...value[dataIndex], ...values } } })
            this.props.onChange(newAvailability)
        }
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                render: (text, record, index) => {
                    return `${record.name}(${record.startTime}-${record.endTime})`
                },
            },
            {
                title: intl.formatMessage({ ...messages.sunday }),
                key: 'sunday',
                render: (text, record, index) => {
                    return (
                        <Checkbox checked={record.hasSunday} onChange={e => this.handleOnValueChange(record._id, { hasSunday: e.target.checked })} />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.monday }),
                key: 'monday',
                render: (text, record, index) => {
                    return (
                        <Checkbox checked={record.hasMonday} onChange={e => this.handleOnValueChange(record._id, { hasMonday: e.target.checked })} />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.tuesday }),
                key: 'tuesday',
                render: (text, record, index) => {
                    return (
                        <Checkbox checked={record.hasTuesday} onChange={e => this.handleOnValueChange(record._id, { hasTuesday: e.target.checked })} />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.wednesday }),
                key: 'wednesday',
                render: (text, record, index) => {
                    return (
                        <Checkbox checked={record.hasWednesday} onChange={e => this.handleOnValueChange(record._id, { hasWednesday: e.target.checked })} />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.thursday }),
                key: 'thursday',
                render: (text, record, index) => {
                    return (
                        <Checkbox checked={record.hasThursday} onChange={e => this.handleOnValueChange(record._id, { hasThursday: e.target.checked })} />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.friday }),
                key: 'friday',
                render: (text, record, index) => {
                    return (
                        <Checkbox checked={record.hasFriday} onChange={e => this.handleOnValueChange(record._id, { hasFriday: e.target.checked })} />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.saturday }),
                key: 'saturday',
                render: (text, record, index) => {
                    return (
                        <Checkbox checked={record.hasSaturday} onChange={e => this.handleOnValueChange(record._id, { hasSaturday: e.target.checked })} />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.selectAll }),
                key: 'selectAll',
                render: (text, record, index) => {
                    return (
                        <Checkbox
                            checked={record.hasSunday && record.hasMonday && record.hasTuesday && record.hasWednesday && record.hasThursday && record.hasFriday && record.hasSaturday}
                            onChange={e => this.handleOnValueChange(
                                record._id,
                                {
                                    hasSunday: e.target.checked,
                                    hasMonday: e.target.checked,
                                    hasTuesday: e.target.checked,
                                    hasWednesday: e.target.checked,
                                    hasThursday: e.target.checked,
                                    hasFriday: e.target.checked,
                                    hasSaturday: e.target.checked,
                                }
                            )}
                        />
                    )
                },
            }
        ]
    }

    render() {
        const { value } = this.props
        return (
            <Table
                columns={this.renderTableColumn()}
                dataSource={value}
                rowKey={record => record._id}
                pagination={false} />
        )
    }
}

export default injectIntl(AvailabilityTable)