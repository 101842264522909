import React from 'react'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import messages from '~/src/messages'
import Helmet from 'react-helmet'

const TitleWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 64px;
`

const Title = styled.div`
    color: white;
`

@inject('authStore', 'commonStore', 'shopStore') @observer
class PageLayout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            width: 0,
            collapsed: false,
        }
    }


    async componentDidMount() {
        const { shopStore, match } = this.props
        const { shopInitial } = match.params
        const hostname = window.location.hostname
        if (hostname !== 'portal.shopper.com.hk' && hostname !== 'localhost') {
            await shopStore.getShopByDomainName(window.location.hostname)
        }
    }

    render() {
        const { children, pathname, commonStore, shopStore, title, intl } = this.props
        const { username } = commonStore
        const isMobile = this.state.width <= 1224

        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, { isMobile: isMobile })
        )

        return (
            <div>
                <Helmet>
                    {
                        shopStore.selfShop && shopStore.selfShop.loginLogo
                            ? <link rel="icon" type="image/*" href={shopStore.selfShop.loginLogo} sizes="16x16" />
                            : null
                    }
                </Helmet>
                {childrenWithProps}
            </div>
        )
    }
}

export default withRouter(injectIntl(PageLayout))