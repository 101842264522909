import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { message } from 'antd'
import messages from '~/src/messages'
import { Helmet } from 'react-helmet'
import EditSelfShopForm from '~/src/components/forms/editSelfShopForm'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

@inject('commonStore', 'shopStore') @observer
class ShopInformationPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            previewVisible: false,
            previewImages: []
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { shopStore, commonStore } = this.props
        const { token } = commonStore
        await shopStore.getSelfShop(token)
    }

    handleOnSelfShopUpdate(values) {
        const { shopStore, commonStore, intl } = this.props
        const { token } = commonStore
        shopStore.updateSelfShop(token, values)
            .then(() => {
                message.success(intl.formatMessage({ ...messages.updateShopInformationSuccess }))
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateShopInformationFailure }))
            })
    }

    render() {
        const { shopStore, intl } = this.props
        const { selfShop } = shopStore
        if (!selfShop) {
            return null
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.shopInformation })}</title>
                </Helmet>
                <EditSelfShopForm
                    initial={toJS(selfShop)}
                    isSubmitting={shopStore.isSubmitting}
                    onSubmit={(values) => this.handleOnSelfShopUpdate(values)}/>
            </Container>
        )
    }
}

export default withRouter(injectIntl(ShopInformationPage))