import React, { Component } from 'react'
import { Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import CreateProductForm from '~/src/components/forms/createProductForm'

class CreateProductModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { intl, visible, isSubmitting, categories, error, shop } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width={960}
                title={intl.formatMessage({ ...messages.createProduct })}
                okText={intl.formatMessage({ ...messages.confirm })}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => this.props.onClose()}>
                {
                    visible
                        ?
                        <CreateProductForm
                            isSubmitting={isSubmitting}
                            categories={categories}
                            error={error}
                            shop={shop}
                            onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                            wrappedComponentRef={(inst) => this.formRef = inst} />
                        : null
                }
            </Modal>
        )
    }
}

export default injectIntl(CreateProductModal)