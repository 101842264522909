import React from 'react'
import styled from 'styled-components'
import { Form, Upload, Icon, Button } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import validator from '~/src/lib/validator'

const TextWithMargin = styled.div`
    margin-right: 16px;
`

const ImageCountWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`
const ErrorWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
`

class ProductImportForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            errors: [],
            progressVisible: false
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(
                        values,
                        () => {
                            this.resetForm()
                        },
                        (errors) => {
                            this.setState({ errors })
                        }
                    )
                    this.setState({ progressVisible: true })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
        this.setState({ errors: [] })
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    normImageFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList.filter(o => o.type.substring(0, 5) === 'image')
    }

    handleOnImageDeleteClick() {
        const { form } = this.props
        const { setFieldsValue } = form
        setFieldsValue({ photos: [] })
    }

    handleOnDownloadTemplate() {
        const { intl } = this.props
        const sheetHeader = [
            `${intl.formatMessage({ ...messages.name })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.category })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.productId })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.introduction })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.description })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.logisticDescription })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.isRecommended })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.group })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.stock })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.buyPrice })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.sellPrice })} (${intl.formatMessage({ ...messages.mustInput })})`,
            `${intl.formatMessage({ ...messages.priority })} (${intl.formatMessage({ ...messages.mustInput })})`,
        ]
        const data = [
            ['test1232', '紅酒', '0007', 'introduction', 'description', 'logisticDescription', 'Y', '超級市場', '100', '20', '28', '1'],
            ['test2123', '紅酒', '0008', 'introduction', 'description', 'logisticDescription', 'Y', '超級市場', '100', '20', '28', '1'],
        ]
        import('~/src/lib/xlsxHelper').then(xlsxHelper => {
            xlsxHelper.exportData(sheetHeader, data, 'product_template.xlsx')
        })
    }

    renderSelectedImageCount(count) {
        const { intl } = this.props
        return (
            <ImageCountWrapper onClick={(e) => e.stopPropagation()}>
                <div>{intl.formatMessage({ ...messages.selectedImageWithCount }, { count })}</div>
                <Icon type='delete' style={{ marginLeft: 8, cursor: 'pointer' }} onClick={() => this.handleOnImageDeleteClick()} />
            </ImageCountWrapper>
        )
    }

    render() {
        const { isSubmitting, intl } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        }
        const uploadButton = (
            <Button>
                <Icon type="upload" /> {intl.formatMessage({ ...messages.uploadDocument })}
            </Button>
        )
        const uploadImageButton = (
            <Button>
                <Icon type="upload" /> {intl.formatMessage({ ...messages.uploadImageDirectory })}
            </Button>
        )
        return (
            <Form {...formItemLayout}>
                <Form.Item label='1'>
                    <TextWithMargin>{intl.formatMessage({ ...messages.downloadTemplateReminder })}</TextWithMargin>
                    <Button onClick={() => this.handleOnDownloadTemplate()}>{intl.formatMessage({ ...messages.downloadTemplate })}</Button>
                </Form.Item>
                <Form.Item
                    label='2'
                    help={intl.formatMessage({ ...messages.importFileReminder })}>
                    {getFieldDecorator(`file`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: [],
                        rules: [
                            { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) },
                            { validator: (rule, value, callback) => validator.validateExcel(rule, value, callback, intl) }
                        ],
                    })(
                        <Upload
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}>
                            {getFieldValue('file').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item
                    label='3'
                    help={intl.formatMessage({ ...messages.importProductImageReminder })}>
                    {
                        getFieldDecorator(`photos`, {
                            valuePropName: 'fileList',
                            getValueFromEvent: (e) => this.normImageFile(e),
                            initialValue: [],
                            rules: [
                                // { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) },
                                // { validator: (rule, value, callback) => validator.validateJPG(rule, value, callback, intl)}
                            ],
                        })(
                            <Upload
                                accept="image/*"
                                disabled={isSubmitting}
                                directory
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    return false
                                }}>
                                {getFieldValue('photos').length > 0 ? this.renderSelectedImageCount(getFieldValue('photos').length) : uploadImageButton}
                            </Upload>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label='Error'>
                    <ErrorWrapper>
                        {
                            this.state.errors.map((e, i) => {
                                const { row, field, message } = e
                                if (row && field) {
                                    return (
                                        <div key={i}>
                                            {
                                                intl.formatMessage({ ...messages.rowErrorWithMessages }, {
                                                    row, field, message
                                                })
                                            }
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={i}>{message}</div>
                                    )
                                }
                            })
                        }
                    </ErrorWrapper>
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(ProductImportForm))