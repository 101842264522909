import React from 'react'
import { Form, Icon, Input, Button, Row } from 'antd'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import validator from '~/src/lib/validator'
import { hasError, getErrorMessage } from '~/src/lib/errorMessages'

const LoginButton = styled(Button)`
    margin-right: 16px;
`

class LoginForm extends React.Component {

    handleSubmit(e) {
        e.preventDefault()
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onLogin(values)
                }
            })
        }
    }

    render() {
        const { isSubmitting, error, intl } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Form.Item
                    validateStatus={hasError(error, 1007) ? 'error' : null}
                    help={getErrorMessage(intl, error, 1007)}>
                    {
                        getFieldDecorator('email', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInputEmail }) },
                                { validator: (rule, value, callback) => validator.validateEmail(rule, value, callback, intl) }
                            ],
                        })(
                            <Input
                                prefix={<Icon type="mail" style={{ color: window.shopperColor }} />}
                                placeholder={intl.formatMessage({ ...messages.email })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    validateStatus={hasError(error, 1005) ? 'error' : null}
                    help={getErrorMessage(intl, error, 1005)}>
                    {
                        getFieldDecorator('password', {
                            rules: [{ required: true, message: intl.formatMessage({ ...messages.pleaseInputPassword }) }],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: window.shopperColor }} />}
                                type="password"
                                placeholder={intl.formatMessage({ ...messages.password })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item>
                    <LoginButton type="primary" block htmlType="submit" loading={isSubmitting}>
                        {intl.formatMessage({ ...messages.login })}
                    </LoginButton>
                </Form.Item>
                <Form.Item>
                    <Row type='flex' justify='center'>
                        <a href="/forgotPassword" style={{ textAlign: 'center' }}>{intl.formatMessage({ ...messages.forgotPassword })}</a>
                    </Row>
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(LoginForm))