import React, { Component } from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { DATE_FORMAT } from '~/src/constants/format'
import styled from 'styled-components'
import messages from '~/src/messages'
import moment from 'moment'
import { Table, Button, message, Modal, DatePicker, Icon, Checkbox, Row, Tooltip, Input, Tag, Divider, Popconfirm } from 'antd'
import { statusOptions, pageSizeOptions } from '~/src/constants/options'
import CreateCouponModal from '~/src/components/createCouponModal'
import EditCouponModal from '~/src/components/editCouponModal'
import update from 'immutability-helper'
import { LIMIT } from '~/src/constants/common'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

@inject('commonStore', 'couponStore') @observer
class CouponManagementModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            createCouponVisible: false,
            editCouponVisible: false,
            pageSize: LIMIT,
            filterValues: {
                name: undefined,
                stateDateMin: undefined,
                stateDateMax: undefined,
                endDateMin: undefined,
                endDateMax: undefined,
                createdAtMin: undefined,
                createdAtMax: undefined,
                status: [],
            },
            selectedCouponId: undefined,
            sortedInfo: undefined,
            previewVisible: false,
            previewImage: undefined
        }
        this.formRef = React.createRef()
    }

    handleOnEditClick(record) {
        this.setState({ editCouponVisible: true, selectedCouponId: record._id })
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false, previewImage: undefined })
    }

    handlePreview(url) {
        this.setState({
            previewImage: url,
            previewVisible: true,
        })
    }

    handleOnCouponCreate(values, reset) {
        const { couponStore, commonStore, intl, product } = this.props
        const { token, shop } = commonStore
        const { currentPage, pageSize, sortedInfo, filterValues } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        couponStore.createProductCoupon(token, shop, product._id, values)
            .then(() => {
                reset()
                this.setState({ createCouponVisible: false })
                message.success(intl.formatMessage({ ...messages.createCouponSuccess }))
                couponStore.listProductCoupon(token, shop, product._id, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.name,
                    filterValues.stateDateMin ? filterValues.stateDateMin.format() : undefined,
                    filterValues.stateDateMax ? filterValues.stateDateMax.format() : undefined,
                    filterValues.endDateMin ? filterValues.endDateMin.format() : undefined,
                    filterValues.endDateMax ? filterValues.endDateMax.format() : undefined,
                    filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                    filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined,
                    filterValues.status
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.createCouponFailure }))
            })
    }

    handleOnCouponUpdate(values, reset) {
        const { couponStore, commonStore, intl, product } = this.props
        const { token, shop } = commonStore
        const { currentPage, pageSize, sortedInfo, filterValues } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        couponStore.updateCoupon(token, shop, this.state.selectedCouponId, values)
            .then(() => {
                reset()
                this.setState({ editCouponVisible: false, selectedCouponId: undefined })
                message.success(intl.formatMessage({ ...messages.updateCouponSuccess }))
                couponStore.listProductCoupon(token, shop, product._id, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.name,
                    filterValues.stateDateMin ? filterValues.stateDateMin.format() : undefined,
                    filterValues.stateDateMax ? filterValues.stateDateMax.format() : undefined,
                    filterValues.endDateMin ? filterValues.endDateMin.format() : undefined,
                    filterValues.endDateMax ? filterValues.endDateMax.format() : undefined,
                    filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                    filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined,
                    filterValues.status
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateCouponFailure }))
            })
    }

    async handleOnCouponDelete(record) {
        const { couponStore, commonStore, intl } = this.props
        const { token, shop } = commonStore
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await couponStore.deleteCoupon(token, shop, record._id)
            message.success(intl.formatMessage({ ...messages.deleteCouponSuccess }))
            couponStore.listShopCoupon(token, shop, pageSize, pageSize * (currentPage - 1),
                sortField,
                ['SHOPPER'],
                filterValues.name,
                filterValues.startDateMin ? filterValues.startDateMin.format() : undefined,
                filterValues.startDateMax ? filterValues.startDateMax.format() : undefined,
                filterValues.endDateMin ? filterValues.endDateMin.format() : undefined,
                filterValues.endDateMax ? filterValues.endDateMax.format() : undefined,
                filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined,
                filterValues.status)
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.deleteCouponFailure }))

        }
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { couponStore, commonStore, product } = this.props
        const { token, shop } = commonStore
        const { pageSize } = pagination
        const {
            name,
            stateDateMin,
            stateDateMax,
            endDateMin,
            endDateMax,
            createdAtMin,
            createdAtMax,
            status,
        } = this.state.filterValues
        couponStore.listProductCoupon(token, shop, product._id, pageSize, pageSize * (page - 1),
            sortField,
            name,
            stateDateMin ? stateDateMin.format() : undefined,
            stateDateMax ? stateDateMax.format() : undefined,
            endDateMin ? endDateMin.format() : undefined,
            endDateMax ? endDateMax.format() : undefined,
            createdAtMin ? createdAtMin.format() : undefined,
            createdAtMax ? createdAtMax.format() : undefined,
            status
        )
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'name':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'startDate':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'startDateMin': { $set: undefined }, 'startDateMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'endDate':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'endDateMin': { $set: undefined }, 'endDateMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'createdAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'createdAtMin': { $set: undefined }, 'createdAtMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                name: undefined,
                stateDateMin: undefined,
                stateDateMax: undefined,
                endDateMin: undefined,
                endDateMax: undefined,
                createdAtMin: undefined,
                createdAtMax: undefined,
                status: [],
            }
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'name':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'startDate':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.startDateMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.fromTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'startDateMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.startDateMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.toTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'startDateMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            case 'endDate':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.endDateMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.fromTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'endDateMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.endDateMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.toTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'endDateMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            case 'createdAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.fromTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.toTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'name')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
                width: 100,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'name' && sortedInfo.order,
                ...this.getColumnSearchProps('name')
            },
            {
                title: intl.formatMessage({ ...messages.photo }),
                dataIndex: 'photo',
                key: 'photo',
                width: 100,
                fixed: 'left',
                render: (text, record) => {
                    return (
                        <a onClick={() => this.handlePreview(record.photo)}>
                            <img src={record.photo} height={50} width={50} style={{ objectFit: 'contain' }} />
                        </a>
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.priority }),
                dataIndex: 'priority',
                key: 'priority',
            },
            {
                title: intl.formatMessage({ ...messages.creditRequired }),
                dataIndex: 'credit',
                key: 'credit',
                width: 100,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'credit' && sortedInfo.order,
            },
            {
                title: intl.formatMessage({ ...messages.minPriceRequired }),
                dataIndex: 'minPriceRequired',
                key: 'minPriceRequired',
                width: 150,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'minPriceRequired' && sortedInfo.order,
                render: (text) => {
                    return `$ ${text}`
                }
            },
            {
                title: intl.formatMessage({ ...messages.discount }),
                dataIndex: 'discount',
                key: 'discount',
                width: 130,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'discount' && sortedInfo.order,
                render: (text) => {
                    return `$ ${text}`
                }
            },
            {
                title: intl.formatMessage({ ...messages.couponTotalAmount }),
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                width: 130,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'totalAmount' && sortedInfo.order,
                render: (text) => {
                    return intl.formatMessage({ ...messages.couponCountUnit }, { count: text })
                }
            },
            {
                title: intl.formatMessage({ ...messages.maxPurchase }),
                dataIndex: 'maxPurchase',
                key: 'maxPurchase',
                width: 130,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'maxPurchase' && sortedInfo.order,
                render: (text) => {
                    return intl.formatMessage({ ...messages.frequencyUnit }, { count: text })
                }
            },
            {
                title: intl.formatMessage({ ...messages.termsAndConditions }),
                dataIndex: 'terms',
                key: 'terms',
                onCell: () => {
                    return {
                        style: {
                            whiteSpace: 'nowrap',
                            maxWidth: 200,
                        }
                    }
                },
                render: (text, record) => {
                    var tmp = document.createElement("DIV")
                    tmp.innerHTML = record.detail
                    return (
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: record.terms }}></div>}>
                            <EllipsisText>{tmp.textContent || tmp.innerText}</EllipsisText>
                        </Tooltip>
                    )
                },
            },

            {
                title: intl.formatMessage({ ...messages.detail }),
                dataIndex: 'detail',
                key: 'detail',
                onCell: () => {
                    return {
                        style: {
                            whiteSpace: 'nowrap',
                            maxWidth: 200,
                        }
                    }
                },
                render: (text, record) => {
                    var tmp = document.createElement("DIV")
                    tmp.innerHTML = record.detail
                    return (
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: record.detail }}></div>}>
                            <EllipsisText>{tmp.textContent || tmp.innerText}</EllipsisText>
                        </Tooltip>
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.fromTime }),
                dataIndex: 'startDate',
                key: 'startDate',
                sorter: true,
                width: 200,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'startDate' && sortedInfo.order,
                render: (startDate) => (
                    <span>
                        {moment(startDate).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('startDate')
            },
            {
                title: intl.formatMessage({ ...messages.toTime }),
                dataIndex: 'endDate',
                key: 'endDate',
                sorter: true,
                width: 200,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'endDate' && sortedInfo.order,
                render: (endDate) => (
                    <span>
                        {moment(endDate).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('endDate')
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'status' && sortedInfo.order,
                render: (text, record) => {
                    const index = statusOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                width: 200,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('createdAt')
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 100,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                            <Divider type='vertical' />
                            <Popconfirm
                                title={intl.formatMessage({ ...messages.deleteReminder })}
                                onConfirm={() => this.handleOnCouponDelete(record)}
                                okText={intl.formatMessage({ ...messages.yes })}
                                cancelText={intl.formatMessage({ ...messages.no })}>
                                <a>
                                    {intl.formatMessage({ ...messages.delete })}
                                </a>
                            </Popconfirm>
                        </span>
                    )
                }
            }
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'name':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.name })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'startDateMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.startDateMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'startDateMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.startDateMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'endDateMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.endDateMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'endDateMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.endDateMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'createdAtMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'createdAtMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { intl, visible, isSubmitting, couponStore } = this.props
        const { currentPage, pageSize, selectedCouponId } = this.state
        var selectedCouponIndex
        if (selectedCouponId) {
            const index = couponStore.coupons.findIndex(r => r._id === selectedCouponId)
            selectedCouponIndex = index
        }
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                footer={null}
                title={intl.formatMessage({ ...messages.couponManagement })}
                onCancel={() => this.props.onClose()}>
                <ActionWrapper>
                    <Button type='primary' onClick={() => this.setState({ createCouponVisible: true })}>
                        {intl.formatMessage({ ...messages.createCoupon })}
                    </Button>
                    <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                        {intl.formatMessage({ ...messages.resetAllSearch })}
                    </Button>
                </ActionWrapper>
                <ActionWrapper>
                    { this.renderFilterTags() }
                </ActionWrapper>
                <Table
                    columns={this.renderTableColumn()}
                    dataSource={toJS(couponStore.coupons)}
                    pagination={
                        {
                            showSizeChanger: true,
                            defaultPageSize: LIMIT,
                            pageSizeOptions: pageSizeOptions,
                            showQuickJumper: true,
                            current: +currentPage,
                            pageSize: pageSize,
                            total: couponStore.count,
                            showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                        }
                    }
                    scroll={{ x: 2000 }}
                    onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                    loading={isSubmitting}
                    rowKey={record => record._id}
                />
                <CreateCouponModal
                    visible={this.state.createCouponVisible}
                    isSubmitting={couponStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnCouponCreate(values, reset)}
                    onClose={() => this.setState({ createCouponVisible: false })} />
                <EditCouponModal
                    coupon={toJS(couponStore.coupons[selectedCouponIndex])}
                    visible={this.state.editCouponVisible}
                    isSubmitting={couponStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnCouponUpdate(values, reset)}
                    onClose={() => this.setState({ editCouponVisible: false, selectedCouponId: undefined })} />
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Modal>
        )
    }
}

export default injectIntl(CouponManagementModal)