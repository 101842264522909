import React, { Component } from 'react'
import styled from 'styled-components'
import { Menu, Icon } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { withRouter } from 'react-router-dom'

const SiderWrapper = styled(Menu)`
    min-height: 100vh;
`

class Sider extends Component {

    handleOnMenuItemClick(e) {
        if (e.key === 'home') {
            this.props.history.push(`/`)
        } else {
            this.props.history.push(`/${e.item.props.route}`)
        }
    }

    getSiderItems() {
        const { shop } = this.props
        if (!shop) {
            return []
        }
        var siderItems = [
            {
                key: 'staffManagement',
                value: 'staffManagement',
                text: messages.staffManagement,
                icon: 'team',
                route: 'staffManagement'
            },
            {
                key: 'mainPageAdvertisement',
                value: 'mainPageAdvertisement',
                text: messages.mainPageAdvertisement,
                icon: 'picture',
                route: 'mainPageAdvertisement'
            },
            {
                key: 'feedManagement',
                value: 'feedManagement',
                text: messages.feedManagement,
                icon: 'fire',
                route: 'feedManagement'
            },
            {
                key: 'memberList',
                value: 'memberList',
                text: messages.memberList,
                icon: 'user',
                route: 'memberList'
            },
            {
                key: 'memberCredit',
                value: 'memberCredit',
                text: messages.memberCredit,
                icon: 'red-envelope',
                route: 'memberCredit'
            },
            {
                key: 'shopInformation',
                value: 'shopInformation',
                text: messages.shopInformation,
                icon: 'shop',
                route: 'shopInformation'
            },
        ]
        switch (shop.type) {
            case 'RETAIL':
                return [
                    {
                        key: 'home',
                        value: 'home',
                        text: messages.home,
                        icon: 'home',
                        route: 'home'
                    },
                    {
                        key: 'product',
                        value: 'product',
                        text: messages.productManagement,
                        icon: 'shopping-cart',
                        subMenu: [
                            { key: 'productList', value: 'productList', route: 'productList', text: messages.productList },
                            { key: 'categoryManagement', value: 'categoryManagement', route: 'categoryManagement', text: messages.categoryManagement },
                            {
                                key: 'productOption',
                                value: 'productOption',
                                route: 'productOption',
                                text: messages.optionManagement
                            },
                            {
                                key: 'productSubchoice',
                                value: 'productSubchoice',
                                route: 'productSubchoice',
                                text: messages.subchoiceManagement
                            },
                            { key: 'productStock', value: 'productStock', route: 'productStock', text: messages.productStock },
                        ]
                    },
                    {
                        key: 'couponManagement',
                        value: 'couponManagement',
                        text: messages.couponManagement,
                        icon: 'tags',
                        route: 'couponManagement'
                    },
                    {
                        key: 'orderManagement',
                        value: 'orderManagement',
                        text: messages.orderManagement,
                        icon: 'credit-card',
                        route: 'orderManagement'
                    },
                    ...siderItems,
                    {
                        key: 'dataAnalysis',
                        value: 'dataAnalysis',
                        text: messages.dataAnalysis,
                        icon: 'pie-chart',
                        subMenu: [
                            { key: 'memberAnalysis', value: 'memberAnalysis', route: 'memberAnalysis', text: messages.memberAnalysis },
                            { key: 'memberBehaviorAnalysis', value: 'memberBehaviorAnalysis', route: 'memberBehaviorAnalysis', text: messages.memberBehaviorAnalysis },
                            { key: 'saleAnalysis', value: 'saleAnalysis', route: 'saleAnalysis', text: messages.saleAnalysis },
                        ]
                    },
                    {
                        key: 'notification',
                        value: 'notification',
                        text: messages.pushNotification,
                        icon: 'notification',
                        route: 'notification'
                    },
                    {
                        key: 'scanPayment',
                        value: 'scanPayment',
                        text: messages.scanPayment,
                        icon: 'scan',
                        route: 'scanPayment'
                    },
                    {
                        key: 'pospal',
                        value: 'pospal',
                        text: messages.pospalManagement,
                        icon: 'bar-chart',
                        route: 'pospal',
                        subMenu: [
                            { key: 'pospalTickets', value: 'pospalTickets', route: 'pospalTickets', text: messages.pospalTickets },
                            { key: 'pospalTicketPrint', value: 'pospalTicketPrint', route: 'pospalTicketPrint', text: messages.pospalTicketPrint },
                            { key: 'pospalProduct', value: 'pospalProduct', route: 'pospalProduct', text: messages.pospalProduct },
                            { key: 'pospalCategory', value: 'pospalCategory', route: 'pospalCategory', text: messages.pospalCategory },
                            // { key: 'pospalOptionGroup', value: 'pospalOptionGroup', route: 'pospalOptionGroup', text: messages.pospalOptionGroup },
                            { key: 'pospalMember', value: 'pospalMember', route: 'pospalMember', text: messages.pospalMemberList },
                        ]
                    }
                ]
            case 'RESTAURANT':
                return [
                    {
                        key: 'offlineCoupon',
                        value: 'offlineCoupon',
                        text: messages.offlineCoupon,
                        icon: 'tags',
                        route: 'offlineCoupon'
                    },
                    {
                        key: 'onlineCoupon',
                        value: 'onlineCoupon',
                        text: messages.onlineCoupon,
                        icon: 'tags',
                        route: 'onlineCoupon'
                    },
                    {
                        key: 'foodOrder',
                        value: 'foodOrder',
                        text: messages.orderManagement,
                        icon: 'credit-card',
                        route: 'foodOrder'
                    },
                    {
                        key: 'notification',
                        value: 'notification',
                        text: messages.pushNotification,
                        icon: 'notification',
                        route: 'notification'
                    },
                    ...siderItems
                ]
            case 'RESTAURANT_SHOPPER':
                return [
                    {
                        key: 'product',
                        value: 'product',
                        text: messages.productManagement,
                        icon: 'shopping-cart',
                        subMenu: [
                            { key: 'productList', value: 'productList', route: 'productList', text: messages.productList },
                            { key: 'categoryManagement', value: 'categoryManagement', route: 'categoryManagement', text: messages.categoryManagement },
                            {
                                key: 'deliveryTimeSlot',
                                value: 'deliveryTimeSlot',
                                route: 'deliveryTimeSlot',
                                text: messages.deliveryTimeSlot
                            },
                            {
                                key: 'foodOption',
                                value: 'foodOption',
                                route: 'foodOption',
                                text: messages.optionManagement
                            },
                            {
                                key: 'foodSubchoice',
                                value: 'foodSubchoice',
                                route: 'foodSubchoice',
                                text: messages.subchoiceManagement
                            },
                        ]
                    },
                    {
                        key: 'couponManagement',
                        value: 'couponManagement',
                        text: messages.couponManagement,
                        icon: 'tags',
                        route: 'couponManagement'
                    },
                    {
                        key: 'foodOrder',
                        value: 'foodOrder',
                        text: messages.orderManagement,
                        icon: 'credit-card',
                        route: 'foodOrder'
                    },
                    {
                        key: 'dataAnalysis',
                        value: 'dataAnalysis',
                        text: messages.dataAnalysis,
                        icon: 'pie-chart',
                        subMenu: [
                            { key: 'memberAnalysis', value: 'memberAnalysis', route: 'memberAnalysis', text: messages.memberAnalysis },
                            { key: 'memberFoodBehaviorAnalysis', value: 'memberFoodBehaviorAnalysis', route: 'memberFoodBehaviorAnalysis', text: messages.memberBehaviorAnalysis },
                            { key: 'foodSaleAnalysis', value: 'foodSaleAnalysis', route: 'foodSaleAnalysis', text: messages.saleAnalysis },
                        ]
                    },
                    ...siderItems,
                ]
            default:
                return []
        }
    }

    renderMenuItems() {
        const { isMobile, intl } = this.props
        return this.getSiderItems().map((item, index) => {

            if (!item.subMenu) {
                return <Menu.Item key={item.key} route={item.route}><span><Icon type={item.icon} /><span>{intl.formatMessage({ ...item.text })}</span></span></Menu.Item>
            }

            const subMenuItems = item.subMenu.map((subMenuItem, subIndex) => {
                return <Menu.Item key={subMenuItem.key} route={subMenuItem.route}>{intl.formatMessage({ ...subMenuItem.text })}</Menu.Item>
            })
            return (
                <Menu.SubMenu key={item.key} title={<span><Icon type={item.icon} /><span>{intl.formatMessage({ ...item.text })}</span></span>}>
                    {subMenuItems}
                </Menu.SubMenu>
            )
        })
    }

    getSelectedKey() {
        if (this.props.location.pathname === '/') {
            return 'home'
        }
        const tempArray = this.props.location.pathname.substring(1).split('/')
        return tempArray[0]
    }

    getOpenKey() {
        const tempArray = this.props.location.pathname.substring(1).split('/')
        const selectedPage = tempArray[0]
        switch (selectedPage) {
            case 'saleAnalysis':
            case 'memberAnalysis':
            case 'memberBehaviorAnalysis':
            case 'memberFoodBehaviorAnalysis':
            case 'foodSaleAnalysis':
                return 'dataAnalysis'
            case 'productList':
            case 'categoryManagement':
            case 'productOption':
            case 'productSubchoice':
            case 'foodOption':
            case 'foodSubchoice':
            case 'deliveryTimeSlot':
            case 'productStock':
                return 'product'
            case 'pospalTickets':
            case 'pospalTicketPrint':
            case 'pospalProduct':
            case 'pospalCategory':
            case 'pospalMember':
                return 'pospal'
            default:
                return selectedPage
        }
    }

    render() {
        return (
            <SiderWrapper
                theme='dark'
                onClick={(e) => this.handleOnMenuItemClick(e)}
                style={{ maxWidth: 256 }}
                defaultSelectedKeys={[this.getSelectedKey()]}
                defaultOpenKeys={[this.getOpenKey()]}
                mode="inline">
                {
                    this.renderMenuItems()
                }
            </SiderWrapper>
        )
    }
}

export default withRouter(injectIntl(Sider))
