import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { DATE_FORMAT } from '~/src/constants/format'
import styled from 'styled-components'
import messages from '~/src/messages'
import moment from 'moment'
import { LIMIT } from '~/src/constants/common'
import { Table, Button, Tag, Modal, Input, Icon, Checkbox, Row, Descriptions } from 'antd'
import { statusOptions, pageSizeOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import { toJS } from 'mobx'
import UpdateMemberCreditForm from '~/src/components/forms/updateMemberCreditForm'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

@inject('commonStore', 'userStore') @observer
class UpdateMemberCreditModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { userStore, intl, visible, member } = this.props
        if (!member) {
            return null
        }
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.addCredit })}
                okText={intl.formatMessage({ ...messages.confirm })}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => {
                    this.props.onClose()
                    this.formRef.resetForm()
                }}>
                <Descriptions>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.username })}>{member.username}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.email })}>{member.email}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.credit })}>{member.credit}</Descriptions.Item>
                </Descriptions>
                <UpdateMemberCreditForm
                    isSubmitting={userStore.isSubmitting}
                    onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                    wrappedComponentRef={(inst) => this.formRef = inst} />
            </Modal>
        )
    }
}

export default injectIntl(UpdateMemberCreditModal, { withRef: true })
