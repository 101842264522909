import React from 'react'
import { Form, Input, Select, InputNumber, Upload, Icon, Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { statusOptions } from '~/src/constants/options'

class CreateAdsBannerForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: ''
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }
    
    resetForm() {
        this.props.form.resetFields()
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false })
    }

    handlePreview(file) {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        })
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    renderImageUploaderArea() {
        const { intl, isSubmitting, initial } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">{intl.formatMessage({ ...messages.uploadPhoto })}</div>
            </div>
        )
        return (
            <Form.Item
                label={intl.formatMessage({ ...messages.uploadPhoto })}
                help={intl.formatMessage({...messages.bannerRatioReminder})}>
                {getFieldDecorator(`photos`, {
                    valuePropName: 'fileList',
                    getValueFromEvent: (e) => this.normFile(e),
                    initialValue: ( initial && initial.photo )? [{
                        uid: '1',
                        name: 'xxx.png',
                        status: 'done',
                        url: initial.photo,
                    }] : []
                })(
                    <Upload
                        listType="picture-card"
                        onPreview={(file) => this.handlePreview(file)}
                        disabled={isSubmitting}
                        beforeUpload={(file) => {
                            return false
                        }}
                    >
                        {getFieldValue('photos').length >= 1 ? null : uploadButton}
                    </Upload>
                )}
            </Form.Item>
        )
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.detail })}>
                    {
                        getFieldDecorator('detail', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.detail : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.detail })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.sort })}>
                    {
                        getFieldDecorator('sort', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.sort : undefined
                        })(
                            <InputNumber
                                placeholder={intl.formatMessage({ ...messages.sort })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                { this.renderImageUploaderArea() }
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreateAdsBannerForm))