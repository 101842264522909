import { observable, flow, action } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'
import shopStore from './shopStore'

class ProductStore {
    @observable isSubmitting = false
    @observable error = null
    @observable products = []
    @observable count = 0
    @observable currentOption = null
    @observable currentProduct = null
    @observable importProductTotalCount = 0
    @observable importedProductCount = 0
    @observable productOptions = []
    @observable productStocks = []
    @observable productStockCount = 0
    @observable foodOptions = []
    @observable searchedProducts = []
    @observable productStockHistory = []
    @observable productStockAlert = []

    createProduct = flow(function* (token, shop, values) {
        this.isSubmitting = true
        const formData = new FormData()
        formData.append('name', values.name)
        formData.append('introduction', values.introduction)
        formData.append('productId', shopStore.selfShop.initial + values.productId)
        formData.append('description', values.description.toHTML())
        formData.append('logisticDescription', values.logisticDescription.toHTML())
        formData.append('isRecommended', values.isRecommended)
        formData.append('productType', values.productType)
        formData.append('group', values.group)
        formData.append('category', values.category[values.category.length - 1])
        formData.append('price', values.price)
        formData.append('cost', values.cost)
        formData.append('stock', values.stock)
        formData.append('status', values.status)
        formData.append('priority', values.priority)
        for (const f of values.photos) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('photos', thumbnail)
            }
        }
        try {
            yield api.createProduct(token, shop, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listProduct = flow(function* (token, shop, limit, skip, shopType,
        sortField,
        searchText,
        productId,
        name,
        isRecommended,
        group,
        category,
        productType,
        status,
        createdAtMin,
        createdAtMax
    ) {
        this.isSubmitting = true
        console.log(sortField)
        try {
            const response = yield api.listProduct(token, shop, limit, skip, shopType,
                sortField,
                searchText,
                productId,
                name,
                isRecommended,
                group,
                category,
                productType,
                status,
                createdAtMin,
                createdAtMax
            )
            const { products, count } = response.data
            this.products = products
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getProduct = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            const response = yield api.getProduct(token, shop, id)
            const product = response.data
            const index = this.products.findIndex((b) => {
                return b._id === id
            })
            this.products[index] = product
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateProduct = flow(function* (token, shop, id, values) {
        this.isSubmitting = true
        const formData = new FormData()
        formData.append('name', values.name)
        formData.append('introduction', values.introduction)
        formData.append('productId', shopStore.selfShop.initial + values.productId)
        formData.append('description', values.description.toHTML())
        formData.append('logisticDescription', values.logisticDescription.toHTML())
        formData.append('isRecommended', values.isRecommended)
        formData.append('productType', values.productType)
        formData.append('group', values.group)
        formData.append('category', values.category[values.category.length - 1])
        formData.append('price', values.price)
        formData.append('cost', values.cost)
        formData.append('stock', values.stock)
        formData.append('status', values.status)
        formData.append('priority', values.priority)
        const index = this.products.findIndex((b) => {
            return b._id === id
        })
        const originalPhotoIds = this.products[index].photos.map(p => p._id)
        const preservedPhotoIds = []
        for (const f of values.photos) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('photos', thumbnail)
            } else {
                const { uid } = f
                preservedPhotoIds.push(uid)
            }
        }
        const removePhotos = originalPhotoIds.filter(id => preservedPhotoIds.indexOf(id) < 0)

        removePhotos.forEach((p) => {
            formData.append('removePhotos[]', p)
        })

        try {
            const response = yield api.updateProduct(token, shop, id, formData)
            this.products[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteProduct = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            yield api.deleteProduct(token, shop, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listProductOption = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            const response = yield api.listProductOption(token, shop, id)
            console.log(response)
            this.productOptions = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listProductFoodOption = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            const response = yield api.listProductFoodOption(token, shop, id)
            this.foodOptions = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateProductOption = flow(function* (token, shop, id, optionIds) {
        this.isSubmitting = true
        try {
            yield api.updateProductOption(token, shop, id, optionIds)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    groupUpdateProductOption = flow(function* (token, shop, productIds, optionIds) {
        this.isSubmitting = true
        try {
            yield api.groupUpdateProductOption(token, shop, productIds, optionIds)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateProductFoodOption = flow(function* (token, shop, id, optionIds) {
        this.isSubmitting = true
        try {
            yield api.updateProductFoodOption(token, shop, id, optionIds)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    groupUpdateProductFoodOption = flow(function* (token, shop, productIds, optionIds) {
        this.isSubmitting = true
        try {
            yield api.groupUpdateProductFoodOption(token, shop, productIds, optionIds)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
    
    updateProductStocklessOption = flow(function* (token, shop, id, optionIds) {
        this.isSubmitting = true
        try {
            yield api.updateProductStocklessOption(token, shop, id, optionIds)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    getProductForPospalStockTake = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            const productResponse = yield api.getProduct(token, shop, id)
            this.currentProduct = productResponse.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listProductStock = flow(function* (token, shop, id, limit, skip, sort) {
        this.isSubmitting = true
        try {
            const response = yield api.listProductStock(token, shop, id, limit, skip, sort)
            const { stocks, count } = response.data
            this.productStocks = stocks
            this.productStockCount = count
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    updateProductStock = flow(function* (token, shop, id, stocks) {
        this.isSubmitting = true
        console.log(stocks)
        try {
            yield api.updateProductStock(token, shop, id, stocks)
            // this.productStocks = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    importProduct = flow(function* (token, shop, data) {
        this.isSubmitting = true
        this.importedProductCount = 0
        this.importProductTotalCount = data.length
        try {
            for (var d of data) {
                const formData = new FormData()
                formData.append('name', d.name)
                formData.append('introduction', d.introduction)
                formData.append('productId', shopStore.selfShop.initial + d.productId)
                formData.append('description', d.description)
                formData.append('logisticDescription', d.logisticDescription)
                formData.append('isRecommended', d.isRecommended.toLowerCase() === 'y' ? true : false)
                formData.append('group', d.group)
                formData.append('category', d.category)
                formData.append('price', Math.round(Number(d.sellPrice)))
                formData.append('stock', Number(d.stock))
                formData.append('cost', Math.round(Number(d.buyPrice)))
                formData.append('priority', Number(d.priority))
                if (d.photo) {
                    const { originFileObj } = d.photo
                    if (originFileObj) {
                        const thumbnail = yield makeOriginal(originFileObj)
                        formData.append('photo', thumbnail)
                    }
                }
                yield api.importProduct(token, shop, formData)
                this.importedProductCount += 1
            }

            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    searchProduct = flow(function* (token, shop, query) {
        this.isSubmitting = true
        console.log(query)
        try {
            const response = yield api.searchProduct(token, shop, query)
            this.searchedProducts = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    getProductStockHistory = flow(function* (token, shop, product) {
        this.isSubmitting = true
        try {
            const response = yield api.getProductStockHistory(token, shop, product)
            this.productStockHistory = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listProductStockAlert = flow(function* (token, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.listProductStockAlert(token, shop)
            console.log(response.data)
            this.productStockAlert = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new ProductStore()
