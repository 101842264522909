import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import api from './api'

const shopFetching = async () => {
    var themeColor = '#EA5A39'
    if (window.location.hostname !== 'portal.shopper.com.hk' && window.location.hostname !== 'localhost' && window.location.hostname !== 'mall-portal.shopper.com.hk') {
        const response = await api.getShopByDomainName(window.location.hostname)

        if (response.data.websiteThemeColor) {
            themeColor = response.data.websiteThemeColor
        }
    }

    window.less.modifyVars({
        '@primary-color': themeColor
    })

    window.shopperColor = themeColor
    ReactDOM.render(
        <App color={themeColor} />,
        document.getElementById('root')
    )
}

shopFetching()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
