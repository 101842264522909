import React from 'react'
import { Input, Table, Icon } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { getRandomId } from '~/src/lib/idGenerator'

class ChoiceTable extends React.Component {

    getEmptyChoice() {
        return {
            _id: getRandomId(),
            name: undefined
        }
    }

    handleOnAddNewClick(index) {
        var oldChoices = this.props.value
        oldChoices.splice(index + 1, 0, this.getEmptyChoice())
        this.props.onChange(oldChoices)
    }

    handleOnDeleteClick(index) {
        var oldChoices = this.props.value
        if (oldChoices.length > 2) {
            oldChoices.splice(index, 1)
            this.props.onChange(oldChoices)
        }
    }

    handleOnValueChange(index, newValue) {
        var oldChoices = this.props.value
        oldChoices[index] = { ...oldChoices[index], ...newValue }
        this.props.onChange(oldChoices)
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                render: (text, record, index) => {
                    return (
                        <Input
                            value={record.name}
                            onChange={(e) => this.handleOnValueChange(index, { name: e.target.value })}
                            placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                        />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                width: 70,
                render: (text, record, index) => {
                    return (
                        <span>
                            <Icon type="plus" onClick={() => this.handleOnAddNewClick(index)} style={{ marginRight: 8, cursor: 'pointer' }} />
                            <Icon type="delete" onClick={() => this.handleOnDeleteClick(index)} style={{ cursor: 'pointer' }} />
                        </span>
                    )
                }
            },
        ]
    }

    render() {
        const { value } = this.props
        return (
            <div>
                <Table
                    columns={this.renderTableColumn()}
                    dataSource={value}
                    rowKey={record => record._id}
                    pagination={false} />
            </div>
        )
    }
}

export default injectIntl(ChoiceTable)