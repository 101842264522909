import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { DATE_FORMAT } from '~/src/constants/format'
import styled from 'styled-components'
import messages from '~/src/messages'
import moment from 'moment'
import { LIMIT } from '~/src/constants/common'
import { Table, Button, Tag, Modal, Input, Icon, Checkbox, Row, Descriptions } from 'antd'
import { statusOptions, pageSizeOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import { toJS } from 'mobx'
import MemberOrderBarChart from '~/src/components/charts/memberOrderBarChart'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

@inject('commonStore', 'userStore', 'analysisStore') @observer
class MemberDetailModal extends Component {

    render() {
        const { analysisStore, intl, visible, member } = this.props
        if (!member) {
            return null
        }
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.memberDetail })}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                onCancel={() => this.props.onClose()}>
                <Descriptions>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.username})}>{member.username}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.email})}>{member.email}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.credit})}>{member.credit}</Descriptions.Item>
                </Descriptions>
                <MemberOrderBarChart
                    data={analysisStore.memberSummaryByPeriod.map((s, i) => {
                        return { name: `${s._id}`, count: s.totalCount, amount: s.totalAmount }
                    })} />
            </Modal>
        )
    }
}

export default injectIntl(MemberDetailModal, { withRef: true })
