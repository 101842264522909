import axios from 'axios'
import pdfMake from "pdfmake/build/pdfmake"

const MSYH_FONT_URL = process.env.REACT_APP_MSYH_FONT_URL

function fetchFont(fontURL) {
    const options = {
        method: 'GET',
        url: fontURL
    }
    return axios(options)
}

const load = async () => { 
    try {
        // const response = await fetchFont(MSYH_FONT_URL)
        // const pdfFonts = response.data
        const pdfFonts = require('~/src/static/web_vfs_fonts_msyh.json')
        pdfMake.vfs = pdfFonts
        pdfMake.fonts = {
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-MediumItalic.ttf'
            },
            msyh: {
                normal: 'msyh.ttf',
                bold: 'msyh.ttf',
                italics: 'msyh.ttf',
                bolditalics: 'msyh.ttf'
            }
        }
        return Promise.resolve()
    } catch(e) {
        console.log(e)
        return Promise.reject()
    }
}

export default {
    load
}