import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import LoginForm from '~/src/components/forms/loginForm'
import { injectIntl } from 'react-intl'
import { Carousel } from 'antd'
import banner from '~/src/static/shopper_banner.png'
import logo from '~/src/static/shopper_logo.png'
import Helmet from 'react-helmet'
import messages from '~/src/messages'
import PageLayout from '~/src/components/layouts/public'

const LoginWrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
`

const BackgroundWrapper = styled.div`
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
`

const BackgroundImage = styled.div`
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`

const BG1 = styled(BackgroundImage)`
    background-image: url("https://cdn.shopper.com.hk/web/background/background1.jpg");
`
const BG2 = styled(BackgroundImage)`
    background-image: url("https://cdn.shopper.com.hk/web/background/background2.jpg");
`
const BG3 = styled(BackgroundImage)`
    background-image: url("https://cdn.shopper.com.hk/web/background/background3.jpg");
`
const BG4 = styled(BackgroundImage)`
    background-image: url("https://cdn.shopper.com.hk/web/background/background4.jpg");
`
const BG5 = styled(BackgroundImage)`
    background-image: url("https://cdn.shopper.com.hk/web/background/background5.jpg");
`
const BG6 = styled(BackgroundImage)`
    background-image: url("https://cdn.shopper.com.hk/web/background/background6.jpg");
`
const BG7 = styled(BackgroundImage)`
    background-image: url("https://cdn.shopper.com.hk/web/background/background7.jpg");
`
const BG8 = styled(BackgroundImage)`
    background-image: url("https://cdn.shopper.com.hk/web/background/background8.jpg");
`

const UpperLayer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 1000;
`

const LeftSide = styled.div`
    flex-grow: 1;
    z-index: 1000;
`

const RightSide = styled.div`
    background-color: #000000AA;
    flex-basis: 500px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1000;
`

const Banner = styled.div`
    width: 500px;
    height: 137px;
    margin: 32px;
    z-index: 1000;
    background-image: url(${banner});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`

@inject('authStore', 'shopStore') @observer
class ShopLoginPage extends React.Component {


    async componentDidMount() {
        const { shopStore, match } = this.props
        const { shopInitial } = match.params
        const hostname = window.location.hostname
        if (hostname !== 'portal.shopper.com.hk' && hostname !== 'localhost') {
            await shopStore.getShopByDomainName(window.location.hostname)
        }
    }


    handleOnLogin(fields) {
        const { email, password } = fields
        this.props.authStore.login(email, password)
            .then(() => {
                this.props.history.push('/')
            })
            .catch(() => { })
    }

    render() {
        const { authStore, shopStore, intl, title } = this.props
        const { isSubmitting, error } = authStore
        if (!shopStore.selfShop) {
            return null
        }
        const Logo = styled.div`
            width: 300px;
            height: 382px;
            z-index: 1000;
            background-image: url(${shopStore.selfShop.loginLogo});
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        `
        return (
            <LoginWrapper>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.login })}</title>
                    <link rel="icon" type="image/*" href={shopStore.selfShop.loginLogo} sizes="16x16" />
                </Helmet>
                <UpperLayer>
                    <LeftSide>
                    </LeftSide>
                    <RightSide>
                        <Logo />
                        <LoginForm
                            isSubmitting={isSubmitting}
                            error={error}
                            onLogin={(fields) => this.handleOnLogin(fields)} />
                    </RightSide>
                </UpperLayer>
                <BackgroundWrapper>
                    <BackgroundImage style={{
                        backgroundPosition: 'left',
                        backgroundImage: `url(${shopStore.selfShop.loginBackground})`
                    }} />
                </BackgroundWrapper>
            </LoginWrapper>
        )
    }
}

export default withRouter(injectIntl(ShopLoginPage))