import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { Table, Tag } from 'antd'
import { toJS } from 'mobx'
import { OPTION_LIMIT } from '~/src/constants/common'

@inject('commonStore', 'optionStore') @observer
class FoodSubchoiceSelectTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            choiceId: undefined,
            selectedRowKeys: []
        }
    }

    static async getDerivedStateFromProps(props, state) {
        const { value } = props
        if (props.choiceId && props.choiceId !== state.choiceId) {
            return {
                currentPage: 1,
                choiceId: props.choiceId,
                selectedRowKeys: value.map(c => c._id)
            }
        }

        if (!props.choiceId) {
            return {
                currentPage: 1,
                choiceId: undefined,
                selectedRowKeys: []
            }
        }
        return state
    }

    handleOnChange(selectedRowKeys, selectedRows) {
        const { value } = this.props
        const result = _.unionBy(selectedRows, value, '_id').filter(o => {
            return selectedRowKeys.includes(o._id)
        })
        this.props.onChange(result.map(s => { return { _id: s._id, fieldName: s.fieldName, choices: s.choices } }))
    }

    handleOnTableChange(pagination, filters, sorter) {
        const page = pagination.current
        const { optionStore, commonStore } = this.props
        const { token, shop } = commonStore
        optionStore.listFoodSubchoice(token, shop, OPTION_LIMIT, OPTION_LIMIT * (page - 1))
        this.setState({
            currentPage: page
        })
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'fieldName',
                key: 'fieldName',
                width: 200,
            },
            {
                title: intl.formatMessage({ ...messages.subchoiceContent }),
                key: 'choices',
                render: (text, record) => {
                    return (
                        <div>
                            {
                                record.choices.map((_c) =>
                                    <Tag>
                                        {`${_c}`}
                                    </Tag>
                                )
                            }
                        </div>
                    )
                }
            },
            {
                title: intl.formatMessage({ ...messages.remark }),
                dataIndex: 'remark',
                key: 'remark',
                width: 200,
            }
        ]
    }

    render() {
        const { intl, value, optionStore } = this.props
        const { currentPage } = this.state
        return (
            <Table
                columns={this.renderTableColumn()}
                dataSource={toJS(optionStore.foodSubchoices)}
                rowKey={record => record._id}
                rowSelection={{
                    selectedRowKeys: value.map(s => s._id),
                    onChange: (selectedRowKeys, selectedRows) => this.handleOnChange(selectedRowKeys, selectedRows),
                }}
                onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                pagination={
                    {
                        defaultPageSize: OPTION_LIMIT,
                        showQuickJumper: true,
                        current: +currentPage,
                        total: optionStore.foodSubchoiceCount,
                        showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                    }
                } />
        )
    }
}

export default injectIntl(FoodSubchoiceSelectTable)