function downscaleImage(originalFile, dataUrl, newWidth, imageType, fileName, imageArguments) {
    return new Promise((resolve, reject) => {
        var image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl

        // Provide default values
        imageType = imageType || "image/jpeg"
        imageArguments = imageArguments || 0.7

        // Create a temporary image so that we can compute the height of the downscaled image.
        image = new Image()

        image.onload = () => {
            oldWidth = image.width
            if (newWidth > oldWidth) {
                resolve(originalFile)
            }
            oldHeight = image.height
            newHeight = Math.floor(oldHeight / oldWidth * newWidth)

            // Create a temporary canvas to draw the downscaled image on.
            canvas = document.createElement("canvas")
            canvas.width = newWidth
            canvas.height = newHeight

            // Draw the downscaled image on the canvas and return the new data URL.
            ctx = canvas.getContext("2d")
            ctx.drawImage(image, 0, 0, newWidth, newHeight)
            canvas.toBlob(blob => {
                const file = new File([blob], fileName, {
                    type: imageType,
                    lastModified: Date.now()
                });
                resolve(file)
            }, imageType, imageArguments)
        }
        image.src = dataUrl
    })
}

function makeThumbnail(file) {
    var reader = new FileReader()
    return new Promise((resolve, reject) => {
        reader.onload = async function () {
            const newUri = await downscaleImage(file, reader.result, 300, file.type, file.name)
            resolve(newUri)
        }
        reader.readAsDataURL(file)
    })
}

function makeOriginal(file) {
    var reader = new FileReader()
    return new Promise((resolve, reject) => {
        reader.onload = async function () {
            const newUri = await downscaleImage(file, reader.result, 1080, file.type, file.name)
            resolve(newUri)
        }
        reader.readAsDataURL(file)
    })
}

export {
    makeThumbnail,
    makeOriginal
}