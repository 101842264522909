import XLSX from 'xlsx'

function importFile(file, header) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = function (e) {
            const data = new Uint8Array(e.target.result)
            const workbook = XLSX.read(data, { type: 'array' })
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const result = XLSX.utils.sheet_to_json(worksheet, { header: header })
            resolve(result)
        }
        reader.onerror = reject
        reader.readAsArrayBuffer(file)
    })
}

function exportData(sheetHeader, data, fileName) {
    const workbook = XLSX.utils.book_new()
    workbook.Props = {
        Title: "SheetJS Tutorial",
        Subject: "Test",
        Author: "Red Stapler",
        CreatedDate: new Date()
    }
    workbook.SheetNames.push("Test Sheet")
    var result = [sheetHeader]
    result = result.concat(data)
    let objectMaxLength = sheetHeader.map((s, index) => {
        return 10
    })
    for (let i = 0; i < data.length; i++) {
        const values = data[i]
        for (let j = 0; j < values.length; j++) {
            if (typeof values[j] === "number") {
                objectMaxLength[j] = 10;
            } else {
                objectMaxLength[j] = values[j] && objectMaxLength[j] < values[j].length
                    ? values[j].length * 2
                    : objectMaxLength[j]
            }
        }
    }
    var wscols = sheetHeader.map((s, index) => {
        return { width: objectMaxLength[index] }
    })
    const workSheet = XLSX.utils.aoa_to_sheet(result)
    workSheet["!cols"] = wscols

    workbook.Sheets["Test Sheet"] = workSheet
    XLSX.writeFile(workbook, fileName)
}

export {
    importFile,
    exportData
}