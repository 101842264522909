import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Row, Col, Statistic, Table, message } from 'antd'
import messages from '~/src/messages'
import PospalFilterForm from '~/src/components/forms/pospalFilterForm'
import { DATE_FORMAT } from '~/src/constants/format'
import { LIMIT } from '~/src/constants/common'
import { pageSizeOptions } from '~/src/constants/options'
import moment from 'moment'
import PospalTicketPrintModal from '../components/pospalTicketPrintModal'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const TableWrapper = styled.div`
    margin-top: 16px;
    background-color: white;
`

@inject('pospalStore', 'shopStore', 'commonStore') @observer
class PospalTicketPrintPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            pageSize: LIMIT,
            branch: undefined,
            date: undefined,
            sortedInfo: undefined,
            selectedTicketId: undefined,
            pospalTicketItemsVisible: false
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { pospalStore, commonStore } = this.props
        const { token, shop } = commonStore
        await pospalStore.listPospalBranch(token, shop)
    }

    async handleOnSubmit(values, reset) {
        const { branch, date } = values
        this.setState({ branch, date }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    async handleOnTableChange(pagination, filters, sorter) {
        const { pospalStore, commonStore } = this.props
        const { token } = commonStore
        const page = pagination.current
        const { pageSize } = pagination
        const { date, branch } = this.state
        const startTime = date.clone().hours(0).minutes(0).second(0).format()
        const endTime = date.clone().hours(0).minutes(0).second(0).add(1, 'days').format()
        await pospalStore.listPospalTicketInRealTime(
            token,
            branch,
            startTime,
            endTime
        )
        const href = `/pospalTicketPrint?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    async handleOnEmailTicket(values, reset) {
        const { pospalStore, commonStore, intl } = this.props
        const { token, shop } = commonStore
        const { selectedTicketId, branch } = this.state
        if (selectedTicketId) {
            const index = pospalStore.tickets.findIndex(o => o.uid === selectedTicketId)
            if (index > -1) {
                const ticket = toJS(pospalStore.tickets[index])
                pospalStore.emailPospalTicket(token, shop, branch, ticket.sn, values.email)
                    .then(() => {
                        reset()
                        message.success(intl.formatMessage({...messages.emailSentSuccess}))
                    })
                    .catch(e => {
                        message.error(intl.formatMessage({...messages.emailSentFailure}))
                    })
            }
        }
    }

    handleOnItemListClick(record) {
        console.log(record)
        this.setState({ pospalTicketItemsVisible: true, selectedTicketId: record.uid })
    }

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.cashierId }),
                dataIndex: 'cashier.jobNumber',
                key: 'cashierId',
                width: 200,
            },
            {
                title: intl.formatMessage({ ...messages.cashierName }),
                dataIndex: 'cashier.name',
                key: 'cashier.name',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'cashier.name' && sortedInfo.order,
            },
            {
                title: intl.formatMessage({ ...messages.totalAmount }),
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'totalAmount' && sortedInfo.order,
                width: 100,
                render: (totalAmount) => (
                    <span>
                        {`$ ${totalAmount}`}
                    </span>
                )
            },
            {
                title: intl.formatMessage({ ...messages.checkedOutAt }),
                dataIndex: 'datetime',
                key: 'datetime',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'datetime' && sortedInfo.order,
                render: (checkedOutAt) => (
                    <span>
                        {moment(checkedOutAt).format(DATE_FORMAT)}
                    </span>
                )
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                width: 100,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnItemListClick(record)}>
                                {intl.formatMessage({ ...messages.invoicePreview })}
                            </a>
                        </span>
                    )
                }
            }
        ]
    }

    render() {
        const { pospalStore, shopStore, intl, title } = this.props
        const { currentPage, pageSize, selectedTicketId, branch } = this.state
        var selectedTicketIndex
        if (selectedTicketId) {
            const index = pospalStore.tickets.findIndex(o => o.uid === selectedTicketId)
            selectedTicketIndex = index
        }
        console.log(this.state.pospalTicketItemsVisible)
        console.log(selectedTicketIndex)
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.pospalTicketPrint })}</title>
                </Helmet>
                <PospalFilterForm
                    branches={toJS(pospalStore.branches)}
                    onSubmit={(values, reset) => this.handleOnSubmit(values, reset)} />
                {
                    branch
                        ? (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table
                                        columns={this.renderTableColumn()}
                                        dataSource={toJS(pospalStore.tickets)}
                                        pagination={
                                            {
                                                showSizeChanger: true,
                                                defaultPageSize: LIMIT,
                                                pageSizeOptions: pageSizeOptions,
                                                showQuickJumper: true,
                                                current: +currentPage,
                                                pageSize: pageSize,
                                                total: toJS(pospalStore.tickets).length,
                                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                                            }
                                        }
                                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                                        loading={pospalStore.isSubmitting}
                                        rowKey={record => record.uid}
                                    />
                                </TableWrapper>
                            </React.Fragment>
                        )
                        : null
                }
                <PospalTicketPrintModal
                    ticket={selectedTicketIndex > -1 ? toJS(pospalStore.tickets[selectedTicketIndex]) : undefined}
                    shop={toJS(shopStore.selfShop)}
                    visible={this.state.pospalTicketItemsVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    onEmail={(values, reset) => this.handleOnEmailTicket(values, reset) }
                    onClose={() => this.setState({ pospalTicketItemsVisible: false, selectedTicketId: undefined })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(PospalTicketPrintPage))