import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, Input, Icon, Row, message, Tag } from 'antd'
import { LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import messages from '~/src/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { pageSizeOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import PospalSelectForm from '~/src/components/forms/pospalSelectForm'
import CreatePospalMemberModal from '~/src/components/createPospalMemberModal'
import EditPospalMemberModal from '~/src/components/editPospalMemberModal'
import MemberImportModal from '~/src/components/memberImportModal'
import _ from 'lodash'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

@inject('commonStore', 'pospalStore', 'shopStore', 'userStore') @observer
class PospalMemberListPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            createMemberVisible: false,
            editMemberVisible: false,
            pageSize: LIMIT,
            filterValues: {
                name: undefined,
                phone: undefined,
                email: undefined
            },
            sortedInfo: undefined,
            selectedMemberId: undefined,
            memberImportVisible: false,
            selectedRowKeys: [],
            selectedMembers: []
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { pospalStore, commonStore } = this.props
        const { token, shop } = commonStore
        await pospalStore.listPospalBranch(token, shop)
    }

    async handleOnSubmit(values, reset) {
        const { branch } = values
        this.setState({
            branch: branch,
            currentPage: 1
        }, async () => {
            const { pospalStore, commonStore } = this.props
            const { token, shop } = commonStore
            const { branch, pageSize, sortedInfo, filterValues } = this.state
            const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
            await Promise.all([
                pospalStore.listPospalMember(token, shop, branch, pageSize, 0,
                    sortField,
                    filterValues.name,
                    filterValues.phone,
                    filterValues.email
                ),
                pospalStore.getPospalUserCategory(token, shop, branch)
            ])
        })
    }

    handleOnPospalMemberCreate(values, reset) {
        const { pospalStore, commonStore, intl, shopStore } = this.props
        const { token, shop } = commonStore
        const { branch, pageSize, currentPage, sortedInfo, filterValues } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        const { categoryName, number, name, point, discount, balance, phone, email } = values
        const { selfShop } = shopStore
        pospalStore.createPospalMember(token, shop, branch, categoryName, selfShop.initial + number, name, point, discount, balance, phone, email)
            .then(() => {
                reset()
                pospalStore.listPospalMember(token, shop, branch, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.name,
                    filterValues.phone,
                    filterValues.email
                )
                message.success(intl.formatMessage({ ...messages.createPospalMemberSuccess }))
                this.setState({ createMemberVisible: false })
            })
            .catch(e => {
                message.error(intl.formatMessage({ ...messages.createPospalMemberFailure }))
            })
    }

    handleOnPospalMemberEdit(values, reset) {
        const { pospalStore, commonStore, intl } = this.props
        const { token, shop } = commonStore
        const { branch, pageSize, currentPage, selectedMemberId, sortedInfo, filterValues } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        const { categoryName, name, discount, phone, email, enable, shopperUserId } = values
        const index = pospalStore.users.findIndex(u => u._id === selectedMemberId)
        const customerUid = index > -1 ? pospalStore.users[index].customerUid : undefined
        pospalStore.updatePospalMember(token, shop, branch, selectedMemberId, customerUid, categoryName, name, discount, phone, email, enable, shopperUserId)
            .then(() => {
                reset()
                pospalStore.listPospalMember(token, shop, branch, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.name,
                    filterValues.phone,
                    filterValues.email
                )
                message.success(intl.formatMessage({ ...messages.updatePospalMemberSuccess }))
                this.setState({ editMemberVisible: false, selectedMemberId: undefined })
            })
            .catch(e => {
                message.error(intl.formatMessage({ ...messages.updatePospalMemberFailure }))
            })
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { pospalStore, commonStore } = this.props
        const { token, shop } = commonStore
        const { pageSize } = pagination
        const { branch } = this.state
        const {
            name,
            phone,
            email
        } = this.state.filterValues
        pospalStore.listPospalMember(token, shop, branch, pageSize, pageSize * (page - 1),
            sortField,
            name,
            phone,
            email
        )
        const href = `/pospalMember?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    async handleOnEditClick(record) {
        this.setState({ editMemberVisible: true, selectedMemberId: record._id })
    }

    validateProductData(members) {
        const { intl } = this.props
        const errors = []
        members.forEach((p, index) => {
            const { number, name, phone, categoryName, point, discount, balance, enable } = p
            if (!number) {
                errors.push({ row: index + 2, field: 'number', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!name) {
                errors.push({ row: index + 2, field: 'name', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!phone) {
                errors.push({ row: index + 2, field: 'phone', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!categoryName) {
                errors.push({ row: index + 2, field: 'categoryName', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!point) {
                errors.push({ row: index + 2, field: 'point', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!discount) {
                errors.push({ row: index + 2, field: 'discount', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!balance) {
                errors.push({ row: index + 2, field: 'balance', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!enable) {
                errors.push({ row: index + 2, field: 'enable', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
        })
        return errors
    }

    async handleOnImport(values, reset, errorCallback) {
        const { pospalStore, commonStore, intl, shopStore } = this.props
        const { selfShop } = shopStore
        const { token, shop } = commonStore
        const { currentPage, pageSize, branch, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        const { file } = values
        const header = ['number', 'name', 'phone', 'email', 'categoryName', 'point', 'discount', 'balance', 'enable']

        import('~/src/lib/xlsxHelper').then(async xlsxHelper => {
            const data = await xlsxHelper.importFile(file[0].originFileObj, header)
            data.shift()
            const errors = this.validateProductData(data)
            if (errors.length === 0) {
                const newData = data.map(({ number, ...fields }) => {
                    return {
                        number: selfShop.initial + number,
                        ...fields
                    }
                })
                pospalStore.importPospalMember(token, shop, branch, newData)
                    .then(() => {
                        reset()
                        this.setState({ memberImportVisible: false })
                        message.success(intl.formatMessage({ ...messages.createPospalMemberSuccess }))
                        pospalStore.listPospalMember(token, shop, branch, pageSize, pageSize * (currentPage - 1),
                            sortField,
                            filterValues.name,
                            filterValues.phone,
                            filterValues.email
                        )
                    })
                    .catch(e => {
                        message.error(intl.formatMessage({ ...messages.createPospalMemberFailure }))
                    })
            } else {
                errorCallback(errors)
            }
        })
    }

    handleOnImportClick() {
        this.setState({ memberImportVisible: true })
    }

    handleOnExportClick() {
        const { intl } = this.props
        const { selectedMembers } = this.state
        if (selectedMembers.length > 0) {
            const sheetHeader = [
                `${intl.formatMessage({ ...messages.id })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.name })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.phoneNumber })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.email })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.memberTier })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.credit })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.discount })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.balance })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.status })} (${intl.formatMessage({ ...messages.mustInput })})`
            ]
            const data = []
            toJS(selectedMembers).forEach(p => {
                return data.push([
                    p.number,
                    p.name,
                    p.phone,
                    p.email,
                    p.categoryName,
                    p.point,
                    p.discount,
                    p.balance,
                    p.enable,
                ])
            })
            import('~/src/lib/xlsxHelper').then(xlsxHelper => {
                xlsxHelper.exportData(sheetHeader, data, 'shopper_member.xlsx')
            })
        } else {
            message.error(intl.formatMessage({ ...messages.exportMemberReminder }))
        }
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'name':
            case 'phone':
            case 'email':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            // case 'status':
            //     this.setState({
            //         filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
            //     }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
            //     break
            // case 'createdAt':
            //     this.setState({
            //         filterValues: update(this.state.filterValues, { 'createdAtMin': { $set: undefined }, 'createdAtMax': { $set: undefined } })
            //     }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
            //     break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                name: undefined,
                phone: undefined,
                email: undefined,
                // status: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'name':
            case 'phone':
            case 'email':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            // case 'status':
            //     return (
            //         <Checkbox.Group
            //             value={this.state.filterValues[dataIndex]}
            //             onChange={value => {
            //                 const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
            //                 this.setState({ filterValues })
            //             }}
            //             style={{ marginBottom: 8, display: 'block' }}>
            //             {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
            //         </Checkbox.Group>
            //     )
            // case 'createdAt':
            //     return (
            //         <div>
            //             <Row>
            //                 <DatePicker
            //                     style={{ marginBottom: 9 }}
            //                     showTime
            //                     format={DATE_FORMAT}
            //                     value={this.state.filterValues.createdAteMin}
            //                     placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
            //                     onChange={value => {
            //                         const filterValues = update(this.state.filterValues, { 'createdAteMin': { $set: value } })
            //                         this.setState({ filterValues })
            //                     }}
            //                 />
            //             </Row>
            //             <Row>
            //                 <DatePicker
            //                     style={{ marginBottom: 9 }}
            //                     showTime
            //                     format={DATE_FORMAT}
            //                     value={this.state.filterValues.createdAtMax}
            //                     placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
            //                     onChange={value => {
            //                         const filterValues = update(this.state.filterValues, { 'createdAtMax': { $set: value } })
            //                         this.setState({ filterValues })
            //                     }}
            //                 />
            //             </Row>
            //         </div>
            //     )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'name' || dataIndex === 'email' || dataIndex === 'phone')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    handleOnRowSelect(selectedRowKeys, selectedRows) {
        const { pospalStore } = this.props
        const users = toJS(pospalStore.users)
        this.setState({ selectedRowKeys: selectedRowKeys })
        const filteredMembers = _.unionBy(this.state.selectedMembers, users, '_id').filter(p => {
            return selectedRowKeys.includes(p._id)
        })
        this.setState({ selectedMembers: filteredMembers })
    }

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.id }),
                dataIndex: 'number',
                key: 'number',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'number' && sortedInfo.order,
            },
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'name' && sortedInfo.order,
                ...this.getColumnSearchProps('name')
            },
            {
                title: intl.formatMessage({ ...messages.phoneNumber }),
                dataIndex: 'phone',
                key: 'phone',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'phone' && sortedInfo.order,
                ...this.getColumnSearchProps('phone')
            },
            {
                title: intl.formatMessage({ ...messages.email }),
                dataIndex: 'email',
                key: 'email',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'email' && sortedInfo.order,
                ...this.getColumnSearchProps('email')
            },
            {
                title: intl.formatMessage({ ...messages.memberTier }),
                dataIndex: 'categoryName',
                key: 'categoryName',
                width: 120,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'categoryName' && sortedInfo.order,
                // ...this.getColumnSearchProps('email')
            },
            // {
            //     title: intl.formatMessage({ ...messages.credit }),
            //     dataIndex: 'point',
            //     key: 'point',
            //     width: 100,
            //     sorter: true,
            //     sortOrder: sortedInfo && sortedInfo.columnKey === 'point' && sortedInfo.order,
            // },
            {
                title: intl.formatMessage({ ...messages.memberDiscount }),
                dataIndex: 'discount',
                key: 'discount',
                width: 100,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'discount' && sortedInfo.order,
            },
            {
                title: intl.formatMessage({ ...messages.balance }),
                dataIndex: 'balance',
                key: 'balance',
                width: 100,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'balance' && sortedInfo.order,
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'enable',
                key: 'enable',
                width: 100,
                render: (text, record) => {
                    switch (record.enable) {
                        case 0:
                            return intl.formatMessage({ ...messages.inactive })
                        case 1:
                            return intl.formatMessage({ ...messages.active })
                        default:
                            return intl.formatMessage({ ...messages.inactive })
                    }
                },
                // ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.joinedAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                ),
                // ...this.getColumnSearchProps('createdAt')
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 60,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                        </span>
                    )
                }
            }
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'name':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.name })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'phone':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.phone })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'email':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.email })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { pospalStore, intl, shopStore, userStore } = this.props
        const { currentPage, pageSize, branch, selectedMemberId } = this.state
        var selectedMemberIndex
        if (selectedMemberId) {
            const index = pospalStore.users.findIndex(o => o._id === selectedMemberId)
            selectedMemberIndex = index
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.memberList })}</title>
                </Helmet>
                <PospalSelectForm
                    branches={toJS(pospalStore.branches)}
                    onSubmit={(values, reset) => this.handleOnSubmit(values, reset)} />
                {
                    branch
                        ?
                        <React.Fragment>
                            <ActionWrapper>
                                <ActionLeftWrapper>
                                    <Button type='primary' onClick={() => this.setState({ createMemberVisible: true })}>
                                        {intl.formatMessage({ ...messages.createPospalMember })}
                                    </Button>
                                    <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                                        {intl.formatMessage({ ...messages.resetAllSearch })}
                                    </Button>
                                    <Button type="primary" onClick={() => this.handleOnImportClick()}>
                                        {intl.formatMessage({ ...messages.import })}
                                    </Button>
                                    <Button type="primary" onClick={() => this.handleOnExportClick()} disabled={this.state.selectedRowKeys.length < 1}>
                                        {intl.formatMessage({ ...messages.export })}
                                        {
                                            this.state.selectedRowKeys.length > 0
                                                ? intl.formatMessage({ ...messages.selectedMemberCount }, { count: this.state.selectedRowKeys.length })
                                                : null
                                        }
                                    </Button>
                                </ActionLeftWrapper>
                            </ActionWrapper>
                            <ActionWrapper>
                                {this.renderFilterTags()}
                            </ActionWrapper>
                            <TableWrapper>
                                <Table
                                    columns={this.renderTableColumn()}
                                    dataSource={toJS(pospalStore.users)}
                                    pagination={
                                        {
                                            showSizeChanger: true,
                                            defaultPageSize: LIMIT,
                                            pageSizeOptions: pageSizeOptions,
                                            showQuickJumper: true,
                                            current: +currentPage,
                                            pageSize: pageSize,
                                            total: pospalStore.count,
                                            showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                                        }
                                    }
                                    scroll={{ x: 1700 }}
                                    onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                                    loading={pospalStore.isSubmitting}
                                    rowKey={record => record._id}
                                    rowSelection={{
                                        fixed: true,
                                        selectedRowKeys: this.state.selectedRowKeys,
                                        onChange: (selectedRowKeys, selectedRows) => this.handleOnRowSelect(selectedRowKeys, selectedRows)
                                    }}
                                />
                            </TableWrapper>
                        </React.Fragment>
                        : null
                }
                <CreatePospalMemberModal
                    visible={this.state.createMemberVisible}
                    shop={shopStore.selfShop}
                    error={pospalStore.error}
                    userCategories={toJS(pospalStore.userCategories)}
                    isSubmitting={pospalStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnPospalMemberCreate(values, reset)}
                    onClose={() => this.setState({ createMemberVisible: false })} />
                <EditPospalMemberModal
                    visible={this.state.editMemberVisible}
                    shop={shopStore.selfShop}
                    userCategories={toJS(pospalStore.userCategories)}
                    member={selectedMemberIndex > -1 ? toJS(pospalStore.users[selectedMemberIndex]) : undefined}
                    isSubmitting={pospalStore.isSubmitting || userStore.isSubmitting}
                    error={pospalStore.error}
                    onSubmit={(values, reset) => this.handleOnPospalMemberEdit(values, reset)}
                    onSearch={(value) => this.handleOnShopperMemberSearch(value)}
                    onClose={() => this.setState({ editMemberVisible: false, selectedMemberId: undefined })} />
                <MemberImportModal
                    visible={this.state.memberImportVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    onSubmit={(values, reset, error) => this.handleOnImport(values, reset, error)}
                    onClose={() => this.setState({ memberImportVisible: false })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(PospalMemberListPage))