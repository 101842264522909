import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { toJS } from 'mobx'
import { Table, Descriptions, Select, Spin, Divider, Card, Row, Col } from 'antd'
import messages from '~/src/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { DATE_FORMAT, DATE_ONLY_FORMAT } from '~/src/constants/format'
import _ from 'lodash'
import QRCode from 'qrcode.react'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 16px;
    background-color: white;
    
    .ant-card:not(:last-child) {
        margin-bottom: 16px;
    }
`

@inject('commonStore', 'productStore', 'shopStore') @observer
class MainPage extends React.Component {

    async componentDidMount() {
        const { productStore, commonStore, location, match } = this.props
        const { token, shop } = commonStore
        await productStore.listProductStockAlert(token, shop)
    }
    
    render() {
        const { intl, productStore, shopStore } = this.props
        console.log(shopStore.selfShop)
        if (shopStore.selfShop && shopStore.selfShop.type === 'RETAIL') {
            return (
                <Container>
                    {
                        shopStore.selfShop && (
                            shopStore.selfShop.initial === 'TKS'
                            || shopStore.selfShop.initial === 'HAC'
                            || shopStore.selfShop.initial === 'CHK'
                        )
                            ? (
                                <>
                                    <h1>{intl.formatMessage({ ...messages.shopQrCode })}</h1>
                                    <QRCode value={shopStore.selfShop._id} />
                                    <Divider />
                                </>
                            )
                            : null
                    }
                    <h1>{intl.formatMessage({ ...messages.stockAlert })}</h1>
                    <Row gutter={16}>
                        {
                            productStore.productStockAlert.map((p, i) => {
                                return (
                                    <Col span={8} key={i}>
                                        <Card >
                                            <Descriptions>
                                                <Descriptions.Item label={intl.formatMessage({ ...messages.name })} span={3}>{p.name}</Descriptions.Item>
                                                <Descriptions.Item label={intl.formatMessage({ ...messages.productId })} span={3}>{p.productId}</Descriptions.Item>
                                                <Descriptions.Item label={intl.formatMessage({ ...messages.price })}>{p.price}</Descriptions.Item>
                                                <Descriptions.Item label={intl.formatMessage({ ...messages.stock })}><div style={{ color: 'red' }}>{p.stock}</div></Descriptions.Item>
                                            </Descriptions>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            )
        } else {
            return <div></div>
        }
    }
}

export default withRouter(injectIntl(MainPage))