export const LIMIT = 10
export const BLOG_LIMIT = 5
export const UPLOAD_LIMIT = 10
export const LONGITUDE_MAX = 180
export const LONGITUDE_MIN = -180
export const LATITUDE_MAX = 90
export const LATITUDE_MIN = -90
export const COORDINATE_DEFAULT_VALUE = 0
export const COORDINATE_STEP = 0.000001
export const YEARBUILD_DEFAULT_VALUE = 2000
export const YEARBUILD_MAX = 2030
export const YEARBUILD_MIN = 1900
export const OPTION_LIMIT = 5