import React from 'react'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { Layout } from 'antd'
import styled from 'styled-components'
import AppBar from '~/src/components/appBar'
import Footer from '~/src/components/footer'
import Sider from '~/src/components/sider'
import AppIcon from '~/src/components/appIcon'
import { withRouter } from 'react-router-dom'
import messages from '~/src/messages'
import Helmet from 'react-helmet'

const TitleWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 64px;
`

const Title = styled.div`
    color: white;
`

@inject('authStore', 'commonStore', 'shopStore') @observer
class PageLayout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            width: 0,
            collapsed: false,
        }
    }

    componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', () => this.updateWindowDimensions())
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.updateWindowDimensions())
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth })
    }

    handleOnLogout() {
        const { authStore, commonStore } = this.props
        const { shop } = commonStore
        authStore.logout()
            .then(() => {
                // this.props.history.push(shop ? `/${shop}/login` : '/login')
                this.props.history.push('/login')
            })
    }

    render() {
        const { children, pathname, commonStore, shopStore, title, intl } = this.props
        const { username } = commonStore
        const isMobile = this.state.width <= 1224

        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, { isMobile: isMobile })
        )

        return (
            <Layout style={{ minHeight: '100vh', minWidth: '960px' }}>
                <Helmet>
                    {
                        shopStore.selfShop && shopStore.selfShop.loginLogo
                            ? <link rel="icon" type="image/*" href={shopStore.selfShop.loginLogo} sizes="16x16" />
                            : null
                    }
                    {
                        shopStore.selfShop
                            ? <title>{shopStore.selfShop.name}</title>
                            : null
                    }
                </Helmet>
                <Layout.Sider
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={(collapsed) => this.setState({ collapsed })}>
                    <TitleWrapper onClick={() => this.handleOnTitleClick()}>
                        <AppIcon />
                        {
                            !this.state.collapsed 
                                ? <Title>{shopStore.selfShop ? shopStore.selfShop.name : ''}</Title>
                                : null
                        }
                    </TitleWrapper>
                    <Sider isMobile={isMobile} pathname={pathname} shop={shopStore.selfShop} />
                </Layout.Sider>
                <Layout>
                    <Layout.Header style={{ padding: 0, height: 64 }}>
                        <AppBar
                            isSiderCollapsed={this.state.collapsed}
                            pathname={pathname}
                            username={username}
                            onMenuFoldClick={() => this.setState({ collapsed: !this.state.collapsed })}
                            onLogout={() => this.handleOnLogout()} />
                    </Layout.Header>
                    <Layout.Content style={{ padding: 16 }}>
                        {childrenWithProps}
                    </Layout.Content>
                    <Layout.Footer style={{ padding: 0 }}>
                        <Footer />
                    </Layout.Footer>
                </Layout>
            </Layout>
        )
    }
}

export default withRouter(injectIntl(PageLayout))