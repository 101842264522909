import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import messages from '~/src/messages'
import { Table, Button, message, Modal, Input, Icon, Checkbox, Popconfirm, Divider } from 'antd'
import AddFoodOptionModal from '~/src/components/addFoodOptionModal'
import { OPTION_LIMIT } from '~/src/constants/common'
import { toJS } from 'mobx'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

const FieldName = styled.div`
    font-size: 20px;
`

const ChoiceCardWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
`

const ChoiceCard = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border: 1px solid #e8e8e8;
    flex-basis: 20%;
    padding: 8px;
    margin: 8px;
    border-radius: 2px;
`

@inject('commonStore', 'productStore', 'optionStore') @observer
class FoodOptionManagementModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            addOptionVisible: false,
        }
    }

    async updateProductFoodOption(values, reset) {
        const { productStore, commonStore, intl, product } = this.props
        const { token, shop } = commonStore
        try {
            await productStore.updateProductFoodOption(token, shop, product._id, values.options.map(o => o._id))
            await productStore.listProductFoodOption(token, shop, product._id)
            message.success(intl.formatMessage({ ...messages.createProductOptionSuccess }))
            this.setState({ addOptionVisible: false }, () => {
                reset()
            })
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.createProductOptionFailure }))
        }
    }

    async handleOnAddOptionClick() {
        const { optionStore, commonStore } = this.props
        const { token, shop } = commonStore
        await optionStore.listFoodOption(token, shop, OPTION_LIMIT, 0)
        this.setState({ addOptionVisible: true })
    }

    renderChoiceTable() {
        const { intl, productStore } = this.props
        const foodOptions = toJS(productStore.foodOptions)
        return foodOptions.map((o, index) => {
            return (
                <div key={index}>
                    <Divider />
                    <RowWrapper>
                        <FieldName>{o.fieldName}</FieldName>
                    </RowWrapper>
                    <div>{o.remark}</div>
                    <ChoiceCardWrapper>
                        {
                            o.choices.map((c, j) => {
                                return (
                                    <ChoiceCard key={j}>
                                        <div style={{ flexGrow: 1 }}>{`${c.name} ($${c.priceAdjustment})`}</div>
                                    </ChoiceCard>
                                )
                            })
                        }
                    </ChoiceCardWrapper>
                </div>
            )
        })
    }

    render() {
        const { intl, visible, isSubmitting, productStore, product } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                footer={null}
                title={intl.formatMessage({ ...messages.optionManagement })}
                onCancel={() => this.props.onClose()}>
                <ActionWrapper>
                    {
                        productStore.foodOptions.length === 0
                            ? (
                                <Button type='primary' onClick={() => this.handleOnAddOptionClick()}>
                                    {intl.formatMessage({ ...messages.addNewOption })}
                                </Button>
                            )
                            : (
                                <Button type='primary' onClick={() => this.handleOnAddOptionClick()}>
                                    {intl.formatMessage({ ...messages.updateOption })}
                                </Button>
                            )
                    }

                </ActionWrapper>
                <div>{intl.formatMessage({ ...messages.stocksWillBeResetAfterEditingPleaseUpdateStockAfterward })}</div>
                {this.renderChoiceTable()}
                <AddFoodOptionModal
                    visible={this.state.addOptionVisible}
                    foodOptions={productStore.foodOptions}
                    product={product}
                    isSubmitting={productStore.isSubmitting}
                    onSubmit={(values, reset) => this.updateProductFoodOption(values, reset)}
                    onClose={() => this.setState({ addOptionVisible: false })} />
            </Modal>
        )
    }
}

export default injectIntl(FoodOptionManagementModal)