import messages from '~/src/messages'

export const statusOptions = [
    { value: 'ACTIVE', text: messages.active },
    { value: 'INACTIVE', text: messages.inactive },
]

export const pageSizeOptions = [
    '10',
    '20',
    '50',
    '100'
]

export const productTypeOptions = [
    { value: 'ORDER', text: messages.orderedProduct },
    { value: 'RESERVATION', text: messages.reservedProduct },
]

export const groupOptions = [
    { value: 'PETS', text: messages.petsProduct },
    { value: 'BEAUTY_HEALTH', text: messages.beautyAndHealth },
    { value: 'SPORT_TRAVEL', text: messages.sportAndTravel },
    { value: 'FASHION', text: messages.fashion },
    { value: 'ENTERTAINMENT', text: messages.entertainment },
    { value: 'SUPERMARKET', text: messages.supermarket },
]

export const orderStatusOptions = [
    { value: 'PENDING', text: messages.pending },
    { value: 'CONFIRMED', text: messages.confirmed },
    { value: 'COMPLETE', text: messages.complete },
    { value: 'CANCELLED', text: messages.cancelled },
]

export const roleOptions = [
    { value: 'ADMIN', text: messages.admin },
    { value: 'SHOPOWNER', text: messages.shopOwner },
    { value: 'STAFF', text: messages.staff },
    { value: 'USER', text: messages.user },
]

export const weekOptions = [
    { value: '0', text: messages.sunday },
    { value: '1', text: messages.monday },
    { value: '2', text: messages.tuesday },
    { value: '3', text: messages.wednesday },
    { value: '4', text: messages.thursday },
    { value: '5', text: messages.friday },
    { value: '6', text: messages.saturday },
]

export const deliveryMethodOptions = [
    { value: 'SFEXPRESS', text: messages.localExpress },
    { value: 'CONTACT_SELLER', text: messages.contactSeller },
    { value: 'SELF_PICK_UP', text: messages.selfPickUp },
]

export const shopTypeOptions = [
    { value: 'RESTAURANT', text: messages.restaurant },
    { value: 'RETAIL', text: messages.retail },
    { value: 'RESTAURANT_SHOPPER', text: messages.shopperDelivery },
]

export const baofoodCouponTypeOptions = [
    { value: 'BAOFOOD_FREE', text: messages.free, formatter: (value) => '-' },
    { value: 'BAOFOOD_DISCOUNT', text: messages.discount, formatter: (value) => `${value} %` },
    { value: 'BAOFOOD_CASH_VOUCHER', text: messages.cashVoucher, formatter: (value) => `$${value}` },
    { value: 'BAOFOOD_ITEM_DISCOUNT', text: messages.itemDiscount, formatter: (value) => `${value} %` },
]

export const foodDeliveryMethodOptions = [
    { value: 'DELIVERY', text: messages.delivery },
    { value: 'COD', text: messages.cashOnDelivery },
    { value: 'SELF_PICK_UP', text: messages.selfPickUp },
]

export const foodOrderStatusOptions = [
    { value: 'PENDING', text: messages.pending },
    { value: 'CONFIRMED', text: messages.confirmed },
    { value: 'DELIVERING', text: messages.delivering },
    { value: 'COMPLETE', text: messages.complete },
    { value: 'CANCELLED', text: messages.cancelled },
]

export const rangeOptions = [
    { value: 'TODAY', text: messages.today },
    { value: 'YESTERDAY', text: messages.yesterday },
    { value: 'WEEK', text: messages.thisWeek },
    { value: 'MONTH', text: messages.thisMonth },
    { value: 'CUSTOMIZE', text: messages.customize },
]

export const analysisPeriodModeOptions = [
    { value: 'HOURLY', text: messages.byHour },
    { value: 'DAILY', text: messages.byDay },
    { value: 'MONTHLY', text: messages.byMonth },
]