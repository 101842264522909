import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Modal, Button, message, Tree, Dropdown, Menu, Icon } from 'antd'
import messages from '~/src/messages'
import PospalSelectForm from '~/src/components/forms/pospalSelectForm'
import CreatePospalCategoryForm from '~/src/components/createPospalCategoryModal'
import EditPospalCategoryForm from '~/src/components/editPospalCategoryModal'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    margin-top: 16px;
    background-color: white;
`

@inject('pospalStore', 'commonStore') @observer
class PospalCategoryListPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            branch: undefined,
            selectedCategory: undefined,
            createPospalCategoryVisible: false,
            editPospalCategoryVisible: false,
            expandedKeys: [],
            autoExpandParent: false,
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { pospalStore, commonStore } = this.props
        const { token, shop } = commonStore
        await pospalStore.listPospalBranch(token, shop)
    }

    async handleOnSubmit(values, reset) {
        const { branch } = values
        this.setState({ branch }, async () => {
            const { pospalStore, commonStore } = this.props
            const { token } = commonStore
            const { branch } = this.state
            await pospalStore.listPospalCategory(
                token,
                branch
            )
        })
    }

    async handleOnPospalCategoryCreate(values, reset) {
        const { intl, pospalStore, commonStore } = this.props
        const { token } = commonStore
        const { branch, selectedCategory } = this.state
        const { name } = values
        pospalStore.createPospalCategory(token, branch, selectedCategory ? selectedCategory.name : '', name)
            .then(() => {
                reset()
                this.setState({ createPospalCategoryVisible: false, selectedCategory: undefined })
                message.success(intl.formatMessage({ ...messages.createCategorySuccess }))
                pospalStore.listPospalCategory(token, branch)
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.createCategoryFailure }))
            })
    }

    async handleOnPospalCategoryEdit(values, reset) {
        const { intl, pospalStore, commonStore } = this.props
        const { token } = commonStore
        const { branch, selectedCategory } = this.state
        const { name } = values
        const index = pospalStore.rawCategories.findIndex(c => c.uid === selectedCategory.parentUid)
        pospalStore.updatePospalCategory(token, branch, selectedCategory.uid, index > -1 ? pospalStore.rawCategories[index].name : '', name)
            .then(() => {
                reset()
                this.setState({ editPospalCategoryVisible: false, selectedCategory: undefined })
                message.success(intl.formatMessage({ ...messages.updateCategorySuccess }))
                pospalStore.listPospalCategory(token, branch)
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateCategoryFailure }))
            })
    }

    async handleOnDeleteClick(record) {
        const { intl, pospalStore, commonStore } = this.props
        const { token } = commonStore
        const { branch } = this.state
        pospalStore.deletePospalCategory(token, branch, [record.uid])
            .then(() => {
                message.success(intl.formatMessage({ ...messages.deleteCategorySuccess }))
                pospalStore.listPospalCategory(token, branch)
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.deleteCategoryFailure }))
            })
    }

    handleOnCategoryCreateClick(record) {
        this.setState({ createPospalCategoryVisible: true, selectedCategory: record })
    }

    handleOnCategoryEditClick(record) {
        this.setState({ editPospalCategoryVisible: true, selectedCategory: record })
    }

    showDeleteConfirm(record) {
        const { intl } = this.props
        const self = this
        Modal.confirm({
            title: intl.formatMessage({ ...messages.notice }),
            content: intl.formatMessage({ ...messages.deleteReminder }),
            okText: intl.formatMessage({ ...messages.yes }),
            okType: 'danger',
            cancelText: intl.formatMessage({ ...messages.no }),
            onOk() {
                self.handleOnDeleteClick(record)
            },
        })
    }

    renderActionDropdown(item, isCreateSubcategoryDisable) {
        const { intl } = this.props
        if (isCreateSubcategoryDisable) {
            return (
                <Dropdown overlay={
                    <Menu>
                        <Menu.Item>
                            <a onClick={() => this.handleOnCategoryEditClick(item)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a onClick={() => this.showDeleteConfirm(item)}>
                                {intl.formatMessage({ ...messages.delete })}
                            </a>
                        </Menu.Item>
                    </Menu>
                }>
                    <a>
                        {intl.formatMessage({ ...messages.actions })} <Icon type="down" />
                    </a>
                </Dropdown>
            )
        }
        return (
            <Dropdown overlay={
                <Menu>
                    <Menu.Item>
                        <a onClick={() => this.handleOnCategoryEditClick(item)}>
                            {intl.formatMessage({ ...messages.edit })}
                        </a>
                    </Menu.Item>
                    <Menu.Item>
                        <a onClick={() => this.handleOnCategoryCreateClick(item)}>
                            {intl.formatMessage({ ...messages.createSubcategory })}
                        </a>
                    </Menu.Item>
                    <Menu.Item>
                        <a onClick={() => this.showDeleteConfirm(item)}>
                            {intl.formatMessage({ ...messages.delete })}
                        </a>
                    </Menu.Item>
                </Menu>
            }>
                <a>
                    {intl.formatMessage({ ...messages.actions })} <Icon type="down" />
                </a>
            </Dropdown>
        )
    }

    renderTreeNodes(data, level) {
        const newLevel = level + 1
        const { intl } = this.props
        return data.map(item => {
            if (item.subcategories) {
                return (
                    <Tree.TreeNode
                        title={<span>{`${item.name} (${intl.formatMessage({ ...messages.subcategoryCount })}: ${item.subcategories ? item.subcategories.length : 0})`}  {this.renderActionDropdown(item, newLevel === 3)}</span>}
                        key={item.uid}
                        dataRef={item}
                        selectable={false}>
                        {this.renderTreeNodes(item.subcategories, newLevel)}
                    </Tree.TreeNode>
                )
            }
            return (
                <Tree.TreeNode
                    title={<span>{item.name} {this.renderActionDropdown(item, newLevel === 3)}</span>}
                    key={item.uid}
                    dataRef={item}
                    selectable={false} />
            )
        })
    }

    render() {
        const { pospalStore, intl, title } = this.props
        const { selectedCategory, branch } = this.state
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.pospalCategory })}</title>
                </Helmet>
                <PospalSelectForm
                    branches={toJS(pospalStore.branches)}
                    onSubmit={(values, reset) => this.handleOnSubmit(values, reset)} />
                {
                    branch
                        ?
                        <React.Fragment>
                            <ActionWrapper>
                                <ActionLeftWrapper>
                                    <Button type='primary' onClick={() => this.setState({ createPospalCategoryVisible: true })}>
                                        {intl.formatMessage({ ...messages.createCategory })}
                                    </Button>
                                </ActionLeftWrapper>
                            </ActionWrapper>
                            <TableWrapper>
                                <Tree
                                    showLine
                                    onExpand={(expandedKeys) => this.setState({ expandedKeys, autoExpandParent: false })}
                                    expandedKeys={this.state.expandedKeys}
                                    autoExpandParent={this.state.autoExpandParent}>
                                    {this.renderTreeNodes(toJS(pospalStore.categories), 0)}
                                </Tree>
                            </TableWrapper>
                        </React.Fragment>
                        : null
                }
                <CreatePospalCategoryForm
                    visible={this.state.createPospalCategoryVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnPospalCategoryCreate(values, reset)}
                    onClose={() => this.setState({ createPospalCategoryVisible: false, selectedCategory: undefined })} />
                <EditPospalCategoryForm
                    category={selectedCategory ? selectedCategory : undefined}
                    visible={this.state.editPospalCategoryVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnPospalCategoryEdit(values, reset)}
                    onClose={() => this.setState({ editPospalCategoryVisible: false, selectedCategory: undefined })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(PospalCategoryListPage))