import React from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { Card, Radio, DatePicker } from 'antd'
import {
    G2,
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
    View,
    Guide,
    Shape,
    Facet,
    Util
} from "bizcharts";
import messages from '~/src/messages'
import moment from 'moment'
import { analysisPeriodModeOptions } from '~/src/constants/options'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
`

const Title = styled.h3`

`

class MemberCountBarChart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mode: 'HOURLY',
            selectedDate: moment()
        }
    }

    renderModeButton() {
        const { intl } = this.props
        const buttons = [
            <Radio.Group onChange={(e) => this.handleOnModeChange(e.target.value)} defaultValue="HOURLY" value={this.state.mode} key={0}>
                {analysisPeriodModeOptions.map((r, i) => <Radio.Button key={r.value} value={r.value}>{intl.formatMessage({ ...r.text })}</Radio.Button>)}
            </Radio.Group>
        ]
        return buttons
    }

    handleOnRangeChange(mode, date) {
        let startDate = undefined
        let endDate = undefined
        switch (mode) {
            case 'HOURLY':
                this.setState({ selectedDate: date })
                startDate = date.clone().startOf('day')
                endDate = date.clone().endOf('day')
                break
            case 'DAILY':
                this.setState({ selectedDate: date })
                startDate = date.clone().startOf('week')
                endDate = date.clone().endOf('week')
                break
            case 'MONTHLY':
                startDate = moment().subtract(11, 'month').startOf('month')
                endDate = moment().startOf('month')
                break
            default:
                break
        }
        this.props.onFetchData(mode, startDate, endDate)
    }

    renderRangePicker() {
        const { mode } = this.state
        switch (mode) {
            case 'HOURLY':
                return (
                    <DatePicker
                        allowClear={false}
                        value={this.state.selectedDate}
                        onChange={(date) => this.handleOnRangeChange(mode, date)}
                        disabledDate={(current) => {
                            return current && current > moment().endOf('day')
                        }}
                    />
                )
            case 'DAILY':
                return (
                    <DatePicker.WeekPicker
                        allowClear={false}
                        value={this.state.selectedDate}
                        onChange={(date) => this.handleOnRangeChange(mode, date)}
                        disabledDate={(current) => {
                            return current && current > moment().endOf('day')
                        }}
                    />
                )
            case 'MONTHLY':
                return null
            default:
                return null
        }
    }

    async handleOnModeChange(value) {
        let startDate = undefined
        let endDate = undefined
        switch (value) {
            case 'HOURLY':
                this.setState({ mode: value, selectedDate: moment() })
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
            case 'DAILY':
                this.setState({ mode: value, selectedDate: moment() })
                startDate = moment().startOf('week')
                endDate = moment().endOf('week')
                break
            case 'MONTHLY':
                this.setState({ mode: value })
                startDate = moment().subtract(11, 'month').startOf('month')
                endDate = moment().endOf('month')
                break
            default:
                break
        }
        this.props.onFetchData(value, startDate, endDate)
    }

    render() {
        const { countData, growthData, intl } = this.props
        return (
            <Card
                title={
                    <ActionWrapper>
                        <ActionLeftWrapper>{this.renderModeButton()}</ActionLeftWrapper>
                        {this.renderRangePicker()}
                    </ActionWrapper>
                }>
                <TitleWrapper><Title>{intl.formatMessage({ ...messages.newMemberCount })}</Title></TitleWrapper>
                <Chart height={300} data={countData} forceFit>
                    <Axis name="name" />
                    <Axis name={intl.formatMessage({ ...messages.newMemberCount })} />
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom type="interval" position={`name*${intl.formatMessage({ ...messages.newMemberCount })}`} color={window.shopperColor} />
                </Chart>


                <TitleWrapper><Title>{intl.formatMessage({ ...messages.memberTotalCount })}</Title></TitleWrapper>
                <Chart height={300} data={growthData} forceFit>
                    <Axis name="name" />
                    <Axis name={intl.formatMessage({ ...messages.memberTotalCount })} />
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom type="line" position={`name*${intl.formatMessage({ ...messages.memberTotalCount })}`} color={window.shopperColor} />
                    <Geom
                        type="point"
                        position={`name*${intl.formatMessage({ ...messages.memberTotalCount })}`}
                        size={4}
                        shape={"circle"}
                        style={{
                            stroke: "#fff",
                            lineWidth: 1
                        }}
                        color={window.shopperColor}
                    />
                </Chart>
            </Card>
        )
    }

}

export default injectIntl(MemberCountBarChart)