import React, { Component } from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import { IntlProvider, addLocaleData } from "react-intl"
import zh from "react-intl/locale-data/zh"
import { ConfigProvider } from 'antd'
import zh_TW from 'antd/lib/locale-provider/zh_TW'
import en_US from 'antd/lib/locale-provider/en_US'
import translations from "./i18n/locales"
import queryString from 'query-string'
import moment from 'moment'
import PrivateRoute from '~/src/components/routes/privateRoute'
import PageLayout from '~/src/components/layouts/main'
import Helmet from 'react-helmet'


import authStore from './stores/authStore'
import commonStore from './stores/commonStore'
import categoryStore from './stores/categoryStore'
import productStore from './stores/productStore'
import reservationStore from './stores/reservationStore'
import adsBannerStore from './stores/adsBannerStore'
import feedStore from './stores/feedStore'
import couponStore from './stores/couponStore'
import shopStore from './stores/shopStore'
import orderStore from './stores/orderStore'
import userStore from './stores/userStore'
import pospalStore from './stores/pospalStore'
import analysisStore from './stores/analysisStore'
import notificationStore from './stores/notificationStore'
import optionStore from './stores/optionStore'
import mediaStore from './stores/mediaStore'
import deliveryTimeSlotStore from './stores/deliveryTimeSlotStore'
import scanPaymentStore from './stores/scanPaymentStore'

import LoginPage from './containers/LoginPage'
import ShopLoginPage from './containers/ShopLoginPage'
import ForgotPasswordPage from './containers/ForgotPasswordPage'
import ResetPasswordPage from './containers/ResetPasswordPage'
import MainPage from './containers/MainPage'
import DeliveryTimeSlotPage from './containers/DeliveryTimeSlotPage'
import CategoryManagementPage from './containers/CategoryManagementPage'
import ProductListPage from './containers/ProductListPage'
import MainPageAdvertisementPage from './containers/MainPageAdvertisementPage'
import FeedManagementPage from './containers/FeedManagementPage'
import ShopInformationPage from './containers/ShopInformationPage'
import OrderManagementPage from './containers/OrderManagementPage'
import MemberListPage from './containers/MemberListPage'
import StaffManagementPage from './containers/StaffManagementPage'
import PospalTicketListPage from './containers/PospalTicketListPage'
import PospalTicketPrintPage from './containers/PospalTicketPrintPage'
import PospalCategoryListPage from './containers/PospalCategoryListPage'
import PospalProductListPage from './containers/PospalProductListPage'
import PospalMemberListPage from './containers/PospalMemberListPage'
import MemberAnalysisPage from './containers/MemberAnalysisPage'
import SaleAnalysisPage from './containers/SaleAnalysisPage'
import MemberBehaviorAnalysisPage from './containers/MemberBehaviorAnalysisPage'
import CouponManagementPage from './containers/CouponManagementPage'
import NotificationPage from './containers/NotificationPage'
import OptionManagementPage from './containers/OptionManagementPage'
import FoodOptionManagementPage from './containers/FoodOptionManagementPage'
import OnlineCouponPage from './containers/OnlineCouponPage'
import OfflineCouponPage from './containers/OfflineCouponPage'
import FoodOrderManagementPage from './containers/FoodOrderManagementPage'
import FoodSubchoiceManagementPage from './containers/FoodSubchoiceManagementPage'
import SubChoiceManagementPage from './containers/SubchoiceManagementPage'
import FoodSaleAnalysisPage from './containers/FoodSaleAnalysisPage'
import MemberFoodBehaviorAnalysisPage from './containers/MemberFoodBehaviorAnalysisPage'
import ProductStockPage from './containers/ProductStockPage'
import MemberCreditPage from './containers/MemberCreditPage'
import ScanPaymentPage from './containers/ScanPaymentPage'

addLocaleData([...zh])

moment.locale('zh-hk')

class App extends Component {

    isTokenExpired() {
        const tokenExpAt = localStorage.getItem('tokenExpAt')
        const expiresIn = Date.parse(tokenExpAt) - Date.now() // in milliseconds
        return expiresIn < 0
    }

    async componentDidMount() {
        if (localStorage.getItem('token') != null && !this.isTokenExpired()) {
            await authStore.getSelf(localStorage.getItem('token'))
            await shopStore.getSelfShop(localStorage.getItem('token'))
        }
    }

    render() {
        const params = queryString.parse(window.location.search)
        const locale = params.locale || 'zh'
        const messages = translations[locale]
        const hostname = window.location.hostname
        const theme = {
            primary: this.props.themeColor
        }
        return (
            <Provider
                authStore={authStore}
                commonStore={commonStore}
                categoryStore={categoryStore}
                productStore={productStore}
                reservationStore={reservationStore}
                adsBannerStore={adsBannerStore}
                feedStore={feedStore}
                couponStore={couponStore}
                deliveryTimeSlotStore={deliveryTimeSlotStore}
                shopStore={shopStore}
                orderStore={orderStore}
                userStore={userStore}
                pospalStore={pospalStore}
                analysisStore={analysisStore}
                notificationStore={notificationStore}
                optionStore={optionStore}
                mediaStore={mediaStore}
                scanPaymentStore={scanPaymentStore}>
                <IntlProvider locale={locale} messages={messages}>
                    <ConfigProvider locale={locale === 'zh' ? zh_TW : en_US}>
                        <ThemeProvider theme={theme}>
                            <Router>
                                <Switch>
                                    {
                                        hostname === 'portal.shopper.com.hk' || hostname === 'localhost' || hostname === 'mall-portal.shopper.com.hk'
                                            ? <Route exact path="/login" component={LoginPage} />
                                            : <Route exact path="/login" component={ShopLoginPage} />
                                    }
                                    <Route exact path="/forgotPassword" component={ForgotPasswordPage} />
                                    <Route exact path="/resetPassword" component={ResetPasswordPage} />
                                    <PageLayout>
                                        <PrivateRoute exact path="/" component={MainPage} />
                                        <PrivateRoute exact path="/categoryManagement" component={CategoryManagementPage} />
                                        <PrivateRoute exact path="/productList" component={ProductListPage} />
                                        <PrivateRoute exact path="/mainPageAdvertisement" component={MainPageAdvertisementPage} />
                                        <PrivateRoute exact path="/deliveryTimeSlot" component={DeliveryTimeSlotPage} />
                                        <PrivateRoute exact path="/feedManagement" component={FeedManagementPage} />
                                        <PrivateRoute exact path="/orderManagement" component={OrderManagementPage} />
                                        <PrivateRoute exact path="/shopInformation" component={ShopInformationPage} />
                                        <PrivateRoute exact path="/memberList" component={MemberListPage} />
                                        <PrivateRoute exact path="/staffManagement" component={StaffManagementPage} />
                                        <PrivateRoute exact path="/pospalTickets" component={PospalTicketListPage} />
                                        <PrivateRoute exact path="/pospalTicketPrint" component={PospalTicketPrintPage} />
                                        <PrivateRoute exact path="/pospalCategory" component={PospalCategoryListPage} />
                                        <PrivateRoute exact path="/productOption" component={OptionManagementPage} />
                                        <PrivateRoute exact path="/foodOption" component={FoodOptionManagementPage} />
                                        <PrivateRoute exact path="/pospalProduct" component={PospalProductListPage} />
                                        <PrivateRoute exact path="/pospalMember" component={PospalMemberListPage} />
                                        <PrivateRoute exact path="/memberAnalysis" component={MemberAnalysisPage} />
                                        <PrivateRoute exact path="/saleAnalysis" component={SaleAnalysisPage} />
                                        <PrivateRoute exact path="/foodSaleAnalysis" component={FoodSaleAnalysisPage} />
                                        <PrivateRoute exact path="/memberBehaviorAnalysis" component={MemberBehaviorAnalysisPage} />
                                        <PrivateRoute exact path="/memberFoodBehaviorAnalysis" component={MemberFoodBehaviorAnalysisPage} />
                                        <PrivateRoute exact path="/couponManagement" component={CouponManagementPage} />
                                        <PrivateRoute exact path="/onlineCoupon" component={OnlineCouponPage} />
                                        <PrivateRoute exact path="/offlineCoupon" component={OfflineCouponPage} />
                                        <PrivateRoute exact path="/notification" component={NotificationPage} />
                                        <PrivateRoute exact path="/foodOrder" component={FoodOrderManagementPage} />
                                        <PrivateRoute exact path="/foodSubchoice" component={FoodSubchoiceManagementPage} />
                                        <PrivateRoute exact path="/productSubchoice" component={SubChoiceManagementPage} />
                                        <PrivateRoute exact path="/productStock" component={ProductStockPage} />
                                        <PrivateRoute exact path="/memberCredit" component={MemberCreditPage} />
                                        <PrivateRoute exact path="/scanPayment" component={ScanPaymentPage} />
                                    </PageLayout>
                                </Switch>
                            </Router>
                        </ThemeProvider>
                    </ConfigProvider>
                </IntlProvider>
            </Provider>
        )
    }
}

export default App
