import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Card, Radio, Statistic, DatePicker } from 'antd'
import messages from '~/src/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import MemberCountBarChart from '~/src/components/charts/memberCountBarChart'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 16px;
    /* background-color: white; */
    
    .ant-card:not(:last-child) {
        margin-bottom: 16px;
    }
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;

`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

const rangeOptions = [
    { value: 'TODAY', text: messages.today },
    { value: 'YESTERDAY', text: messages.yesterday },
    { value: 'WEEK', text: messages.thisWeek },
    { value: 'MONTH', text: messages.thisMonth },
    { value: 'CUSTOMIZE', text: messages.customize },
]

@inject('commonStore', 'analysisStore') @observer
class MemberAnalysisPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mode: 'TODAY',
            range: undefined
        }
    }

    async componentDidMount() {
        const { analysisStore, commonStore } = this.props
        const { token, shop } = commonStore
        const startDate = moment().startOf('day')
        const endDate = moment().endOf('day')
        await analysisStore.getMemberCount(token, shop)
        await analysisStore.getMemberCountByPeriod(token, shop, 'HOURLY', startDate.format(), endDate.format())
        await analysisStore.getMemberCumulativeCountByPeriod(token, shop, 'HOURLY', startDate.format(), endDate.format())
    }

    async handleOnPeriodDataFetch(mode, startDate, endDate) {
        const { analysisStore, commonStore } = this.props
        const { token, shop } = commonStore
        await analysisStore.getMemberCountByPeriod(token, shop, mode, startDate.format(), endDate.format())
        await analysisStore.getMemberCumulativeCountByPeriod(token, shop, mode, startDate.format(), endDate.format())
    }

    renderRangeButton() {
        const { intl } = this.props
        const buttons = [
            <Radio.Group onChange={(e) => this.handleOnModeChange(e.target.value)} defaultValue="TODAY" value={this.state.mode} key={0}>
                {rangeOptions.map((r, i) => <Radio.Button key={r.value} value={r.value}>{intl.formatMessage({ ...r.text })}</Radio.Button>)}
            </Radio.Group>
        ]
        return this.state.mode !== 'CUSTOMIZE'
            ? buttons
            : buttons.concat(<DatePicker.RangePicker
                value={this.state.range}
                key={1}
                onChange={(v) => this.handleOnRangeChange(v)} />
            )
    }

    async handleOnRangeChange(v) {
        const { analysisStore, commonStore } = this.props
        const { token, shop } = commonStore
        const { mode } = this.state
        if (mode === 'CUSTOMIZE' && v && v.length > 0) {
            analysisStore.getMemberCount(token, shop, v[0].format(), v[1].format())
        }
        this.setState({ range: v })
    }

    async handleOnModeChange(value) {
        const { analysisStore, commonStore } = this.props
        const { token, shop } = commonStore
        let startDate = undefined
        let endDate = undefined
        switch (value) {
            case 'TODAY':
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
            case 'YESTERDAY':
                startDate = moment().startOf('day').subtract(1, 'day')
                endDate = moment().endOf('day').subtract(1, 'day')
                break
            case 'WEEK':
                startDate = moment().startOf('week')
                endDate = moment().endOf('week')
                break
            case 'MONTH':
                startDate = moment().startOf('month')
                endDate = moment().endOf('month')
                break
            case 'CUSTOMIZE':
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
            default:
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
        }
        analysisStore.getMemberCount(token, shop, startDate.format(), endDate.format())
        this.setState({ mode: value, range: [startDate, endDate] })
    }

    render() {
        const { analysisStore, intl } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.memberAnalysis })}</title>
                </Helmet>
                <Card
                    title={
                        <ActionWrapper>
                            <ActionLeftWrapper>{intl.formatMessage({ ...messages.memberAnalysis })}</ActionLeftWrapper>
                            {/* {this.renderRangeButton()} */}
                        </ActionWrapper>
                    }>
                    <Statistic title={intl.formatMessage({ ...messages.memberTotalCount })} value={analysisStore.memberCount} />
                </Card>
                <MemberCountBarChart
                    onFetchData={(mode, startDate, endDate) => this.handleOnPeriodDataFetch(mode, startDate, endDate)}
                    countData={analysisStore.memberCountByPeriod.map((s, i) => {
                        return { name: `${s._id}`, [intl.formatMessage({ ...messages.newMemberCount })]: s.count }
                    })}
                    growthData={analysisStore.memberCumulativeCountByPeriod.map(s => {
                        return { name: s._id, [intl.formatMessage({ ...messages.memberTotalCount })]: s.cumGrowth }
                    })}
                />
            </Container>
        )
    }
}

export default withRouter(injectIntl(MemberAnalysisPage))