import { observable, flow, action } from 'mobx'
import api from '../api'

class ScanPaymentStore {
    @observable isSubmitting = false
    @observable error = null
    @observable scanPayments = []
    @observable count = 0

    listScanPayment = flow(function* (token, shop, limit, skip) {
        this.isSubmitting = true
        try {
            const response = yield api.listScanPayment(token, shop, limit, skip)
            const { scanPayments, count } = response.data
            this.scanPayments = scanPayments
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })
}

export default new ScanPaymentStore()
