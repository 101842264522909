import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { message } from 'antd'
import { Helmet } from 'react-helmet'
import CreateNotificationForm from '~/src/components/forms/createNotificationForm'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

@inject('commonStore', 'notificationStore') @observer
class NotificationPage extends React.Component {

    handleOnNotificationCreate(values) {
        const { notificationStore, commonStore, intl } = this.props
        const { token, shop } = commonStore
        const { title, body } = values
        notificationStore.createNotification(token, shop, title, body)
            .then(() => {
                message.success(intl.formatMessage({ ...messages.pushNotificationSuccess }))
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.pushNotificationFailure }))
            })
    }

    render() {
        const { intl, notificationStore } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.pushNotification })}</title>
                </Helmet>
                <CreateNotificationForm
                    isSubmitting={notificationStore.isSubmitting}
                    onSubmit={(values) => this.handleOnNotificationCreate(values)} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(NotificationPage))