import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { Table, Modal, message } from 'antd'
import { getRandomId } from '~/src/lib/idGenerator'
import styled from 'styled-components'

const Footer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
`

class PospalTicketItemsModal extends Component {

    
    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: intl.formatMessage({ ...messages.quantity }),
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: intl.formatMessage({ ...messages.price }),
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                width: 100
            },
        ]
    }

    render() {
        const { intl, visible, isSubmitting, ticket } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                footer={null}
                title={intl.formatMessage({ ...messages.itemList })}
                onCancel={() => this.props.onClose()}>
                <Table
                    columns={this.renderTableColumn()}
                    dataSource={(ticket && ticket.ticket) ? ticket.ticket.items : []}
                    pagination={false}
                    loading={isSubmitting}
                    footer={() => 
                        (ticket && ticket.ticket)
                        ? <Footer><span>{`${intl.formatMessage({...messages.totalAmount})}: $${ticket.ticket.totalAmount}`}</span></Footer>
                        : null
                    }
                    rowKey={record => { return getRandomId() }}
                />
            </Modal>
        )
    }
}

export default injectIntl(PospalTicketItemsModal)