import { observable, flow } from 'mobx'
import api from '../api'

class DeliveryTimeSlotStore {
    @observable isSubmitting = false
    @observable error = null
    @observable deliveryTimeSlots = []

    createDeliveryTimeSlot = flow(function* (token, shop, name, startTime, endTime) {
        this.isSubmitting = true
        try {
            yield api.createDeliveryTimeSlot(token, shop, name, startTime, endTime)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listDeliveryTimeSlot = flow(function* (token, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.listDeliveryTimeSlot(token, shop)
            this.deliveryTimeSlots = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateDeliveryTimeSlot = flow(function* (token, shop, id, name, startTime, endTime) {
        this.isSubmitting = true
        try {
            yield api.updateDeliveryTimeSlot(token, shop, id, name, startTime, endTime)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteDeliveryTimeSlot = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            yield api.deleteDeliveryTimeSlot(token, shop, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(this.error)
        }
    })
}

export default new DeliveryTimeSlotStore()
