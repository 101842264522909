import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, message, Input, Icon, Checkbox, Row, DatePicker, Tag } from 'antd'
import { LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import messages from '~/src/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import CreateStaffModal from '~/src/components/createStaffModal'
import EditStaffModal from '~/src/components/editStaffModal'
import { statusOptions, pageSizeOptions, roleOptions } from '~/src/constants/options'
import update from 'immutability-helper'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

@inject('commonStore', 'scanPaymentStore') @observer
class ScanPaymentPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            pageSize: LIMIT
        }
    }

    async componentDidMount() {
        const { scanPaymentStore, commonStore, location } = this.props
        const { token, shop } = commonStore
        const { pageSize } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        await scanPaymentStore.listScanPayment(token, shop, pageSize, pageSize * (currentPage - 1))
        this.setState({ currentPage })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const page = pagination.current
        const { scanPaymentStore, commonStore } = this.props
        const { token, shop } = commonStore
        const { pageSize } = pagination
        scanPaymentStore.listScanPayment(token, shop, pageSize, pageSize * (page - 1))
        const href = `/scanPayment?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize
        })
    }

    renderTableColumn() {
        const { intl } = this.props
        const columns = [
            {
                title: intl.formatMessage({ ...messages.user }),
                dataIndex: 'user',
                key: 'user',
                render: (text, record) => {
                    return record.user.username
                }
            },
            {
                title: 'Transaction ID',
                dataIndex: 'stripeTransactionId',
                key: 'stripeTransactionId',
            },
            {
                title: intl.formatMessage({ ...messages.totalPrice }),
                dataIndex: 'totalPrice',
                key: 'totalPrice',
                render: (text, record) => {
                    return `$${record.totalPrice}`
                }
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                )
            }
        ]

        return columns
    }

    render() {
        const { scanPaymentStore, intl, commonStore } = this.props
        const { currentPage, pageSize } = this.state
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.scanPayment })}</title>
                </Helmet>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(scanPaymentStore.scanPayments)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: scanPaymentStore.count,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={scanPaymentStore.isSubmitting}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
            </Container>
        )
    }
}

export default withRouter(injectIntl(ScanPaymentPage))