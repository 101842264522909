import { observable, flow } from 'mobx'
import api from '../api'

class ReservationStore {
    @observable isSubmitting = false
    @observable error = null
    @observable reservations = []
    @observable count = 0

    createReservation = flow(function* (token, shop, productId, values) {
        this.isSubmitting = true
        const {
            userCountMin,
            userCountMax,
            time,
            duration,
            options,
            status,
        } = values
        try {
            yield api.createReservation(token, shop,
                productId,
                userCountMin,
                userCountMax,
                time.format(),
                duration,
                options.map(({key, ...fields}) => fields),
                status
            )
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    createReservationGroup = flow(function* (token, shop, productId, values) {
        this.isSubmitting = true
        const {
            userCountMin,
            userCountMax,
            period,
            week,
            timeSlots,
            duration,
            options,
            status,
        } = values
        try {
            yield api.createReservationGroup(token, shop,
                productId,
                userCountMin,
                userCountMax,
                period[0].format(),
                period[1].format(),
                week,
                timeSlots.map(item => { return item.time.seconds(0).format() }),
                duration,
                options.map(({key, ...fields}) => fields),
                status
            )
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })


    deleteReservation = flow(function* (token, shop, product, reservationId) {
        this.isSubmitting = true
        try {
            yield api.deleteReservation(token, shop, product, reservationId)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listReservation = flow(function* (token, shop, productId, limit, skip,
        sortField,
        timeMin,
        timeMax,
        status,
    ) {
        this.isSubmitting = true
        try {
            const response = yield api.listReservation(token, shop, productId, limit, skip,
                sortField,
                timeMin,
                timeMax,
                status,
            )
            const { reservations, count } = response.data
            this.reservations = reservations
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateReservation = flow(function* (token, shop, product, id, values) {
        this.isSubmitting = true
        const {
            userCountMin,
            userCountMax,
            time,
            duration,
            options,
            status,
        } = values
        try {
            const response = yield api.updateReservation(token, shop, product, id, 
                userCountMin,
                userCountMax,
                time.format(),
                duration,
                options.map(({key, ...fields}) => fields),
                status
            )
            const index = this.reservations.findIndex((b) => {
                return b._id === id
            })
            this.reservations[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
    
    inactivateReservations = flow(function* (token, shop, product, ids) {
        this.isSubmitting = true
        try {
            yield api.inactivateReservations(token, shop, product, ids)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new ReservationStore()
