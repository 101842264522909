import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, message, Input, Tag, Icon, Checkbox, Row, DatePicker, Divider, Carousel } from 'antd'
import { LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import messages from '~/src/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import OrderDetailModal from '~/src/components/orderDetailModal'
import UpdateManyOrderModal from '~/src/components/updateManyOrderModal'
import OrderPrintModal from '~/src/components/orderPrintModal'
import { orderStatusOptions, productTypeOptions, pageSizeOptions, deliveryMethodOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import _ from 'lodash'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

@inject('commonStore', 'orderStore', 'shopStore') @observer
class OrderManagementPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            orderDetailVisible: false,
            updateManyOrderVisible: false,
            orderPrintVisible: false,
            pageSize: LIMIT,
            filterValues: {
                orderId: undefined,
                type: [],
                status: [],
                trackingNumber: undefined,
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortedInfo: undefined,
            selectedOrderId: undefined,
            previewVisible: false,
            previewImages: [],
            selectedRowKeys: [],
            selectedOrders: []
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { orderStore, commonStore, location } = this.props
        const { token, shop } = commonStore
        const { pageSize, sortedInfo, filterValues } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        await orderStore.listOrder(token, shop, pageSize, pageSize * (currentPage - 1),
            sortField,
            filterValues.orderId,
            filterValues.type,
            filterValues.status,
            filterValues.trackingNumber,
            filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
            filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
        )
        this.setState({ currentPage })
    }

    handleOnOrderUpdate(values, reset) {
        const { orderStore, commonStore, intl } = this.props
        const { selectedOrderId } = this.state
        const { token, shop } = commonStore
        orderStore.updateOrder(token, shop, selectedOrderId, values.status, values.trackingNumber)
            .then(() => {
                reset()
                this.setState({ orderDetailVisible: false, selectedOrderId: undefined })
                message.success(intl.formatMessage({ ...messages.updateOrderSuccess }))
                const { pageSize, currentPage, sortField } = this.state
                const {
                    orderId,
                    type,
                    status,
                    trackingNumber,
                    createdAtMin,
                    createdAtMax
                } = this.state.filterValues
                orderStore.listOrder(token, shop, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    orderId,
                    type,
                    status,
                    trackingNumber,
                    createdAtMin ? createdAtMin.format() : undefined,
                    createdAtMax ? createdAtMax.format() : undefined
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateOrderFailure }))
            })
    }

    async handleOnManyOrderUpdate(values, reset) {
        const { orderStore, commonStore, intl } = this.props
        const { selectedOrders } = this.state
        const { token, shop } = commonStore
        try {
            await orderStore.updateManyOrderStatus(token, shop, selectedOrders.map(o => o._id), values.status)
            reset()
            this.setState({ updateManyOrderVisible: false })
            message.success(intl.formatMessage({ ...messages.updateOrderSuccess }))
            const { pageSize, currentPage, sortField } = this.state
            const {
                orderId,
                type,
                status,
                trackingNumber,
                createdAtMin,
                createdAtMax
            } = this.state.filterValues
            orderStore.listOrder(token, undefined, pageSize, pageSize * (currentPage - 1),
                sortField,
                orderId,
                type,
                status,
                trackingNumber,
                createdAtMin ? createdAtMin.format() : undefined,
                createdAtMax ? createdAtMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.updateOrderFailure }))
        }
    }

    handleOnUpdateStatusClick() {
        this.setState({ updateManyOrderVisible: true })
    }

    handleOnExportClick() {
        const { intl } = this.props
        const { selectedOrders } = this.state
        if (selectedOrders.length > 0) {
            const sheetHeader = [
                `${intl.formatMessage({ ...messages.orderId })}`,
                `${intl.formatMessage({ ...messages.orderDetail })}`,
                `${intl.formatMessage({ ...messages.deliveryMethod })}`,
                `${intl.formatMessage({ ...messages.pickUpStore })}`,
                `${intl.formatMessage({ ...messages.createdAt })}`,
                `${intl.formatMessage({ ...messages.price })}`,
                `${intl.formatMessage({ ...messages.contactDetail })}`,
                `${intl.formatMessage({ ...messages.address })}`,
            ]

            const data = []
            toJS(selectedOrders).forEach(o => {
                const deliveryIndex = deliveryMethodOptions.findIndex(d => d.value === o.deliveryMethod)
                const deliveryText = deliveryIndex > -1 ? intl.formatMessage({ ...deliveryMethodOptions[deliveryIndex].text }) : '-'
                var items = []
                const products = o.products.map(p => {
                    if (p.choices.length > 0) {
                        return `${p.product.name}(${p.choices.join(', ')}) X ${p.quantity}`
                    } else {
                        return `${p.product.name} X ${p.quantity}`
                    }
                })
                items = items.concat(products)
                const reservations = o.reservations.map(r => {
                    const { option, reservation } = r
                    const { options } = reservation
                    const index = options.findIndex(o => o._id === option)
                    if (index > -1) {
                        return `${r.reservation.product.name}(${options[index].name}}) X ${r.quantity}`
                    } else {
                        return `${r.reservation.product.name} X ${r.quantity}`
                    }
                })
                items = items.concat(reservations)
                return data.push([
                    o.orderId,
                    items.join(', '),
                    deliveryText,
                    o.pickUpStore,
                    moment(o.createdAt).format(DATE_FORMAT),
                    `$${o.price}`,
                    `${o.contact.name} ${o.contact.phoneNumber}`,
                    o.deliveryMethod === 'SFEXPRESS' ? `${o.contact.district} ${o.contact.street} ${o.contact.room}` : undefined
                ])
            })
            import('~/src/lib/xlsxHelper').then(xlsxHelper => {
                xlsxHelper.exportData(sheetHeader, data, 'orders.xlsx')
            })
        } else {
            message.error(intl.formatMessage({ ...messages.exportOrderReminder }))
        }
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { orderStore, commonStore } = this.props
        const { token, shop } = commonStore
        const { pageSize } = pagination
        const {
            orderId,
            type,
            status,
            trackingNumber,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        orderStore.listOrder(token, shop, pageSize, pageSize * (page - 1),
            sortField,
            orderId,
            type,
            status,
            trackingNumber,
            createdAtMin ? createdAtMin.format() : undefined,
            createdAtMax ? createdAtMax.format() : undefined
        )
        const href = `/orderManagement?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    async handleOnEditClick(record) {
        const { orderStore, commonStore } = this.props
        const { token, shop } = commonStore
        await orderStore.getOrder(token, shop, record._id)
        this.setState({ orderDetailVisible: true, selectedOrderId: record._id })
    }

    async handleOnPrintClick(record) {
        const { orderStore, commonStore } = this.props
        const { token, shop } = commonStore
        await orderStore.getOrder(token, shop, record._id)
        this.setState({ orderPrintVisible: true, selectedOrderId: record._id })
    }

    handleOnRowSelect(selectedRowKeys, selectedRows) {
        const { orderStore } = this.props
        const orders = toJS(orderStore.orders)
        this.setState({ selectedRowKeys: selectedRowKeys })
        const filteredProducts = _.unionBy(this.state.selectedOrders, orders, '_id').filter(o => {
            return selectedRowKeys.includes(o._id)
        })
        this.setState({ selectedOrders: filteredProducts })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'orderId':
            case 'trackingNumber':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'type':
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'createdAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'createdAtMin': { $set: undefined }, 'createdAtMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                orderId: undefined,
                trackingNumber: undefined,
                type: [],
                status: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'orderId':
            case 'trackingNumber':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'type':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {productTypeOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {orderStatusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'createdAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'orderId' || dataIndex === 'trackingNumber')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.orderId }),
                dataIndex: 'orderId',
                key: 'orderId',
                fixed: 'left',
                width: 150,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'orderId' && sortedInfo.order,
                ...this.getColumnSearchProps('orderId')
            },
            {
                title: intl.formatMessage({ ...messages.totalPrice }),
                dataIndex: 'price',
                key: 'price',
                width: 150,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'price' && sortedInfo.order,
            },
            {
                title: intl.formatMessage({ ...messages.refundAmount }),
                dataIndex: 'refundAmount',
                key: 'refundAmount',
                width: 100,
                render: (text, record) => {
                    return record.refundAmount ? record.refundAmount : '-'
                }
            },
            // {
            //     title: intl.formatMessage({ ...messages.type }),
            //     dataIndex: 'type',
            //     key: 'type',
            //     width: 100,
            //     render: (text, record) => {
            //         const index = productTypeOptions.findIndex(c => c.value===text)
            //         return index > -1 ? intl.formatMessage({ ...productTypeOptions[index].text }) : null
            //     },
            //     ...this.getColumnSearchProps('type')
            // },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text, record) => {
                    const index = orderStatusOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...orderStatusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.trackingNumber }),
                dataIndex: 'trackingNumber',
                key: 'trackingNumber',
                width: 150,
                ...this.getColumnSearchProps('trackingNumber')
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('createdAt')
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 100,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                            <Divider type='vertical'/>
                            <a onClick={() => this.handleOnPrintClick(record)}>
                                {intl.formatMessage({ ...messages.print })}
                            </a>
                        </span>
                    )
                }
            }
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'orderId':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.orderId })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'trackingNumber':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.trackingNumber })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = orderStatusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'createdAtMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'createdAtMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { orderStore, shopStore, intl } = this.props
        const { currentPage, pageSize, selectedOrderId } = this.state
        var selectedOrderIndex
        if (selectedOrderId) {
            const index = orderStore.orders.findIndex(o => o._id === selectedOrderId)
            selectedOrderIndex = index
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.orderManagement })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                            {intl.formatMessage({ ...messages.resetAllSearch })}
                        </Button>
                        <Button type="primary" onClick={() => this.handleOnExportClick()} disabled={this.state.selectedRowKeys.length < 1}>
                            {intl.formatMessage({ ...messages.export })}
                        </Button>
                    </ActionLeftWrapper>
                </ActionWrapper>
                {
                    this.state.selectedRowKeys.length > 0
                        ? <ActionWrapper><div>{intl.formatMessage({ ...messages.selectedCount }, { count: this.state.selectedRowKeys.length })}</div></ActionWrapper>
                        : null
                }
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(orderStore.orders)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: orderStore.count,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        scroll={{ x: 1000 }}
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={orderStore.isSubmitting}
                        rowKey={record => record._id}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: this.state.selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => this.handleOnRowSelect(selectedRowKeys, selectedRows),
                        }}
                    />
                </TableWrapper>
                <OrderDetailModal
                    order={toJS(orderStore.orders[selectedOrderIndex])}
                    visible={this.state.orderDetailVisible}
                    isSubmitting={orderStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnOrderUpdate(values, reset)}
                    onClose={() => this.setState({ orderDetailVisible: false, selectedOrderId: undefined })} />
                <UpdateManyOrderModal
                    visible={this.state.updateManyOrderVisible}
                    isSubmitting={orderStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnManyOrderUpdate(values, reset)}
                    onClose={() => this.setState({ updateManyOrderVisible: false })} />
                <OrderPrintModal
                    order={toJS(orderStore.orders[selectedOrderIndex])}
                    shop={toJS(shopStore.selfShop)}
                    visible={this.state.orderPrintVisible}
                    isSubmitting={orderStore.isSubmitting}
                    onEmail={(values, reset) => this.handleOnEmailTicket(values, reset) }
                    onClose={() => this.setState({ orderPrintVisible: false, selectedOrderId: undefined })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(OrderManagementPage))