import React from 'react'
import { Form, Input, Upload, Icon, Modal, Button, InputNumber } from 'antd'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import validator from '~/src/lib/validator'
import RichTextEditor from '~/src/components/richTextEditor'
import BraftEditor from 'braft-editor'
import DeliveryMethodsCheckout from '~/src/components/forms/editSelfShopForm/deliveryMethodsCheckbox'
import StoreTable from '~/src/components/forms/editSelfShopForm/storeTable'
import { hasError, getErrorMessage } from '~/src/lib/errorMessages'
import { shopTypeOptions } from '~/src/constants/options'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

class EditSelfShopForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: ''
        }
        this.storeTable = React.createRef()
    }

    handleSubmit(e) {
        e.preventDefault()
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.storeTable.submit()
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values)
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
        this.storeTable.reset()
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false })
    }

    handlePreview(file) {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        })
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    getTypeText() {
        const { intl, initial } = this.props
        if (initial && initial.type) {
            const index = shopTypeOptions.findIndex(s => s.value === initial.type)
            if (index > -1) {
                return intl.formatMessage({ ...shopTypeOptions[index].text })
            } else {
                return null
            }
        } else {
            return null
        }

    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        const tailFormItemLayout = {
            wrapperCol: {
                span: 20,
                offset: 4
            }
        }
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">{intl.formatMessage({ ...messages.uploadPhoto })}</div>
            </div>
        )
        return (
            <Form {...formItemLayout} style={{ backgroundColor: 'white', padding: 16 }} onSubmit={e => this.handleSubmit(e)}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        initial ? initial.name : undefined
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.email })}>
                    {
                        initial ? initial.email : undefined
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.address })}>
                    {
                        initial ? initial.address : undefined
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.initial })}>
                    {
                        initial ? initial.initial : undefined
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.phoneNumber })}>
                    {
                        getFieldDecorator('phoneNumber', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.phoneNumber : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.phoneNumber })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.website })}>
                    {
                        getFieldDecorator('website', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.website : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.website })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>

                <Form.Item
                    label={intl.formatMessage({ ...messages.shopType })}>
                    {this.getTypeText()}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.shopDescription })}>
                    {
                        getFieldDecorator('description', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.description) : undefined
                        })(
                            <RichTextEditor
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                            />
                        )
                    }
                </Form.Item>

                <Form.Item
                    label={intl.formatMessage({ ...messages.termsAndConditions })}>
                    {
                        getFieldDecorator('terms', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.terms) : undefined
                        })(
                            <RichTextEditor
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.deliveryMethod })}>
                    {
                        getFieldDecorator('deliveryMethods', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.deliveryMethods  : []
                        })(
                            <DeliveryMethodsCheckout
                                initialValue={initial ? initial.deliveryMethods : []}
                                wrappedComponentRef={(inst) => this.deliveryMethodsCheckbox = inst} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.pickUpStore })}>
                    {
                        getFieldDecorator('stores', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.stores.map(s => { return { address: s } }) : undefined
                        })(
                            <StoreTable
                                shopId={initial ? initial._id : undefined}
                                initialValue={initial ? initial.stores.map(s => { return { address: s } }) : undefined}
                                wrappedComponentRef={(inst) => this.storeTable = inst} />
                        )
                    }
                </Form.Item>

                {
                    initial && initial.type === 'RETAIL' && initial.isCustomLogistics && getFieldValue('deliveryMethods').includes('SFEXPRESS')
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.freeCustomShippingPrice })}>
                                {
                                    getFieldDecorator('freeCustomShippingPrice', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                                        ],
                                        initialValue: initial ? initial.freeCustomShippingPrice : undefined
                                    })(
                                        <InputNumber
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            placeholder={intl.formatMessage({ ...messages.freeCustomShippingPrice })}
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                {
                    initial && initial.type === 'RETAIL' && initial.isCustomLogistics && getFieldValue('deliveryMethods').includes('SFEXPRESS')
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.nonfreeCustomShippingFee })}>
                                {
                                    getFieldDecorator('nonfreeCustomShippingFee', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                                        ],
                                        initialValue: initial ? initial.nonfreeCustomShippingFee : undefined
                                    })(
                                        <InputNumber
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            placeholder={intl.formatMessage({ ...messages.nonfreeCustomShippingFee })}
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                <Form.Item
                    label={intl.formatMessage({ ...messages.shopLogo })}
                    help={intl.formatMessage({ ...messages.squareRatioReminder })}>
                    {getFieldDecorator(`logo`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: (initial && initial.logo) ? [{
                            uid: '1',
                            name: 'xxx.png',
                            status: 'done',
                            url: initial.logo,
                        }] : []
                    })(
                        <Upload
                            listType="picture-card"
                            onPreview={(file) => this.handlePreview(file)}
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {getFieldValue('logo').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>

                <Form.Item
                    label={intl.formatMessage({ ...messages.feedCover })}
                    help={intl.formatMessage({ ...messages.bannerRatioReminder })}>
                    {getFieldDecorator(`feedCover`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: (initial && initial.feedCover) ? [{
                            uid: '1',
                            name: 'xxx.png',
                            status: 'done',
                            url: initial.feedCover,
                        }] : []
                    })(
                        <Upload
                            listType="picture-card"
                            onPreview={(file) => this.handlePreview(file)}
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {getFieldValue('feedCover').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>

                <Form.Item
                    label={intl.formatMessage({ ...messages.searchCover })}
                    help={intl.formatMessage({ ...messages.bannerRatioReminder })}>
                    {getFieldDecorator(`searchCover`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: (initial && initial.searchCover) ? [{
                            uid: '1',
                            name: 'xxx.png',
                            status: 'done',
                            url: initial.searchCover,
                        }] : []
                    })(
                        <Upload
                            listType="picture-card"
                            onPreview={(file) => this.handlePreview(file)}
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {getFieldValue('searchCover').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.couponCover })}
                    help={intl.formatMessage({ ...messages.bannerRatioReminder })}>
                    {getFieldDecorator(`couponCover`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: (initial && initial.couponCover) ? [{
                            uid: '1',
                            name: 'xxx.png',
                            status: 'done',
                            url: initial.couponCover,
                        }] : []
                    })(
                        <Upload
                            listType="picture-card"
                            onPreview={(file) => this.handlePreview(file)}
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {getFieldValue('couponCover').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <ActionWrapper>
                        <Button onClick={() => this.resetForm()}>
                            {intl.formatMessage({ ...messages.reset })}
                        </Button>
                        <Button type="primary" htmlType='submit'>
                            {intl.formatMessage({ ...messages.submit })}
                        </Button>
                    </ActionWrapper>
                </Form.Item>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(EditSelfShopForm))