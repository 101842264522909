import { observable, flow, action } from 'mobx'
import api from '../api'

class OrderStore {
    @observable isSubmitting = false
    @observable error = null
    @observable orders = []
    @observable count = 0
    @observable foodOrders = []
    @observable foodOrderCount = 0

    listOrder = flow(function* (token, shop, limit, skip,
        sortField,
        orderId,
        type,
        status,
        trackingNumber,
        createdAtMin,
        createdAtMax
    ) {
        this.isSubmitting = true
        try {
            const response = yield api.listOrder(token, shop, limit, skip,
                sortField,
                orderId,
                type,
                status,
                trackingNumber,
                createdAtMin,
                createdAtMax
            )
            const { orders, count } = response.data
            this.orders = orders
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getOrder = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            const response = yield api.getOrder(token, shop, id)
            const index = this.orders.findIndex(o => o._id === id)
            this.orders[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateOrder = flow(function* (token, shop, id, status, trackingNumber) {
        this.isSubmitting = true
        try {
            const response = yield api.updateOrder(token, shop, id, status, trackingNumber)
            const index = this.orders.findIndex((b) => {
                return b._id === id
            })
            this.orders[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listFoodOrder = flow(function* (token, shop, limit, skip,
        sortField,
        orderId,
        deliveryMethod,
        status,
        createdAtMin,
        createdAtMax
    ) {
        this.isSubmitting = true
        try {
            const response = yield api.listFoodOrder(token, shop, limit, skip,
                sortField,
                orderId,
                deliveryMethod,
                status,
                createdAtMin,
                createdAtMax
            )
            const { orders, count } = response.data
            this.foodOrders = orders
            this.foodOrderCount = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getFoodOrder = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            const response = yield api.getFoodOrder(token, shop, id)
            const index = this.foodOrders.findIndex(o => o._id === id)
            this.foodOrders[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateFoodOrder = flow(function* (token, shop, id, status) {
        this.isSubmitting = true
        try {
            const response = yield api.updateFoodOrder(token, shop, id, status)
            const index = this.foodOrders.findIndex((b) => {
                return b._id === id
            })
            this.foodOrders[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateManyOrderStatus = flow(function* (token, shop, orderIds, status) {
        this.isSubmitting = true
        try {
            yield api.updateManyOrderStatus(token, shop, orderIds, status)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new OrderStore()
