import React, { Component } from 'react'
import { Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import CreatePospalProductForm from '~/src/components/forms/createPospalProductForm'

class CreatePospalProductModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { intl, visible, isSubmitting, categories, error, shop, optionGroups } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width={960}
                title={intl.formatMessage({ ...messages.createProduct })}
                okText={intl.formatMessage({...messages.confirm})}
                cancelText={intl.formatMessage({...messages.cancel})}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => this.props.onClose()}>
                <CreatePospalProductForm
                    isSubmitting={isSubmitting}
                    categories={categories}
                    // optionGroups={optionGroups}
                    error={error}
                    shop={shop}
                    onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                    wrappedComponentRef={(inst) => this.formRef = inst}/>
            </Modal>
        )
    }
}

export default injectIntl(CreatePospalProductModal)