import React, { Component } from 'react'
import { Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import CreateProductSubchoiceForm from '~/src/components/forms/createProductSubchoiceForm'

class CreateProductSubchoiceModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { intl, visible, isSubmitting, productSubchoice } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                title={productSubchoice ? intl.formatMessage({ ...messages.editSubchoice }) : intl.formatMessage({ ...messages.createSubchoice })}
                okText={intl.formatMessage({ ...messages.confirm })}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => this.props.onClose()}>
                {
                    visible
                        ? (
                            <CreateProductSubchoiceForm
                                initial={productSubchoice}
                                isSubmitting={isSubmitting}
                                onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                                wrappedComponentRef={(inst) => this.formRef = inst} />
                        )
                        : null
                }
            </Modal>
        )
    }
}

export default injectIntl(CreateProductSubchoiceModal)