import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Row, Col, Statistic, Table } from 'antd'
import messages from '~/src/messages'
import PospalFilterForm from '~/src/components/forms/pospalFilterForm'
import { DATE_FORMAT } from '~/src/constants/format'
import { LIMIT } from '~/src/constants/common'
import { pageSizeOptions } from '~/src/constants/options'
import moment from 'moment'
import PospalTicketItemsModal from '~/src/components/pospalTicketItemsModal'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const TableWrapper = styled.div`
    margin-top: 16px;
    background-color: white;
`

@inject('pospalStore', 'commonStore') @observer
class PospalTicketListPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            pageSize: LIMIT,
            branch: undefined,
            date: undefined,
            sortedInfo: undefined,
            selectedTicketId: undefined,
            pospalTicketItemsVisible: false
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { pospalStore, commonStore } = this.props
        const { token, shop } = commonStore
        await pospalStore.listPospalBranch(token, shop)
    }

    async handleOnSubmit(values, reset) {
        const { branch, date } = values
        this.setState({ branch, date }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    async handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const { pospalStore, commonStore } = this.props
        const { token, shop } = commonStore
        const page = pagination.current
        const { pageSize } = pagination
        const { date, branch } = this.state
        const startTime = date.clone().hours(0).minutes(0).second(0).format()
        const endTime = date.clone().hours(0).minutes(0).second(0).add(1, 'days').format()
        await pospalStore.listPospalTicket(token, shop, branch, pageSize, pageSize * (page - 1),
            sortField,
            startTime,
            endTime
        )
        const href = `/pospalTickets?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    handleOnItemListClick(record) {
        this.setState({ pospalTicketItemsVisible: true, selectedTicketId: record._id })
    }

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.cashierId }),
                dataIndex: 'ticket.cashier.jobNumber',
                key: 'cashierId',
                width: 200,
            },
            {
                title: intl.formatMessage({ ...messages.cashierName }),
                dataIndex: 'ticket.cashier.name',
                key: 'ticket.cashier.name',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'ticket.cashier.name' && sortedInfo.order,
            },
            {
                title: intl.formatMessage({ ...messages.totalAmount }),
                dataIndex: 'ticket.totalAmount',
                key: 'ticket.totalAmount',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'ticket.totalAmount' && sortedInfo.order,
                width: 100,
                render: (totalAmount) => (
                    <span>
                        {`$ ${totalAmount}`}
                    </span>
                )
            },
            {
                title: intl.formatMessage({ ...messages.checkedOutAt }),
                dataIndex: 'ticket.datetime',
                key: 'ticket.datetime',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'ticket.datetime' && sortedInfo.order,
                render: (checkedOutAt) => (
                    <span>
                        {moment(checkedOutAt).format(DATE_FORMAT)}
                    </span>
                )
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                width: 100,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnItemListClick(record)}>
                                {intl.formatMessage({ ...messages.itemList })}
                            </a>
                        </span>
                    )
                }
            }
        ]
    }

    getDailyTotalAmount() {
        const { pospalStore } = this.props
        return pospalStore.tickets.reduce((acc, current) => { return acc + current.ticket.totalAmount }, 0)
    }

    render() {
        const { pospalStore, intl, title } = this.props
        const { currentPage, pageSize, selectedTicketId, branch } = this.state
        var selectedTicketIndex
        if (selectedTicketId) {
            const index = pospalStore.tickets.findIndex(o => o._id === selectedTicketId)
            selectedTicketIndex = index
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.pospalTickets })}</title>
                </Helmet>
                <PospalFilterForm
                    branches={toJS(pospalStore.branches)}
                    onSubmit={(values, reset) => this.handleOnSubmit(values, reset)} />
                {
                    branch
                        ? (
                            <React.Fragment>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Statistic title={intl.formatMessage({ ...messages.dailyTotalAmount })} value={this.getDailyTotalAmount()} />
                                    </Col>
                                </Row>
                                <TableWrapper>
                                    <Table
                                        columns={this.renderTableColumn()}
                                        dataSource={toJS(pospalStore.tickets)}
                                        pagination={
                                            {
                                                showSizeChanger: true,
                                                defaultPageSize: LIMIT,
                                                pageSizeOptions: pageSizeOptions,
                                                showQuickJumper: true,
                                                current: +currentPage,
                                                pageSize: pageSize,
                                                total: pospalStore.count,
                                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                                            }
                                        }
                                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                                        loading={pospalStore.isSubmitting}
                                        rowKey={record => record._id}
                                    />
                                </TableWrapper>
                            </React.Fragment>
                        )
                        : null
                }
                <PospalTicketItemsModal
                    ticket={selectedTicketIndex > -1 ? toJS(pospalStore.tickets[selectedTicketIndex]) : undefined}
                    visible={this.state.pospalTicketItemsVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnAdsBannerUpdate(values, reset)}
                    onClose={() => this.setState({ pospalTicketItemsVisible: false, selectedTicketId: undefined })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(PospalTicketListPage))