import React from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { Card, Radio, DatePicker } from 'antd'
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Legend,
    Label
} from "bizcharts"
import messages from '~/src/messages'
import moment from 'moment'
import { analysisPeriodModeOptions } from '~/src/constants/options'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
`

const Title = styled.h3`

`

class ProductBarChart extends React.Component {

    render() {
        const { data, intl } = this.props
        return (
            <Card title={'過去12個月數訂單數據'}>
                {/* <TitleWrapper><Title>{intl.formatMessage({ ...messages.orderCount })}</Title></TitleWrapper> */}
                <Chart height={400} data={data} padding={[50, 'auto', 'auto', 'auto']} forceFit
                    scale={{
                        name: {
                            alias: 'date',
                        },
                        count: {
                            min: 0,
                            tickCount: 7,
                            alias: '訂單數',
                        },
                        amount: {
                            min: 0,
                            tickCount: 7,
                            alias: '金額',
                        },
                    }}>
                    <Axis name="name" />
                    <Axis name="count" title />
                    <Axis name="amount" title />
                    <Tooltip
                        crosshairs={{
                            type: "rect"
                        }}
                    />
                    <Geom type="interval" position={`name*count`} color={window.shopperColor}>
                        <Label
                            content={[`name*count`, (name, count) => `${count}單`]}
                            labelLine={{
                                lineWidth: 1,
                                stroke: '#ff8800',
                                lineDash: [2, 2],
                            }} />
                    </Geom>
                    <Geom
                        type="line"
                        position={`name*amount`}
                        color={window.shopperColor}
                        // shape="smooth"
                        size={2}
                        label={{
                            formatter: val => `$${val}`,
                            textStyle: {
                                fill: 'red'
                            },
                        }} />
                    <Geom
                        type="point"
                        position={`name*amount`}
                        color="#000000"
                        shape="circle"
                        size={4}
                    />
                    <Legend position="bottom" />
                </Chart>
            </Card >
        )
    }

}

export default injectIntl(ProductBarChart)