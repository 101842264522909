import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { DATE_FORMAT } from '~/src/constants/format'
import styled from 'styled-components'
import messages from '~/src/messages'
import moment from 'moment'
import { LIMIT } from '~/src/constants/common'
import { Table, Button, Tag, Modal, Input, Icon, Checkbox, Row, Descriptions } from 'antd'
import { statusOptions, pageSizeOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import { toJS } from 'mobx'
import ProductBarChart from '~/src/components/charts/productBarChart'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

@inject('commonStore', 'userStore', 'analysisStore') @observer
class ProductAnalysisModal extends Component {

    render() {
        const { analysisStore, intl, visible, product } = this.props
        if (!product) {
            return null
        }
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.productAnalysis })}
                cancelText={intl.formatMessage({...messages.cancel})}
                onCancel={() => this.props.onClose()}>
                <Descriptions>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.productId})}>{product.productId}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.name})}>{product.name}</Descriptions.Item>
                </Descriptions>
                <ProductBarChart
                    data={analysisStore.productSummaryByPeriod.map((s, i) => {
                        return { name: `${s._id}`, count: s.totalCount, amount: s.totalAmount }
                    })} />
            </Modal>
        )
    }
}

export default injectIntl(ProductAnalysisModal, { withRef: true })
