import React from 'react'
import { Form, Button } from 'antd'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { statusOptions } from '~/src/constants/options'
import validator from '~/src/lib/validator'
import StockTable from '~/src/components/forms/editProductStockForm/stockTable'
import { getRandomId } from '~/src/lib/idGenerator'


class EditProductStockForm extends React.Component {

    constructor(props) {
        super(props)
        this.choiceTable = React.createRef()
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { isSubmitting, intl, initial, options, product } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form>
                <Form.Item>
                    {
                        getFieldDecorator('stocks', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial
                        })(
                            <StockTable
                                product={product}
                                options={options}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(EditProductStockForm))