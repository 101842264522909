import React from 'react'
import { Form, Input, Select, Upload, Icon, Checkbox, Modal, Cascader, InputNumber } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'
import { statusOptions, groupOptions, productTypeOptions } from '~/src/constants/options'
import { LIMIT } from '~/src/constants/common'
import RichTextEditor from '~/src/components/richTextEditor'
import BraftEditor from 'braft-editor'
import { hasError, getErrorMessage } from '~/src/lib/errorMessages'

class CreateProductForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: ''
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false })
    }

    handlePreview(file) {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        })
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    parseProductId(productId) {
        const { shop } = this.props
        const index = productId.indexOf(shop.initial)
        if (index > -1) {
            return productId.substr(shop.initial.length)
        } else {
            return productId
        }
    }


    getInitialCategory(categories) {
        const { initial } = this.props
        var result = []
        for (var i = 0; i < categories.length; i++) {
            const { subcategories, _id } = categories[i]
            if (_id === initial.category._id) {
                result.push(_id)
                break
            } else if (subcategories) {
                const temp = this.getInitialCategory(subcategories)
                if (temp.length > 0) {
                    result.push(_id)
                    result = result.concat(temp)
                }
            }
        }
        return result
    }

    renderCategoryOptions(categories) {
        return categories.map((b, index) => {
            const result = {
                key: b._id,
                value: b._id,
                label: b.name
            }
            if (b.subcategories) {
                result.children = this.renderCategoryOptions(b.subcategories)
            }
            return result
        })
    }

    renderImageUploaderArea() {
        const { intl, isSubmitting, initial, shop } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">{intl.formatMessage({ ...messages.uploadPhoto })}</div>
            </div>
        )
        const isPhotoRequired = shop.type === 'RETAIL'
        return (
            <Form.Item
                label={intl.formatMessage({ ...messages.uploadPhoto })}
                help={intl.formatMessage({...messages.squareRatioReminder})}
            >
                {getFieldDecorator(`photos`, {
                    valuePropName: 'fileList',
                    getValueFromEvent: (e) => this.normFile(e),
                    rules: [
                        { required: isPhotoRequired, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                    ],
                    initialValue: initial ? initial.photos.map((photo, index) => {
                        return {
                            uid: photo._id,
                            name: 'xxx.png',
                            status: 'done',
                            url: photo.path,
                        }
                    }) : []
                })(
                    <Upload
                        listType="picture-card"
                        onPreview={(file) => this.handlePreview(file)}
                        disabled={isSubmitting}
                        beforeUpload={(file) => {
                            return false
                        }}
                    >
                        {getFieldValue('photos').length >= LIMIT ? null : uploadButton}
                    </Upload>
                )}
            </Form.Item>
        )
    }

    render() {
        const { isSubmitting, intl, initial, error, shop, categories } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.introduction })}>
                    {
                        getFieldDecorator('introduction', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.introduction : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.productId })}
                    validateStatus={hasError(error, 4002) ? 'error' : null}
                    help={getErrorMessage(intl, error, 4002)}>
                    {
                        getFieldDecorator('productId', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? this.parseProductId(initial.productId) : undefined
                        })(
                            <Input
                                addonBefore={shop.initial}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.description })}>
                    {
                        getFieldDecorator('description', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.description) : BraftEditor.createEditorState(null)
                        })(
                            <RichTextEditor 
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.logisticDescription })}>
                    {
                        getFieldDecorator('logisticDescription', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.logisticDescription) : BraftEditor.createEditorState(null)
                        })(
                            <RichTextEditor 
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                            />
                        )
                    }
                </Form.Item>
                {this.renderImageUploaderArea()}
                <Form.Item
                    label={intl.formatMessage({ ...messages.priority })}>
                    {
                        getFieldDecorator('priority', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.priority : 1
                        })(
                            <InputNumber
                                placeholder={intl.formatMessage({ ...messages.priority })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.recommend })}>
                    {
                        getFieldDecorator('isRecommended', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            valuePropName: 'checked',
                            initialValue: initial ? initial.isRecommended : false
                        })(
                            <Checkbox />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.productType })}>
                    {
                        getFieldDecorator('productType', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.productType : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    productTypeOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.group })}>
                    {
                        getFieldDecorator('group', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.group : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    groupOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.category })}>
                    {
                        getFieldDecorator('category', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? this.getInitialCategory(categories) : undefined
                        })(
                            <Cascader
                                options={this.renderCategoryOptions(categories)}
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.price })}
                    help={intl.formatMessage({ ...messages.priceErrorReminder })}>
                    {
                        getFieldDecorator('price', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.price : undefined
                        })(
                            <InputNumber
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.stock })}>
                    {
                        getFieldDecorator('stock', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.stock : undefined
                        })(
                            <InputNumber
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.cost })}>
                    {
                        getFieldDecorator('cost', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.cost : undefined
                        })(
                            <InputNumber
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreateProductForm))