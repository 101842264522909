
import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, message, Input, Tag, Icon, Checkbox, Row, DatePicker, Popconfirm, Modal, Divider } from 'antd'
import { LIMIT, OPTION_LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import messages from '~/src/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import CreateFoodOptionModal from '~/src/components/createFoodOptionModal'
import EditFoodOptionModal from '~/src/components/editFoodOptionModal'
import { statusOptions, productTypeOptions, pageSizeOptions } from '~/src/constants/options'
import update from 'immutability-helper'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

@inject('commonStore', 'optionStore') @observer
class FoodOptionManagementPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            createOptionVisible: false,
            editOptionVisible: false,
            pageSize: LIMIT,
            filterValues: {
                fieldName: undefined,
                status: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortedInfo: undefined,
            selectedOptionId: undefined,
            previewVisible: false,
            previewImage: ''
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { optionStore, commonStore, location } = this.props
        const { token, shop } = commonStore
        const { pageSize, sortedInfo, filterValues } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : undefined
        await optionStore.listFoodOption(token, shop, pageSize, pageSize * (currentPage - 1),
            sortField,
            filterValues.fieldName,
            filterValues.status,
            filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
            filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
        )
        this.setState({ currentPage })
    }

    async handleOnOptionCreate(values, reset) {
        const { optionStore, commonStore, intl } = this.props
        const { token, shop } = commonStore
        const { fieldName, remark, selectionRequired, priority, choices } = values
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : undefined
        try {
            await optionStore.createFoodOption(token, shop, fieldName, selectionRequired, remark, priority, choices)
            reset()
            await optionStore.listFoodOption(token, shop, pageSize, pageSize * (currentPage - 1),
                sortField,
                filterValues.fieldName,
                filterValues.status,
                filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
            )
            message.success(intl.formatMessage({ ...messages.createOptionSuccess }))
            this.setState({ createOptionVisible: false })
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.createOptionFailure }))
        }
    }

    async handleOnOptionEdit(values, reset) {
        const { optionStore, commonStore, intl } = this.props
        const { token, shop } = commonStore
        const { fieldName, selectionRequired, remark, priority, choices } = values
        const { currentPage, pageSize, selectedOptionId, sortedInfo, filterValues } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : undefined
        try {
            await optionStore.updateFoodOption(token, shop, selectedOptionId, fieldName, selectionRequired, remark, priority, choices)
            reset()
            await optionStore.listFoodOption(token, shop, pageSize, pageSize * (currentPage - 1),
                sortField,
                filterValues.fieldName,
                filterValues.status,
                filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
            )
            message.success(intl.formatMessage({ ...messages.updateOptionSuccess }))
            this.setState({ editOptionVisible: false, selectedOptionId: undefined })
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.updateOptionFailure }))
        }
    }

    async handleOnOptionDelete(optionId) {
        const { optionStore, commonStore, intl } = this.props
        const { token, shop } = commonStore
        const { currentPage, pageSize, sortedInfo, filterValues } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : undefined
        try {
            await optionStore.deleteFoodOption(token, shop, optionId)
            await optionStore.listFoodOption(token, shop, pageSize, pageSize * (currentPage - 1),
                sortField,
                filterValues.fieldName,
                filterValues.status,
                filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
            )
            message.success(intl.formatMessage({ ...messages.deleteOptionSuccess }))
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.deleteOptionFailure }))
        }
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false, previewImage: undefined })
    }

    handlePreview(url) {
        this.setState({
            previewImage: url,
            previewVisible: true,
        })
    }
    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { optionStore, commonStore } = this.props
        const { token, shop } = commonStore
        const { pageSize } = pagination
        const {
            fieldName,
            status,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        optionStore.listFoodOption(token, shop, pageSize, pageSize * (page - 1),
            sortField,
            fieldName,
            status,
            createdAtMin ? createdAtMin.format() : undefined,
            createdAtMax ? createdAtMax.format() : undefined
        )
        const href = `/foodOption?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    async handleOnEditClick(record) {
        this.setState({ editOptionVisible: true, selectedOptionId: record._id })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'fieldName':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'createdAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'createdAtMin': { $set: undefined }, 'createdAtMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnCreateOptionClick() {
        this.setState({ createOptionVisible: true })
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                fieldName: undefined,
                status: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'fieldName':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'createdAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'fieldName')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'fieldName',
                key: 'fieldName',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'fieldName' && sortedInfo.order,
                ...this.getColumnSearchProps('fieldName')
            },
            {
                title: intl.formatMessage({ ...messages.optionChoice }),
                key: 'optionChoice',
                render: (text, record) => {
                    return (
                        <div>
                            {
                                record.choices.map((_c) => {
                                    if (_c.subchoices.length > 0) {
                                        const subchoiceText = _c.subchoices.map(s => s.fieldName).join(', ')
                                        return (
                                            <Tag>
                                                {`${_c.name} ($${_c.priceAdjustment}) (${subchoiceText})`}
                                            </Tag>
                                        )
                                    } else {
                                        return (
                                            <Tag>
                                                {`${_c.name} ($${_c.priceAdjustment})`}
                                            </Tag>
                                        )
                                    }
                                })
                            }
                        </div>
                    )
                }
            },
            {
                title: intl.formatMessage({ ...messages.remark }),
                dataIndex: 'remark',
                key: 'remark',
                width: 200,
            },
            {
                title: intl.formatMessage({ ...messages.selectionRequired }),
                dataIndex: 'selectionRequired',
                key: 'selectionRequired',
                width: 200,
            },
            {
                title: intl.formatMessage({ ...messages.priority }),
                dataIndex: 'priority',
                key: 'priority',
                width: 200,
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text, record) => {
                    const index = statusOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('createdAt')
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 100,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                            <Divider type='vertical' />
                            <Popconfirm
                                key="unlink"
                                title={intl.formatMessage({ ...messages.unlinkReminder })}
                                onConfirm={() => this.handleOnOptionDelete(record._id)}
                                okText={intl.formatMessage({ ...messages.yes })}
                                cancelText={intl.formatMessage({ ...messages.no })}>
                                <a>
                                    {intl.formatMessage({ ...messages.delete })}
                                </a>
                            </Popconfirm>
                        </span>
                    )
                }
            }
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'fieldName':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.name })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'createdAtMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'createdAtMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { optionStore, intl } = this.props
        const { currentPage, pageSize, selectedOptionId } = this.state
        var selectedOptionIndex = -1
        if (selectedOptionId) {
            selectedOptionIndex = optionStore.foodOptions.findIndex(o => o._id === selectedOptionId)
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.optionManagement })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                            {intl.formatMessage({ ...messages.resetAllSearch })}
                        </Button>
                        <Button type="primary" onClick={() => this.handleOnCreateOptionClick()}>
                            {intl.formatMessage({ ...messages.createOption })}
                        </Button>
                    </ActionLeftWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(optionStore.foodOptions)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: optionStore.foodOptionCount,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        scroll={{ x: 1500 }}
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={optionStore.isSubmitting}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
                <CreateFoodOptionModal
                    visible={this.state.createOptionVisible}
                    isSubmitting={optionStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnOptionCreate(values, reset)}
                    onClose={() => this.setState({ createOptionVisible: false })} />
                <EditFoodOptionModal
                    foodOption={selectedOptionIndex > -1 ? toJS(optionStore.foodOptions[selectedOptionIndex]) : undefined}
                    visible={this.state.editOptionVisible}
                    isSubmitting={optionStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnOptionEdit(values, reset)}
                    onClose={() => this.setState({ editOptionVisible: false, selectedOptionId: undefined })} />
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Container>
        )
    }
}

export default withRouter(injectIntl(FoodOptionManagementPage))