import React from 'react'
import { Form, Cascader } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/src/messages'

class UpdateManyProductCategoryForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    renderCategoryOptions(categories) {
        return categories.map((b, index) => {
            const result = {
                key: b._id,
                value: b._id,
                label: b.name
            }
            if (b.subcategories) {
                result.children = this.renderCategoryOptions(b.subcategories)
            }
            return result
        })
    }

    render() {
        const { isSubmitting, intl, categories } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.category })}>
                    {
                        getFieldDecorator('category', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                        })(
                            <Cascader
                                options={this.renderCategoryOptions(categories)}
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                            />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(UpdateManyProductCategoryForm))