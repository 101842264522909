import { observable, flow } from 'mobx'
import api from '../api'

class NotificationStore {
    @observable isSubmitting = false
    @observable error = null
    
    createNotification = flow(function* (token, shop, title, body) {
        this.isSubmitting = true
        try {
            yield api.createNotification(token, shop, title, body)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

}

export default new NotificationStore()
