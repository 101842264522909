import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, Input, Icon, Checkbox, Row, DatePicker, Tag, Divider, message } from 'antd'
import { LIMIT } from '~/src/constants/common'
import { DATE_FORMAT } from '~/src/constants/format'
import messages from '~/src/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import { statusOptions, pageSizeOptions } from '~/src/constants/options'
import update from 'immutability-helper'
import MemberDetailModal from '~/src/components/memberDetailModal'
import UpdateMemberCreditModal from '~/src/components/updateMemberCreditModal'
import analysisStore from '../stores/analysisStore'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

@inject('commonStore', 'userStore', 'analysisStore') @observer
class MemberListPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            createMemberVisible: false,
            editMemberVisible: false,
            memberDetailVisible: false,
            memberCreditVisible: false,
            pageSize: LIMIT,
            filterValues: {
                username: undefined,
                email: undefined,
                status: [],
                validationDateMin: undefined,
                validationDateMax: undefined
            },
            sortedInfo: undefined,
            selectedMemberId: undefined,
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { userStore, commonStore, location } = this.props
        const { token, shop } = commonStore
        const { pageSize, sortedInfo, filterValues } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        await userStore.listMember(token, shop, pageSize, pageSize * (currentPage - 1),
            sortField,
            filterValues.username,
            filterValues.email,
            filterValues.status,
            filterValues.validationDateMin ? filterValues.validationDateMin.format() : undefined,
            filterValues.validationDateMax ? filterValues.validationDateMax.format() : undefined
        )
        this.setState({ currentPage })
    }

    async handleOnViewDetailClick(record) {
        const { analysisStore, commonStore } = this.props
        const { token, shop } = commonStore
        const now = moment()
        const start = now.clone().startOf('month').subtract(11, 'months').format()
        const end = now.clone().endOf('month').format()
        await analysisStore.getMemberSummaryByPeriod(token, shop, record._id, 'MONTHLY', start, end)
        this.setState({ selectedMemberId: record._id, memberDetailVisible: true })
    }

    handleOnAddCreditClick(record) {
        this.setState({ selectedMemberId: record._id, memberCreditVisible: true })
    }

    async handleOnMemberCreditUpdate(values, reset) {
        const { userStore, commonStore, intl } = this.props
        const { token, shop } = commonStore
        const { selectedMemberId, filterValues, pageSize, currentPage, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        const { credit } = values
        try {
            await userStore.addMemberCredit(token, shop, selectedMemberId, credit)
            reset()
            this.setState({ memberCreditVisible: false, selectedMemberId: undefined })
            message.success(intl.formatMessage({ ...messages.success }))
            userStore.listMember(token, shop, pageSize, pageSize * (currentPage - 1),
                sortField,
                filterValues.username,
                filterValues.email,
                filterValues.status,
                filterValues.validationDateMin ? filterValues.validationDateMin.format() : undefined,
                filterValues.validationDateMax ? filterValues.validationDateMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { userStore, commonStore } = this.props
        const { token, shop } = commonStore
        const { pageSize } = pagination
        const {
            username,
            email,
            status,
            validationDateMin,
            validationDateMax
        } = this.state.filterValues
        userStore.listMember(token, shop, pageSize, pageSize * (page - 1),
            sortField,
            username,
            email,
            status,
            validationDateMin ? validationDateMin.format() : undefined,
            validationDateMax ? validationDateMax.format() : undefined
        )
        const href = `/memberList?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    async handleOnEditClick(record) {
        this.setState({ editMemberVisible: true, selectedMemberId: record._id })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'username':
            case 'email':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'joinedAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'validationDateMin': { $set: undefined }, 'validationDateMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                username: undefined,
                email: undefined,
                status: [],
                validationDateMin: undefined,
                validationDateMax: undefined
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'username':
            case 'email':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'joinedAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.validationDateMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'validationDateMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.validationDateMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'validationDateMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'username' || dataIndex === 'email')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { commonStore } = this.props
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.username }),
                dataIndex: 'username',
                key: 'username',
                fixed: 'left',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'username' && sortedInfo.order,
                ...this.getColumnSearchProps('username')
            },
            {
                title: intl.formatMessage({ ...messages.email }),
                dataIndex: 'email',
                key: 'email',
                fixed: 'left',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'email' && sortedInfo.order,
                ...this.getColumnSearchProps('email')
            },
            {
                title: intl.formatMessage({ ...messages.credit }),
                dataIndex: 'credit',
                key: 'credit',
                width: 100,
            },
            {
                title: intl.formatMessage({ ...messages.joinedAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                )
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnViewDetailClick(record)}>
                                {intl.formatMessage({ ...messages.viewDetail })}
                            </a>
                            <Divider type='vertical' />
                            <a onClick={() => this.handleOnAddCreditClick(record)}>
                                {intl.formatMessage({ ...messages.addCredit })}
                            </a>
                        </span>
                    )
                }
            },
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'username':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.username })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'email':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.email })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'validationDateMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.joinedAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'validationDateMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.joinedAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { userStore, intl } = this.props
        const { currentPage, pageSize, selectedMemberId } = this.state
        var selectedMemberIndex
        if (selectedMemberId) {
            selectedMemberIndex = toJS(userStore.users).findIndex(u => u._id === selectedMemberId)
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.memberList })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                            {intl.formatMessage({ ...messages.resetAllSearch })}
                        </Button>
                    </ActionLeftWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(userStore.users)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: userStore.count,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={userStore.isSubmitting}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
                <MemberDetailModal
                    visible={this.state.memberDetailVisible}
                    isSubmitting={analysisStore.isSubmitting}
                    member={selectedMemberIndex > -1 ? toJS(userStore.users)[selectedMemberIndex] : undefined}
                    onClose={() => this.setState({ memberDetailVisible: false, selectedMemberId: undefined })} />
                <UpdateMemberCreditModal
                    visible={this.state.memberCreditVisible}
                    isSubmitting={userStore.isSubmitting}
                    member={selectedMemberIndex > -1 ? toJS(userStore.users)[selectedMemberIndex] : undefined}
                    onSubmit={(values, reset) => this.handleOnMemberCreditUpdate(values, reset)}
                    onClose={() => this.setState({ memberCreditVisible: false, selectedMemberId: undefined })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(MemberListPage))