import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'

class CouponStore {
    @observable isSubmitting = false
    @observable error = null
    @observable coupons = []
    @observable count = 0

    createShopCoupon = flow(function* (token, shop, values) {
        this.isSubmitting = true
        const formData = new FormData()
        if (values.couponType) {
            formData.append('couponType', values.couponType)
            if (values.couponType === 'BAOFOOD_FREE') {
                formData.append('discount', 0)
            } else {
                formData.append('discount', values.discount)
            }
        } else {
            formData.append('couponType', 'SHOPPER')
            formData.append('discount', values.discount)
        }
        formData.append('name', values.name)
        formData.append('credit', values.credit)
        formData.append('minPriceRequired', values.minPriceRequired)
        formData.append('totalAmount', values.totalAmount)
        formData.append('maxPurchase', values.maxPurchase)
        formData.append('terms', values.terms.toHTML())
        formData.append('detail', values.detail.toHTML())
        formData.append('startDate', values.validDate[0].format())
        formData.append('endDate', values.validDate[1].format())
        formData.append('status', values.status)
        formData.append('priority', values.priority)
        for (const f of values.photos) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('photo', thumbnail)
            }
        }
        try {
            yield api.createShopCoupon(token, shop, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    createProductCoupon = flow(function* (token, shop, product, values) {
        this.isSubmitting = true
        const formData = new FormData()
        if (values.couponType) {
            formData.append('couponType', values.couponType)
            if (values.couponType === 'BAOFOOD_FREE') {
                formData.append('discount', 0)
            } else {
                formData.append('discount', values.discount)
            }
        } else {
            formData.append('couponType', 'SHOPPER')
            formData.append('discount', values.discount)
        }
        formData.append('name', values.name)
        formData.append('credit', values.credit)
        formData.append('minPriceRequired', values.minPriceRequired)
        formData.append('totalAmount', values.totalAmount)
        formData.append('maxPurchase', values.maxPurchase)
        formData.append('terms', values.terms.toHTML())
        formData.append('detail', values.detail.toHTML())
        formData.append('startDate', values.validDate[0].format())
        formData.append('endDate', values.validDate[1].format())
        formData.append('status', values.status)
        formData.append('priority', values.priority)
        for (const f of values.photos) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('photo', thumbnail)
            }
        }
        try {
            yield api.createProductCoupon(token, shop, product, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    createManyCoupon = flow(function* (token, shop, productIds, values) {
        this.isSubmitting = true
        const formData = new FormData()
        productIds.forEach(p => {
            formData.append('productIds[]', p)
        })
        if (values.couponType) {
            formData.append('couponType', values.couponType)
            if (values.couponType === 'BAOFOOD_FREE') {
                formData.append('discount', 0)
            } else {
                formData.append('discount', values.discount)
            }
        } else {
            formData.append('couponType', 'SHOPPER')
            formData.append('discount', values.discount)
        }
        formData.append('name', values.name)
        formData.append('credit', values.credit)
        formData.append('minPriceRequired', values.minPriceRequired)
        formData.append('totalAmount', values.totalAmount)
        formData.append('maxPurchase', values.maxPurchase)
        formData.append('terms', values.terms.toHTML())
        formData.append('detail', values.detail.toHTML())
        formData.append('startDate', values.validDate[0].format())
        formData.append('endDate', values.validDate[1].format())
        formData.append('status', values.status)
        formData.append('priority', values.priority)
        for (const f of values.photos) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('photo', thumbnail)
            }
        }
        try {
            yield api.createManyCoupon(token, shop, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listProductCoupon = flow(function* (token, shop, productId, limit, skip,
        sortField,
        name,
        stateDateMin,
        stateDateMax,
        endDateMin,
        endDateMax,
        createdAtMin,
        createdAtMax,
        status,
    ) {
        this.isSubmitting = true
        try {
            const response = yield api.listProductCoupon(token, shop, productId, limit, skip,
                sortField,
                name,
                stateDateMin,
                stateDateMax,
                endDateMin,
                endDateMax,
                createdAtMin,
                createdAtMax,
                status,
            )
            const { coupons, count } = response.data
            this.coupons = coupons
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listShopCoupon = flow(function* (token, shop, limit, skip,
        sortField,
        couponType,
        name,
        startDateMin,
        startDateMax,
        endDateMin,
        endDateMax,
        createdAtMin,
        createdAtMax,
        status,
    ) {
        this.isSubmitting = true
        try {
            const response = yield api.listShopCoupon(token, shop, limit, skip,
                sortField,
                couponType,
                name,
                startDateMin,
                startDateMax,
                endDateMin,
                endDateMax,
                createdAtMin,
                createdAtMax,
                status,
            )
            const { coupons, count } = response.data
            this.coupons = coupons
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateCoupon = flow(function* (token, shop, id, values) {
        this.isSubmitting = true
        const formData = new FormData()
        formData.append('name', values.name)
        if (values.couponType) {
            formData.append('couponType', values.couponType)
            if (values.couponType === 'BAOFOOD_FREE') {
                formData.append('discount', 0)
            } else {
                formData.append('discount', values.discount)
            }
        } else {
            formData.append('couponType', 'SHOPPER')
            formData.append('discount', values.discount)
        }
        formData.append('credit', values.credit)
        formData.append('minPriceRequired', values.minPriceRequired)
        formData.append('totalAmount', values.totalAmount)
        formData.append('maxPurchase', values.maxPurchase)
        formData.append('terms', values.terms.toHTML())
        formData.append('detail', values.detail.toHTML())
        formData.append('startDate', values.validDate[0].format())
        formData.append('endDate', values.validDate[1].format())
        formData.append('status', values.status)
        formData.append('priority', values.priority)
        for (const f of values.photos) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('photo', thumbnail)
            }
        }
        try {
            const response = yield api.updateCoupon(token, shop, id, formData)
            const index = this.coupons.findIndex((b) => {
                return b._id===id
            })
            this.coupons[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteCoupon = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            yield api.deleteCoupon(token, shop, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

}

export default new CouponStore()
