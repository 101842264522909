import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, Button, Divider, message, Popconfirm } from 'antd'
import messages from '~/src/messages'
import PospalSelectForm from '~/src/components/forms/pospalSelectForm'
import { pageSizeOptions } from '~/src/constants/options'
import { LIMIT } from '~/src/constants/common'
import CreatePospalProductModal from '~/src/components/createPospalProductModal'
import EditPospalProductModal from '~/src/components/editPospalProductModal'
import PospalProductImportModal from '~/src/components/pospalProductImportModal'
import _ from 'lodash'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    margin-top: 16px;
    background-color: white;
`

@inject('pospalStore', 'commonStore', 'productStore', 'categoryStore') @observer
class PospalProductListPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            pageSize: LIMIT,
            branch: undefined,
            sortedInfo: undefined,
            selectedProductId: undefined,
            createPospalProductVisible: false,
            editPospalProductVisible: false,
            productImportVisible: false,
            selectedRowKeys: [],
            selectedProducts: [],
            linkProductOptionVisible: false,
            stockTakeVisible: false
        }
    }

    async componentDidMount() {
        const { pospalStore, commonStore } = this.props
        const { token, shop } = commonStore
        await pospalStore.listPospalBranch(token, shop)
    }

    async handleOnSubmit(values, reset) {
        const { branch } = values
        this.setState({
            branch: branch,
            currentPage: 1
        }, async () => {
            const { pospalStore, commonStore } = this.props
            const { token } = commonStore
            const { branch, pageSize } = this.state
            await Promise.all([
                pospalStore.listPospalProduct(token, branch, 1, pageSize),
                pospalStore.listPospalCategory(token, branch)
            ])
        })
    }

    async handleOnPospalProductCreate(values, reset) {
        const { intl, pospalStore, commonStore } = this.props
        const { token } = commonStore
        const { branch, currentPage, pageSize } = this.state
        const { name, category, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, optionGroups } = values
        const categoryUid = category[category.length - 1]
        const index = pospalStore.rawCategories.findIndex((c) => c.uid === categoryUid)
        const categoryName = index > -1 ? pospalStore.rawCategories[index].name : undefined
        // let productTastes = pospalStore.optionGroups.filter(g => optionGroups.includes(g.uid)).map(g => { return { uid: g.uid, packageName: g.packageName } })
        pospalStore.createPospalProduct(
            token,
            branch,
            name,
            categoryUid,
            categoryName,
            sellPrice.toString(),
            buyPrice.toString(),
            isCustomerDiscount ? '1' : '0',
            customerPrice.toString(),
            sellPrice2.toString(),
            stock.toString(),
            []
        )
            .then(() => {
                reset()
                this.setState({ createPospalProductVisible: false })
                message.success(intl.formatMessage({ ...messages.createProductSuccess }))
                pospalStore.listPospalProduct(token, branch, currentPage, pageSize)
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.createProductFailure }))
            })
    }

    async handleOnPospalProductEdit(values, reset) {
        const { intl, pospalStore, commonStore } = this.props
        const { token } = commonStore
        const { branch, selectedProductId, currentPage, pageSize } = this.state
        const { name, barcode, category, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock } = values
        const categoryUid = category[category.length - 1]
        const index = pospalStore.rawCategories.findIndex((c) => c.uid === categoryUid)
        const categoryName = index > -1 ? pospalStore.rawCategories[index].name : undefined
        pospalStore.updatePospalProduct(
            token,
            branch,
            selectedProductId,
            name,
            barcode,
            categoryUid,
            categoryName,
            sellPrice.toString(),
            buyPrice.toString(),
            isCustomerDiscount ? '1' : '0',
            customerPrice.toString(),
            sellPrice2.toString(),
            stock.toString(),
            []
        )
            .then(() => {
                reset()
                this.setState({ editPospalProductVisible: false, selectedProductId: undefined })
                message.success(intl.formatMessage({ ...messages.updateProductSuccess }))
                pospalStore.listPospalProduct(token, branch, currentPage, pageSize)
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateProductFailure }))
            })
    }

    async handleOnPospalProductDelete(record) {
        const { intl, pospalStore, commonStore } = this.props
        const { token } = commonStore
        const { branch, currentPage, pageSize } = this.state
        pospalStore.deletePospalProduct(token, branch, record.id)
            .then(() => {
                message.success(intl.formatMessage({ ...messages.deleteProductSuccess }))
                pospalStore.listPospalProduct(token, branch, currentPage, pageSize)
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.deleteProductFailure }))
            })
    }

    async handleOnTableChange(pagination, filters, sorter) {
        const { pospalStore, commonStore } = this.props
        const { token } = commonStore
        const { branch } = this.state
        const page = pagination.current
        const { pageSize } = pagination
        await pospalStore.listPospalProduct(token, branch, page, pageSize)
        const href = `/pospalProduct?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            sortedInfo: sorter,
            pageSize: pageSize
        })
    }

    async handleOnProductEditClick(record) {
        const { pospalStore, commonStore } = this.props
        const { token } = commonStore
        const { branch } = this.state
        await pospalStore.getPospalProduct(token, branch, record.id)
        this.setState({ editPospalProductVisible: true, selectedProductId: record.id })
    }

    handleOnImportClick() {
        this.setState({ productImportVisible: true })
    }

    handleOnExportClick() {
        const { intl } = this.props
        const { selectedProducts } = this.state
        if (selectedProducts.length > 0) {
            const sheetHeader = [
                `${intl.formatMessage({ ...messages.name })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.barcode })}`,
                `${intl.formatMessage({ ...messages.category })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.stock })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.buyPrice })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.sellPrice })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.memberPrice })}`,
                `${intl.formatMessage({ ...messages.wholesellPrice })} (${intl.formatMessage({ ...messages.mustInput })})`
            ]
            const data = []
            toJS(selectedProducts).forEach(p => {
                return data.push([
                    p.name,
                    p.barcode,
                    p.categoryName,
                    p.stock,
                    p.buyPrice,
                    p.sellPrice,
                    p.customerPrice,
                    p.sellPrice2,
                ])
            })
            import('~/src/lib/xlsxHelper').then(xlsxHelper => {
                xlsxHelper.exportData(sheetHeader, data, 'shopper_product.xlsx')
            })
        } else {
            message.error(intl.formatMessage({ ...messages.exportProductReminder }))
        }
    }

    validateProductData(products) {
        const { pospalStore, intl } = this.props
        const { rawCategories } = pospalStore
        const errors = []
        products.forEach((p, index) => {
            const { name, categoryName, stock, buyPrice, sellPrice, sellPrice2 } = p
            if (!name) {
                errors.push({ row: index + 2, field: 'name', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!categoryName) {
                errors.push({ row: index + 2, field: 'categoryName', message: intl.formatMessage({ ...messages.pleaseInput }) })
            } else {
                const index = rawCategories.findIndex(c => c.name === categoryName)
                if (index < 0) {
                    errors.push({ row: index + 2, field: 'categoryName', message: intl.formatMessage({ ...messages.categoryNameNotFound }) })
                }
            }
            if (!stock) {
                errors.push({ row: index + 2, field: 'stock', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!buyPrice) {
                errors.push({ row: index + 2, field: 'buyPrice', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!sellPrice) {
                errors.push({ row: index + 2, field: 'sellPrice', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!sellPrice2) {
                errors.push({ row: index + 2, field: 'sellPrice2', message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
        })
        return errors
    }

    async handleOnImport(values, reset, errorCallback) {
        const { pospalStore, commonStore, intl } = this.props
        const { token } = commonStore
        const { currentPage, pageSize, branch } = this.state
        const { file } = values
        const header = ['name', 'categoryName', 'stock', 'buyPrice', 'sellPrice', 'isCustomerDiscount', 'customerPrice', 'sellPrice2']

        import('~/src/lib/xlsxHelper').then(async xlsxHelper => {
            const data = await xlsxHelper.importFile(file[0].originFileObj, header)
            data.shift()
            const errors = this.validateProductData(data)
            if (errors.length === 0) {
                pospalStore.importPospalProduct(token, branch, data)
                    .then(() => {
                        reset()
                        this.setState({ productImportVisible: false })
                        message.success(intl.formatMessage({ ...messages.createProductSuccess }))
                        pospalStore.listPospalProduct(token, branch, currentPage, pageSize)
                    })
                    .catch(e => {
                        message.error(intl.formatMessage({ ...messages.createProductFailure }))
                    })
            } else {
                errorCallback(errors)
            }
        })
    }

    handleOnRowSelect(selectedRowKeys, selectedRows) {
        const { pospalStore } = this.props
        const products = toJS(pospalStore.products)
        this.setState({ selectedRowKeys: selectedRowKeys })
        const filteredProducts = _.unionBy(this.state.selectedProducts, products, 'id').filter(p => {
            return selectedRowKeys.includes(p.id)
        })
        this.setState({ selectedProducts: filteredProducts })
    }

    // async handleOnLinkClick(record) {
    //     const { productStore, categoryStore, commonStore } = this.props
    //     const { token, shop } = commonStore
    //     await productStore.listProduct(token, shop, LIMIT, 0,
    //         undefined,
    //         undefined,
    //         undefined,
    //         undefined,
    //         [],
    //         [],
    //         [],
    //         ['ORDER'],
    //         [],
    //         undefined,
    //         undefined
    //     )
    //     await categoryStore.listCategory(token, shop, 0, 0)
    //     this.setState({ linkProductOptionVisible: true, selectedProductId: record.id })
    // }

    // handleOnLink(productId, productOptionId, pospalProductId, reset) {
    //     const { intl, pospalStore, commonStore } = this.props
    //     const { currentPage, branch, pageSize } = this.state
    //     const { token, shop } = commonStore
    //     pospalStore.linkPospalProduct(token, shop, branch, productId, productOptionId, pospalProductId)
    //         .then(() => {
    //             message.success(intl.formatMessage({ ...messages.linkShopperProductOptionSuccess }))
    //             reset()
    //             this.setState({ linkProductOptionVisible: false, selectedProductId: undefined })
    //             pospalStore.listPospalProduct(token, branch, currentPage, pageSize)
    //         })
    //         .catch(e => {
    //             console.log(e)
    //             if (hasError(e, 13008)) {
    //                 message.error(getErrorMessage(intl, e, 13008))
    //             } else {
    //                 message.error(intl.formatMessage({ ...messages.linkShopperProductOptionFailure }))
    //             }
    //         })
    // }

    // async handleOnStockTakeClick(record) {
    //     const { pospalStore, productStore, commonStore } = this.props
    //     const { token } = commonStore
    //     const { branch } = this.state 
    //     await productStore.getProductForPospalStockTake(token, record.shopperProductId)
    //     await pospalStore.getPospalProduct(token, branch, record.id)
    //     this.setState({ stockTakeVisible: true, selectedProductId: record.id })
    // }

    // async handleOnStockUpdate(values, reset) {
    //     const { intl, productStore, pospalStore, commonStore } = this.props
    //     const { token } = commonStore
    //     const { branch, selectedProductId, currentPage, pageSize } = this.state
    //     var selectedPospalProductIndex
    //     if (selectedProductId) {
    //         const index = pospalStore.products.findIndex(o => o.id === selectedProductId)
    //         selectedPospalProductIndex = index
    //     }
    //     const { shopperProductId } = toJS(pospalStore.products[selectedPospalProductIndex])
    //     const { name, barcode, category, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2 } = toJS(pospalStore.currentProduct)
    //     console.log(toJS(pospalStore.currentProduct))
    //     const pospalProductData = {
    //         name,
    //         barcode,
    //         categoryUid: category ? category.uid : undefined,
    //         categoryName: category ? category.name : undefined,
    //         sellPrice: sellPrice.toString(),
    //         buyPrice: buyPrice.toString(),
    //         isCustomerDiscount: isCustomerDiscount ? '1' : '0',
    //         customerPrice: customerPrice.toString(),
    //         sellPrice2: sellPrice2 ? sellPrice2.toString() : '0',
    //         stock: values.pospalProductStock.toString(),
    //         productTastes: []
    //     }
    //     const shopperProductData = {
    //         stock: values.productStock,
    //     }
    //     pospalStore.updateStockTake(token, branch, selectedProductId, shopperProductId,
    //         pospalProductData,
    //         shopperProductData,
    //     )
    //         .then(() => {
    //             reset()
    //             this.setState({ stockTakeVisible: false, selectedProductId: undefined })
    //             message.success(intl.formatMessage({ ...messages.stockTakeSuccess }))
    //             pospalStore.listPospalProduct(token, branch, currentPage, pageSize)
    //         })
    //         .catch((e) => {
    //             message.error(intl.formatMessage({ ...messages.stockTakeFailure }))
    //         })
    // }

    // handleOnUnlink(linkId, reset) {
    //     const { productStore, pospalStore, commonStore, intl } = this.props
    //     const { token, shop } = commonStore
    //     const { currentPage, branch, pageSize } = this.state
    //     pospalStore.unlinkPospalProduct(token, shop, branch, linkId)
    //         .then(() => {
    //             reset()
    //             message.success(intl.formatMessage({ ...messages.unlinkSuccess }))
    //             this.setState({ stockTakeVisible: false, selectedProductId: undefined })
    //             pospalStore.listPospalProduct(token, branch, currentPage, pageSize)
    //         })
    //         .catch(e => {
    //             message.error(intl.formatMessage({ ...messages.unlinkFailure }))
    //         })
    // }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: intl.formatMessage({ ...messages.barcode }),
                dataIndex: 'barcode',
                key: 'barcode',
            },
            {
                title: intl.formatMessage({ ...messages.category }),
                dataIndex: 'categoryName',
                key: 'categoryName',
            },
            {
                title: intl.formatMessage({ ...messages.stock }),
                dataIndex: 'stock',
                key: 'stock',
            },
            {
                title: intl.formatMessage({ ...messages.buyPrice }),
                dataIndex: 'buyPrice',
                key: 'buyPrice',
            },
            {
                title: intl.formatMessage({ ...messages.sellPrice }),
                dataIndex: 'sellPrice',
                key: 'sellPrice',
            },
            {
                title: intl.formatMessage({ ...messages.memberDiscount }),
                dataIndex: 'isCustomerDiscount',
                key: 'isCustomerDiscount',
                render: (text, record) => {
                    return record.isCustomerDiscount
                        ? intl.formatMessage({ ...messages.yes })
                        : intl.formatMessage({ ...messages.no })
                }
            },
            {
                title: intl.formatMessage({ ...messages.memberPrice }),
                dataIndex: 'customerPrice',
                key: 'customerPrice',
            },
            {
                title: intl.formatMessage({ ...messages.wholesellPrice }),
                dataIndex: 'sellPrice2',
                key: 'sellPrice2',
            },
            // {
            //     title: intl.formatMessage({ ...messages.createdAt }),
            //     dataIndex: 'updatedDatetime',
            //     key: 'updatedDatetime',
            // },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 140,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnProductEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                            {/* <Divider type='vertical' />
                            {
                                record.linkId
                                    ?
                                    <a onClick={() => this.handleOnStockTakeClick(record)}>
                                        {intl.formatMessage({ ...messages.stockTake })}
                                    </a>
                                    :
                                    <a onClick={() => this.handleOnLinkClick(record)}>
                                        {intl.formatMessage({ ...messages.link })}
                                    </a>
                            } */}
                            <Divider type='vertical' />
                            <Popconfirm
                                title={intl.formatMessage({ ...messages.deleteReminder })}
                                onConfirm={() => this.handleOnPospalProductDelete(record)}
                                okText={intl.formatMessage({ ...messages.yes })}
                                cancelText={intl.formatMessage({ ...messages.no })}>
                                <a>
                                    {intl.formatMessage({ ...messages.delete })}
                                </a>
                            </Popconfirm>
                        </span>
                    )
                }
            }
        ]
    }

    render() {
        const { pospalStore, intl, title, productStore } = this.props
        const { currentPage, selectedProductId, branch, pageSize } = this.state
        var selectedProductIndex
        if (selectedProductId) {
            const index = pospalStore.products.findIndex(o => o.id === selectedProductId)
            selectedProductIndex = index
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.pospalProduct })}</title>
                </Helmet>
                <PospalSelectForm
                    branches={toJS(pospalStore.branches)}
                    onSubmit={(values, reset) => this.handleOnSubmit(values, reset)} />
                {
                    branch
                        ?
                        <React.Fragment>
                            <ActionWrapper>
                                <ActionLeftWrapper>
                                    <Button type='primary' onClick={() => this.setState({ createPospalProductVisible: true })}>
                                        {intl.formatMessage({ ...messages.createProduct })}
                                    </Button>
                                    <Button type="primary" onClick={() => this.handleOnImportClick()}>
                                        {intl.formatMessage({ ...messages.import })}
                                    </Button>
                                    <Button type="primary" onClick={() => this.handleOnExportClick()} disabled={this.state.selectedRowKeys.length < 1}>
                                        {intl.formatMessage({ ...messages.export })}
                                        {
                                            this.state.selectedRowKeys.length > 0
                                                ? intl.formatMessage({ ...messages.selectedCount }, { count: this.state.selectedRowKeys.length })
                                                : null
                                        }
                                    </Button>
                                </ActionLeftWrapper>
                            </ActionWrapper>
                            <TableWrapper>
                                <Table
                                    columns={this.renderTableColumn()}
                                    dataSource={toJS(pospalStore.products)}
                                    pagination={
                                        {
                                            showSizeChanger: true,
                                            defaultPageSize: LIMIT,
                                            pageSizeOptions: pageSizeOptions,
                                            showQuickJumper: true,
                                            current: +currentPage,
                                            pageSize: pageSize,
                                            total: pospalStore.productCount,
                                            showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                                        }
                                    }
                                    onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                                    loading={pospalStore.isSubmitting}
                                    scroll={{ x: 2000 }}
                                    rowKey={record => record.id}
                                    rowSelection={{
                                        fixed: true,
                                        selectedRowKeys: this.state.selectedRowKeys,
                                        onChange: (selectedRowKeys, selectedRows) => this.handleOnRowSelect(selectedRowKeys, selectedRows)
                                    }}
                                />
                            </TableWrapper>
                        </React.Fragment>
                        : null
                }
                <CreatePospalProductModal
                    visible={this.state.createPospalProductVisible}
                    categories={toJS(pospalStore.categories)}
                    // optionGroups={toJS(pospalStore.optionGroups)}
                    isSubmitting={pospalStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnPospalProductCreate(values, reset)}
                    onClose={() => this.setState({ createPospalProductVisible: false })} />
                <EditPospalProductModal
                    product={selectedProductIndex > -1 ? toJS(pospalStore.currentProduct) : undefined}
                    visible={this.state.editPospalProductVisible}
                    categories={toJS(pospalStore.categories)}
                    // optionGroups={toJS(pospalStore.optionGroups)}
                    isSubmitting={pospalStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnPospalProductEdit(values, reset)}
                    onClose={() => this.setState({ editPospalProductVisible: false, selectedProductId: undefined })} />
                <PospalProductImportModal
                    visible={this.state.productImportVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    onSubmit={(values, reset, error) => this.handleOnImport(values, reset, error)}
                    onClose={() => this.setState({ productImportVisible: false })} />
                {/* <LinkProductOptionModal
                    branch={branch}
                    pospalProduct={selectedProductIndex > -1 ? toJS(pospalStore.products[selectedProductIndex]) : undefined}
                    error={pospalStore.error}
                    visible={this.state.linkProductOptionVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    onLink={(productId, pospalProductId, reset) => this.handleOnLink(productId, pospalProductId, reset)}
                    onClose={() => this.setState({ linkProductOptionVisible: false, selectedProductId: undefined })} />
                <StockTakeModal
                    pospalProduct={selectedProductIndex > -1 ? toJS(pospalStore.currentProduct) : undefined}
                    linkId={selectedProductIndex > -1 ? toJS(pospalStore.products[selectedProductIndex].linkId) : undefined}
                    product={toJS(productStore.currentProduct)}
                    visible={this.state.stockTakeVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnStockUpdate(values, reset)}
                    onUnlink={(linkId, reset) => this.handleOnUnlink(linkId, reset)}
                    onClose={() => this.setState({ stockTakeVisible: false, selectedProductId: undefined })} /> */}
            </Container>
        )
    }
}

export default withRouter(injectIntl(PospalProductListPage))